/* .booking-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking-card {
  background-color: #fff;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.booking-card h2 {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
}

.form-group input,
.form-group select {
  margin-bottom: 15px;
}

.btn-block {
  width: 100%;
} */


.booking-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking-card {
  background-color: #fff;
  padding: 40px;
  margin:10px;
  border-radius: 10px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 0px 8px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
  transition: box-shadow 0.3s ease;
}

.booking-card:hover {
  box-shadow: 0 0px 8px 3px rgba(100, 85, 85, 0.6);
}

.booking-card h2 {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
}

.form-group input,
.form-group select {
  margin-bottom: 15px;
}

.btn-block {
  width: 100%;
}

.man_heading{
margin: 20px;
}