
  
  .signup-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .side-image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    border-radius: 5px 0 0 5px;
  }
  /* Mobile Styles */
@media (max-width: 600px) {
  .side-image {
    width: 38%;
  }
}

