body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mebile-lion-btm {
  position: absolute;
  bottom: 25px;
  border-top: 1px solid;
  width: 100%;
  padding: 14px 0px;
}
div#header {
  background: #02acdd;
}
.header-menu ul {
  display: flex;
  align-items: center;
}
.header-menu ul {
  list-style: none;
  margin: 0;
}
.header-menu a {
  margin-right: 13px;
  color: white;
  text-decoration: none;
}
.header-icon {
  display: flex;
  align-items: center;
}
.header-icon svg {
  font-size: 22px;
  margin-left: 19px;
  color: white;
}
.carousel.slide img {
  height: 450px;
  object-fit: cover;
}
.services img {
  width: 56%;
}
.services {
  display: flex;
  justify-content: space-between;
  margin-top: 51px;
  flex-wrap: wrap;
}
.services-com.text-center {
  width: 24%;
  background: #f6f6f6;
  padding: 25px;
}
.we-fix-content img {
  width: 100%;
}
.we-fix-content img {
  width: 61%;
}
.we-fix-content {
  text-align: center;
}
.we-fix {
  margin-top: 51px;
}
.futere-text img {
  width: 100%;
}
.why-chose-us img {
  width: 100%;
}
.why-chose-us  {
  margin-top: 51px;
}
div#footer {
  background: #02acdd;
  padding: 68px 0;
  margin-top: 51px;
}
div#footer img {
  width: 100%;
}
div#footer a {
  color: white;
  text-decoration: none;
}
div#footer ul {
  list-style: none;
  padding: 0;
}
div#footer li {
  margin-bottom: 14px;
}
div#footer h5 {
  color: white;
}
.footer-link h6 {
  color: white;
  font-size: 24px;
  margin-bottom: 34px;
}
.card {
  padding: 46px;
  background: #e7e5e5;
}
div#Blog-post {
  margin-top: 51px;
}
h3.section-title {
  margin-bottom: 30px;
}
.review-heading {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
div#review {
  margin-top: 51px;
}
.row.review-item-outer img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.review-item-person-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.review-item h3 {
  text-transform: capitalize;
  font-size: 15px;
}
.review-item div {
  margin-left: 20px;
}
.review-item h3 {
  text-transform: capitalize;
  font-size: 15px;
  margin-bottom: 0;
}
div#review svg {
  color: red;
}
.review-item {
  background: #5e5c5c30;
  margin-top: 31px;
  border-radius: 10px;
  padding: 28px;
}
.mobile-menu-btn {
  display: none;
}
.mobile-menu {
  display: none;
}
.top-brands img {
  width: 100%;
}
.appointment {
  margin-top: 31px;
}
.top-brands {
  box-shadow: 3px 5px 17px 2px #0000000f;
  margin-top: 31px;
}

.appoint-Device a {
  text-decoration: none;
}
.appoint-Device h6 {
  text-decoration: none;
}
.appoint-Device img {
  width: 60%;
}
.appoint-Device {
  background: #02acdd33;
  padding: 9px;
  border-radius: 12px;
}
.appointment a {
  text-decoration: none;
}
.appoint-Device_content h6 {
  color: #000000e0;
  margin-top: 8px;
  margin-bottom: 39px;
}
.appoint-Device img {
  width: 60%;
  height: 200px;
  object-fit: scale-down;
}
.order {
  margin-top: 25px;
}
.order-srvices a {
  text-decoration: none;
  background: #02acddc2;
  color: white;
  padding: 5px 26px;
  text-transform: capitalize;
  border-radius: 10px;
}
.ordr-service-content {
  border-bottom: 1px solid #0000002e;
  padding: 16px 14px;
  margin-top: 6px;
}
.appontment-btn a {
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  border: 1px solid #02acdd;
  padding: 13px;
  border-radius: 13px;
  color: white;
  background: #02acdd;
}
.appontment-btn {
  text-align: center;
  padding-top: 37px;
}
.order-srvices p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 7px;
  margin-right: 36px;
}
.order-srvices svg {
  font-size: 59px;
  color: #02acddb5;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ================================================================ */
.nk-main {
  position: relative;
}
.nk-sidebar {
  transform: translateX(0);
}
.nk-sidebar.is-theme {
  background-color: #0e0147;
}
.nk-sidebar-fixed {
  position: fixed;
  max-height: 100vh;
}

.nk-sidebar-head {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.375rem;
  overflow: hidden;
  width: 280px;
}
.nk-sidebar .nk-menu-text, .nk-sidebar .nk-sidebar-brand {
  transition: .4s;
}

.nk-sidebar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.nk-sidebar-body {
  position: relative;
  height: 100%;
}
.nk-sidebar-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}
.nk-sidebar-content .nk-sidebar-menu[data-simplebar] {
  height: 100%;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper, .simplebar-scroll-content {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden scroll;
}
.simplebar-content:before, .simplebar-content:after {
  content: " ";
  display: table;
}
.nk-menu {
  padding: 1rem 0;
}
.is-theme .nk-menu .nk-menu-link {
  color: #f8f8f9;
  opacity: .9;
}

.active>.nk-menu-link {
  color: #9b82fb;
  background-color: rgba(95,56,249,.04);
}
.nk-menu-toggle {
  position: relative;
}
.nk-menu-link {
  position: relative;
  display: flex;
  align-items: center;
  color: #2e314a;
  font-weight: 400;
  font-size: .875rem;
  padding: 0.625rem 1.75rem;
  background-color: rgba(0,0,0,0);
}
.has-sub.active>.nk-menu-toggle::before {
  transform: translateY(-50%) rotate(-180deg);
}

.nk-menu-toggle::before {
  position: absolute;
  font-family: "Nioicon";
  top: 50%;
  content: "";
  right: 1.5rem;
  transform: translateY(-50%);
  font-size: 18px;
  transition: .4s;
}
.nk-menu-icon {
  width: 1.125rem;
  display: inline-flex;
  justify-content: center;
  margin-right: 0.875rem;
}
.is-theme .nk-menu .nk-menu-icon .icon {
  color: #f8f8f9;
}

.active>.nk-menu-link .icon {
  color: #9b82fb;
}
.nk-menu-icon .icon {
  transition: color .3s;
  font-size: 1.125rem;
  color: #2e314a;
}
.ni {
  font-family: "Nioicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nk-sidebar .nk-menu-text, .nk-sidebar .nk-sidebar-brand {
  transition: .4s;
}
.nk-menu-text {
  flex-grow: 1;
  display: inline-block;
  white-space: nowrap;
}
.is-theme .nk-menu .nk-menu-sub .active>.nk-menu-link {
  color: #9b82fb;
  opacity: 1;
}

.is-theme .nk-menu .nk-menu-link {
  color: #f8f8f9;
  opacity: .9;
}
.nk-menu-sub .active>.nk-menu-link {
  color: #9b82fb;
}
.nk-menu-sub .nk-menu-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: .813rem;
  font-weight: 400;
  color: #43476b;
  background-color: rgba(0,0,0,0);
}
.active>.nk-menu-link {
  color: #9b82fb;
  background-color: rgba(95,56,249,.04);
}
.nk-menu-link {
  position: relative;
  display: flex;
  align-items: center;
  color: #2e314a;
  font-weight: 400;
  font-size: .875rem;
  padding: 0.625rem 1.75rem;
  background-color: rgba(0,0,0,0);
}
.nk-sidebar .nk-menu-text, .nk-sidebar .nk-sidebar-brand {
  transition: .4s;
}
.nk-menu-sub .nk-menu-text {
  padding-left: 0;
}
.nk-menu-text {
  flex-grow: 1;
  display: inline-block;
  white-space: nowrap;
}
.nk-menu-heading:not(:first-child) {
  padding-top: 2rem;
}

.nk-menu-heading {
  padding: 0.75rem 1.75rem;
}
.is-theme .nk-menu .overline-title {
  color: #f8f8f9;
  opacity: .6;
}

.nk-menu-heading .overline-title {
  white-space: nowrap;
}
.overline-title {
  font-size: 11px;
  line-height: 1.2;
  letter-spacing: .1em;
  color: #787c9e;
  text-transform: uppercase;
  font-weight: 700;
}
.simplebar-content-wrapper, .simplebar-scroll-content {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden scroll;
}
.nk-menu-icon {
  width: 1.125rem;
  display: inline-flex;
  justify-content: center;
  margin-right: 0.875rem;
}
.nk-sidebar .nk-menu-text, .nk-sidebar .nk-sidebar-brand {
  transition: .4s;
}

.nk-menu-text {
  flex-grow: 1;
  display: inline-block;
  white-space: nowrap;
}
.nk-menu-link::after {
  position: absolute;
  content: unset;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: red;
}
@media (min-width: 992px){
[data-sidebar-collapse=lg] .nk-sidebar+.nk-wrap>.nk-header-fixed {
    left: 280px;
}
}
@media (min-width: 576px){
.nk-header {
    padding: 0.5rem 1rem;
}
}
.nk-header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: .4s;
}
.nk-header {
    z-index: 1024;
    border-bottom: 1px solid #e8e7ec;
    background-color: white;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 1px 0 rgba(232,231,236,.5);
    min-height: 3.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
em.icon.ni.ni-arrow-left {
  color: black;
}
.nk-header-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*!
 * Template Name: NioBoard
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version: 1.1.2
 * Updated: 06.13.2023
**/






 /*!
 * Bootstrap v5.2.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme="light"] {
  --bs-blue: #0080ff;
  --bs-indigo: #5b5efb;
  --bs-purple: #8927f9;
  --bs-pink: #f24a8b;
  --bs-red: #df3c4e;
  --bs-orange: #f85f32;
  --bs-yellow: #f2bc16;
  --bs-green: #2dc58c;
  --bs-teal: #2acc9c;
  --bs-cyan: #478ffc;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6b7280;
  --bs-gray-dark: #374151;
  --bs-gray-100: #f1f2f7;
  --bs-gray-200: #e5e7eb;
  --bs-gray-300: #d1d5db;
  --bs-gray-400: #6b7280;
  --bs-gray-500: #6b7280;
  --bs-gray-600: #4b5563;
  --bs-gray-700: #374151;
  --bs-gray-800: #1f2937;
  --bs-gray-900: #111827;
  --bs-primary: #5f38f9;
  --bs-purple: #8927f9;
  --bs-pink: #f24a8b;
  --bs-secondary: #374151;
  --bs-success: #2dc58c;
  --bs-info: #478ffc;
  --bs-warning: #f2bc16;
  --bs-danger: #df3c4e;
  --bs-lighter: #f1f2f7;
  --bs-light: #e5e7eb;
  --bs-dark: #1f2937;
  --bs-darker: #111827;
  --bs-primary-rgb: 95, 56, 249;
  --bs-purple-rgb: 137, 39, 249;
  --bs-pink-rgb: 242, 74, 139;
  --bs-secondary-rgb: 55, 65, 81;
  --bs-success-rgb: 45, 197, 140;
  --bs-info-rgb: 71, 143, 252;
  --bs-warning-rgb: 242, 188, 22;
  --bs-danger-rgb: 223, 60, 78;
  --bs-lighter-rgb: 241, 242, 247;
  --bs-light-rgb: 229, 231, 235;
  --bs-dark-rgb: 31, 41, 55;
  --bs-darker-rgb: 17, 24, 39;
  --bs-primary-text-emphasis: #003366;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #124f38;
  --bs-info-text-emphasis: #1c3965;
  --bs-warning-text-emphasis: #614b09;
  --bs-danger-text-emphasis: #59181f;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cce6ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d5f3e8;
  --bs-info-bg-subtle: #dae9fe;
  --bs-warning-bg-subtle: #fcf2d0;
  --bs-danger-bg-subtle: #f9d8dc;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #99ccff;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #abe8d1;
  --bs-info-border-subtle: #b5d2fe;
  --bs-warning-border-subtle: #fae4a2;
  --bs-danger-border-subtle: #f2b1b8;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Roboto", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.67;
  --bs-body-color: #2e314a;
  --bs-body-color-rgb: 46, 49, 74;
  --bs-body-bg: #f8f8f9;
  --bs-body-bg-rgb: 248, 248, 249;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #43476b;
  --bs-link-color: #5f38f9;
  --bs-link-color-rgb: 95, 56, 249;
  --bs-link-decoration: none;
  --bs-link-hover-color: #4c2dc7;
  --bs-link-hover-color-rgb: 76, 45, 199;
  --bs-code-color: #f24a8b;
  --bs-highlight-bg: #fcf2d0;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d2d4e4;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.75rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(0, 128, 255, 0.25);
  --bs-form-valid-color: #2dc58c;
  --bs-form-valid-border-color: #2dc58c;
  --bs-form-invalid-color: #df3c4e;
  --bs-form-invalid-border-color: #df3c4e;
}
[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #66b3ff;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #81dcba;
  --bs-info-text-emphasis: #91bcfd;
  --bs-warning-text-emphasis: #f7d773;
  --bs-danger-text-emphasis: #ec8a95;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #001a33;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #09271c;
  --bs-info-bg-subtle: #0e1d32;
  --bs-warning-bg-subtle: #302604;
  --bs-danger-bg-subtle: #2d0c10;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #004d99;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #1b7654;
  --bs-info-border-subtle: #2b5697;
  --bs-warning-border-subtle: #91710d;
  --bs-danger-border-subtle: #86242f;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #66b3ff;
  --bs-link-hover-color: #85c2ff;
  --bs-link-color-rgb: 102, 179, 255;
  --bs-link-hover-color-rgb: 133, 194, 255;
  --bs-code-color: #f792b9;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #81dcba;
  --bs-form-valid-border-color: #81dcba;
  --bs-form-invalid-color: #ec8a95;
  --bs-form-invalid-border-color: #ec8a95;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}
h1,
.h1 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 1.75rem;
  }
}
h2,
.h2 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 1.5rem;
  }
}
h3,
.h3 {
  font-size: 1.25rem;
}
h4,
.h4 {
  font-size: 1.125rem;
}
h5,
.h5 {
  font-size: 1rem;
}
h6,
.h6 {
  font-size: 0.875rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small,
.small {
  font-size: 0.875rem;
}
mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}
sub,
sup {
  position: relative;
  font-size: 0.65rem;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875rem;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875rem;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #43476b;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.125rem;
  font-weight: 400;
}
.display-1 {
  font-size: calc(1.45rem + 2.4vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 3.25rem;
  }
}
.display-2 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 3rem;
  }
}
.display-3 {
  font-size: calc(1.4rem + 1.8vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 2.75rem;
  }
}
.display-4 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.5rem;
  }
}
.display-5 {
  font-size: calc(1.35rem + 1.2vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.25rem;
  }
}
.display-6 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875rem;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #4b5563;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8f8f9;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875rem;
  color: #4b5563;
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.75rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}
.row {
  --bs-gutter-x: 1.75rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.375rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.375rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.75rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.75rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 2.75rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 2.75rem;
}
.g-gs,
.gx-gs {
  --bs-gutter-x: 1.75rem;
}
.g-gs,
.gy-gs {
  --bs-gutter-y: 1.75rem;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-sm-gs,
  .gx-sm-gs {
    --bs-gutter-x: 1.75rem;
  }
  .g-sm-gs,
  .gy-sm-gs {
    --bs-gutter-y: 1.75rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-md-gs,
  .gx-md-gs {
    --bs-gutter-x: 1.75rem;
  }
  .g-md-gs,
  .gy-md-gs {
    --bs-gutter-y: 1.75rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-lg-gs,
  .gx-lg-gs {
    --bs-gutter-x: 1.75rem;
  }
  .g-lg-gs,
  .gy-lg-gs {
    --bs-gutter-y: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-xl-gs,
  .gx-xl-gs {
    --bs-gutter-x: 1.75rem;
  }
  .g-xl-gs,
  .gy-xl-gs {
    --bs-gutter-y: 1.75rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-xxl-gs,
  .gx-xxl-gs {
    --bs-gutter-x: 1.75rem;
  }
  .g-xxl-gs,
  .gy-xxl-gs {
    --bs-gutter-y: 1.75rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #43476b;
  --bs-table-bg: transparent;
  --bs-table-border-color: #e8e7ec;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #43476b;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.03);
  --bs-table-active-color: #43476b;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #43476b;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.75rem;
  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, var(--bs-table-color))
  );
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table-group-divider {
  border-top: calc(1px * 2) solid currentcolor;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}
.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}
.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}
.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}
.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #dfd7fe;
  --bs-table-border-color: #c9c2e5;
  --bs-table-striped-bg: #d8d1f6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c9c2e5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cec7eb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #d7d9dc;
  --bs-table-border-color: #c2c3c6;
  --bs-table-striped-bg: #d1d2d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2c3c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7c9cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d5f3e8;
  --bs-table-border-color: #c0dbd1;
  --bs-table-striped-bg: #cfece1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c0dbd1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c5e1d7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #dae9fe;
  --bs-table-border-color: #c4d2e5;
  --bs-table-striped-bg: #d3e2f6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c4d2e5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cad8eb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fcf2d0;
  --bs-table-border-color: #e3dabb;
  --bs-table-striped-bg: #f4ebca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3dabb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9e0c0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f9d8dc;
  --bs-table-border-color: #e0c2c6;
  --bs-table-striped-bg: #f2d2d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0c2c6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6c8cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f1f2f7;
  --bs-table-border-color: #d9dade;
  --bs-table-striped-bg: #eaebf0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9dade;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfe0e4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #1f2937;
  --bs-table-border-color: #353e4b;
  --bs-table-striped-bg: #262f3d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #353e4b;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #303946;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}
.col-form-label {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5rem;
}
.col-form-label-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
}
.col-form-label-sm {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  font-size: 0.75rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: rgba(var(--bs-body-color-rgb), 0.75);
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.5625rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #43476b;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid #d2d4e4;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #43476b;
  background-color: #fff;
  border-color: rgba(95, 56, 249, 0.65);
  outline: 0;
  box-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5rem;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #d1d5db;
  opacity: 1;
}
.form-control:disabled {
  background-color: #f2f3f8;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.5625rem 1.125rem;
  margin: -0.5625rem -1.125rem;
  margin-inline-end: 1.125rem;
  color: #43476b;
  background-color: #e5e7eb;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.0625rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dadbdf;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5625rem 0;
  margin-bottom: 0;
  line-height: 1.5rem;
  color: #43476b;
  background-color: rgba(0, 0, 0, 0);
  border: solid rgba(0, 0, 0, 0);
  border-width: 0.0625rem 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: 1.875rem;
  padding: 0.125rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.125rem 0.75rem;
  margin: -0.125rem -0.75rem;
  margin-inline-end: 0.75rem;
}
.form-control-lg {
  min-height: 3.5rem;
  padding: 0.9375rem 1.375rem;
  font-size: 1rem;
  border-radius: 0.75rem;
}
.form-control-lg::file-selector-button {
  padding: 0.9375rem 1.375rem;
  margin: -0.9375rem -1.375rem;
  margin-inline-end: 1.375rem;
}
textarea.form-control {
  min-height: 2.75rem;
}
textarea.form-control-sm {
  min-height: 1.875rem;
}
textarea.form-control-lg {
  min-height: 3.5rem;
}
.form-control-color {
  width: 3rem;
  height: 2.75rem;
  padding: 0.5625rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: 1.875rem;
}
.form-control-color.form-control-lg {
  height: 3.5rem;
}
.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231F2937' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.5625rem 3.375rem 0.5625rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #43476b;
  background-color: #fff;
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1.125rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid #d2d4e4;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgba(95, 56, 249, 0.65);
  outline: 0;
  box-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 1.125rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #f2f3f8;
}
.form-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 0 #43476b;
}
.form-select-sm {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.form-select-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  padding-left: 1.375rem;
  font-size: 1rem;
  border-radius: 0.75rem;
}
[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}
.form-check {
  display: block;
  min-height: 1.75rem;
  padding-left: 0;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: 0;
}
.form-check-reverse {
  padding-right: 0;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: 0;
  margin-left: 0;
}
.form-check-input {
  --bs-form-check-bg: #fff;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0.21rem;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #d2d4e4;
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: rgba(95, 56, 249, 0.65);
  outline: 0;
  box-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
}
.form-check-input:checked {
  background-color: #5f38f9;
  border-color: #5f38f9;
}
.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #5f38f9;
  border-color: #5f38f9;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
.form-switch {
  padding-left: 0;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23d2d4e4'/%3e%3c/svg%3e");
  width: 2.5rem;
  margin-left: 0;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2.5rem;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2895, 56, 249, 0.65%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 0;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: 0;
  margin-left: 0;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
[data-bs-theme="dark"]
  .form-switch
  .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}
.form-range {
  width: 100%;
  height: 2.25rem;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1.75rem;
  height: 1.75rem;
  margin-top: -0.5375rem;
  background-color: #fff;
  border: 1px solid #d2d4e4;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fff;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.675rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #e5e7eb;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1.75rem;
  height: 1.75rem;
  background-color: #fff;
  border: 1px solid #d2d4e4;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fff;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.675rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #e5e7eb;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #d1d5db;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #d1d5db;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: 3.625rem;
  min-height: 3.625rem;
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1.125rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 0.0625rem solid rgba(0, 0, 0, 0);
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.125rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: rgba(0, 0, 0, 0);
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.5625rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: 0.375rem;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 0.0625rem 0;
}
.form-floating > :disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after {
  background-color: #f2f3f8;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5625rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #43476b;
  text-align: center;
  white-space: nowrap;
  background-color: #e5e7eb;
  border: 0.0625rem solid #d2d4e4;
  border-radius: 0.375rem;
}
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.9375rem 1.375rem;
  font-size: 1rem;
  border-radius: 0.75rem;
}
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.125rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4.5rem;
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation
  > :nth-last-child(n
    + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(0.0625rem * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #2dc58c;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #2dc58c;
  border-radius: 0.25rem;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #2dc58c;
  padding-right: 2.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232dc58c' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.6875rem center;
  background-size: 1.375rem 1.375rem;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #2dc58c;
  box-shadow: 0 0 0 0.25rem rgba(45, 197, 140, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: 2.75rem;
  background-position: top 0.6875rem right 0.6875rem;
}
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #2dc58c;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232dc58c' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 6.1875rem;
  background-position: right 1.125rem center, center right 3.375rem;
  background-size: 16px 12px, 1.375rem 1.375rem;
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #2dc58c;
  box-shadow: 0 0 0 0.25rem rgba(45, 197, 140, 0.25);
}
.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: 5.75rem;
}
.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #2dc58c;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #2dc58c;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(45, 197, 140, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #2dc58c;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated .input-group > .form-control:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #df3c4e;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #df3c4e;
  border-radius: 0.25rem;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #df3c4e;
  padding-right: 2.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23df3c4e'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23df3c4e' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.6875rem center;
  background-size: 1.375rem 1.375rem;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #df3c4e;
  box-shadow: 0 0 0 0.25rem rgba(223, 60, 78, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: 2.75rem;
  background-position: top 0.6875rem right 0.6875rem;
}
.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #df3c4e;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23df3c4e'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23df3c4e' stroke='none'/%3e%3c/svg%3e");
  padding-right: 6.1875rem;
  background-position: right 1.125rem center, center right 3.375rem;
  background-size: 16px 12px, 1.375rem 1.375rem;
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #df3c4e;
  box-shadow: 0 0 0 0.25rem rgba(223, 60, 78, 0.25);
}
.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: 5.75rem;
}
.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #df3c4e;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #df3c4e;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(223, 60, 78, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #df3c4e;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated .input-group > .form-control:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}
.btn {
  --bs-btn-padding-x: 1.125rem;
  --bs-btn-padding-y: 0.5625rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5rem;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0.0625rem;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5f38f9;
  --bs-btn-border-color: #5f38f9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5130d4;
  --bs-btn-hover-border-color: #4c2dc7;
  --bs-btn-focus-shadow-rgb: 119, 86, 250;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c2dc7;
  --bs-btn-active-border-color: #472abb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5f38f9;
  --bs-btn-disabled-border-color: #5f38f9;
}
.btn-purple {
  --bs-btn-color: #fff;
  --bs-btn-bg: #8927f9;
  --bs-btn-border-color: #8927f9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7421d4;
  --bs-btn-hover-border-color: #6e1fc7;
  --bs-btn-focus-shadow-rgb: 155, 71, 250;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6e1fc7;
  --bs-btn-active-border-color: #671dbb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #8927f9;
  --bs-btn-disabled-border-color: #8927f9;
}
.btn-pink {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f24a8b;
  --bs-btn-border-color: #f24a8b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ce3f76;
  --bs-btn-hover-border-color: #c23b6f;
  --bs-btn-focus-shadow-rgb: 244, 101, 156;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c23b6f;
  --bs-btn-active-border-color: #b63868;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f24a8b;
  --bs-btn-disabled-border-color: #f24a8b;
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #374151;
  --bs-btn-border-color: #374151;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2f3745;
  --bs-btn-hover-border-color: #2c3441;
  --bs-btn-focus-shadow-rgb: 85, 94, 107;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2c3441;
  --bs-btn-active-border-color: #29313d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #374151;
  --bs-btn-disabled-border-color: #374151;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2dc58c;
  --bs-btn-border-color: #2dc58c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #26a777;
  --bs-btn-hover-border-color: #249e70;
  --bs-btn-focus-shadow-rgb: 77, 206, 157;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #249e70;
  --bs-btn-active-border-color: #229469;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2dc58c;
  --bs-btn-disabled-border-color: #2dc58c;
}
.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #478ffc;
  --bs-btn-border-color: #478ffc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3c7ad6;
  --bs-btn-hover-border-color: #3972ca;
  --bs-btn-focus-shadow-rgb: 99, 160, 252;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3972ca;
  --bs-btn-active-border-color: #356bbd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #478ffc;
  --bs-btn-disabled-border-color: #478ffc;
}
.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #f2bc16;
  --bs-btn-border-color: #f2bc16;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f4c639;
  --bs-btn-hover-border-color: #f3c32d;
  --bs-btn-focus-shadow-rgb: 206, 160, 19;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5c945;
  --bs-btn-active-border-color: #f3c32d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f2bc16;
  --bs-btn-disabled-border-color: #f2bc16;
}
.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #df3c4e;
  --bs-btn-border-color: #df3c4e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #be3342;
  --bs-btn-hover-border-color: #b2303e;
  --bs-btn-focus-shadow-rgb: 228, 89, 105;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b2303e;
  --bs-btn-active-border-color: #a72d3b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #df3c4e;
  --bs-btn-disabled-border-color: #df3c4e;
}
.btn-lighter {
  --bs-btn-color: #000;
  --bs-btn-bg: #f1f2f7;
  --bs-btn-border-color: #f1f2f7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f3f4f8;
  --bs-btn-hover-border-color: #f2f3f8;
  --bs-btn-focus-shadow-rgb: 205, 206, 210;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f4f5f9;
  --bs-btn-active-border-color: #f2f3f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f1f2f7;
  --bs-btn-disabled-border-color: #f1f2f7;
}
.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #e5e7eb;
  --bs-btn-border-color: #e5e7eb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c3c4c8;
  --bs-btn-hover-border-color: #b7b9bc;
  --bs-btn-focus-shadow-rgb: 195, 196, 200;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b7b9bc;
  --bs-btn-active-border-color: #acadb0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e5e7eb;
  --bs-btn-disabled-border-color: #e5e7eb;
}
.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1f2937;
  --bs-btn-border-color: #1f2937;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #414955;
  --bs-btn-hover-border-color: #353e4b;
  --bs-btn-focus-shadow-rgb: 65, 73, 85;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c545f;
  --bs-btn-active-border-color: #353e4b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1f2937;
  --bs-btn-disabled-border-color: #1f2937;
}
.btn-darker {
  --bs-btn-color: #fff;
  --bs-btn-bg: #111827;
  --bs-btn-border-color: #111827;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0e1421;
  --bs-btn-hover-border-color: #0e131f;
  --bs-btn-focus-shadow-rgb: 53, 59, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0e131f;
  --bs-btn-active-border-color: #0d121d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #111827;
  --bs-btn-disabled-border-color: #111827;
}
.btn-outline-primary {
  --bs-btn-color: #5f38f9;
  --bs-btn-border-color: #5f38f9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5f38f9;
  --bs-btn-hover-border-color: #5f38f9;
  --bs-btn-focus-shadow-rgb: 95, 56, 249;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5f38f9;
  --bs-btn-active-border-color: #5f38f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5f38f9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5f38f9;
  --bs-gradient: none;
}
.btn-outline-purple {
  --bs-btn-color: #8927f9;
  --bs-btn-border-color: #8927f9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8927f9;
  --bs-btn-hover-border-color: #8927f9;
  --bs-btn-focus-shadow-rgb: 137, 39, 249;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8927f9;
  --bs-btn-active-border-color: #8927f9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #8927f9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8927f9;
  --bs-gradient: none;
}
.btn-outline-pink {
  --bs-btn-color: #f24a8b;
  --bs-btn-border-color: #f24a8b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f24a8b;
  --bs-btn-hover-border-color: #f24a8b;
  --bs-btn-focus-shadow-rgb: 242, 74, 139;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f24a8b;
  --bs-btn-active-border-color: #f24a8b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f24a8b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f24a8b;
  --bs-gradient: none;
}
.btn-outline-secondary {
  --bs-btn-color: #374151;
  --bs-btn-border-color: #374151;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #374151;
  --bs-btn-hover-border-color: #374151;
  --bs-btn-focus-shadow-rgb: 55, 65, 81;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #374151;
  --bs-btn-active-border-color: #374151;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #374151;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #374151;
  --bs-gradient: none;
}
.btn-outline-success {
  --bs-btn-color: #2dc58c;
  --bs-btn-border-color: #2dc58c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2dc58c;
  --bs-btn-hover-border-color: #2dc58c;
  --bs-btn-focus-shadow-rgb: 45, 197, 140;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2dc58c;
  --bs-btn-active-border-color: #2dc58c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2dc58c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2dc58c;
  --bs-gradient: none;
}
.btn-outline-info {
  --bs-btn-color: #478ffc;
  --bs-btn-border-color: #478ffc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #478ffc;
  --bs-btn-hover-border-color: #478ffc;
  --bs-btn-focus-shadow-rgb: 71, 143, 252;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #478ffc;
  --bs-btn-active-border-color: #478ffc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #478ffc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #478ffc;
  --bs-gradient: none;
}
.btn-outline-warning {
  --bs-btn-color: #f2bc16;
  --bs-btn-border-color: #f2bc16;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2bc16;
  --bs-btn-hover-border-color: #f2bc16;
  --bs-btn-focus-shadow-rgb: 242, 188, 22;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2bc16;
  --bs-btn-active-border-color: #f2bc16;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f2bc16;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f2bc16;
  --bs-gradient: none;
}
.btn-outline-danger {
  --bs-btn-color: #df3c4e;
  --bs-btn-border-color: #df3c4e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #df3c4e;
  --bs-btn-hover-border-color: #df3c4e;
  --bs-btn-focus-shadow-rgb: 223, 60, 78;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #df3c4e;
  --bs-btn-active-border-color: #df3c4e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #df3c4e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #df3c4e;
  --bs-gradient: none;
}
.btn-outline-lighter {
  --bs-btn-color: #f1f2f7;
  --bs-btn-border-color: #f1f2f7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1f2f7;
  --bs-btn-hover-border-color: #f1f2f7;
  --bs-btn-focus-shadow-rgb: 241, 242, 247;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f1f2f7;
  --bs-btn-active-border-color: #f1f2f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f1f2f7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f1f2f7;
  --bs-gradient: none;
}
.btn-outline-light {
  --bs-btn-color: #e5e7eb;
  --bs-btn-border-color: #e5e7eb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e5e7eb;
  --bs-btn-hover-border-color: #e5e7eb;
  --bs-btn-focus-shadow-rgb: 229, 231, 235;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e5e7eb;
  --bs-btn-active-border-color: #e5e7eb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e5e7eb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e5e7eb;
  --bs-gradient: none;
}
.btn-outline-dark {
  --bs-btn-color: #1f2937;
  --bs-btn-border-color: #1f2937;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1f2937;
  --bs-btn-hover-border-color: #1f2937;
  --bs-btn-focus-shadow-rgb: 31, 41, 55;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1f2937;
  --bs-btn-active-border-color: #1f2937;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1f2937;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1f2937;
  --bs-gradient: none;
}
.btn-outline-darker {
  --bs-btn-color: #111827;
  --bs-btn-border-color: #111827;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #111827;
  --bs-btn-hover-border-color: #111827;
  --bs-btn-focus-shadow-rgb: 17, 24, 39;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #111827;
  --bs-btn-active-border-color: #111827;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #111827;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #111827;
  --bs-gradient: none;
}
.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #4b5563;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 119, 86, 250;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}
.btn-lg,
.btn-group-lg > .btn {
  --bs-btn-padding-y: 0.9375rem;
  --bs-btn-padding-x: 1.375rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.75rem;
}
.btn-sm,
.btn-group-sm > .btn {
  --bs-btn-padding-y: 0.125rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: 0.25rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid rgba(0, 0, 0, 0);
  border-bottom: 0;
  border-left: 0.3em solid rgba(0, 0, 0, 0);
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 200px;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.813rem;
  --bs-dropdown-color: #43476b;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: #e8e7ec;
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: subtract(0.375rem, 0);
  --bs-dropdown-divider-bg: #e8e7ec;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 2px 12px -1px rgba(67, 71, 107, 0.2);
  --bs-dropdown-link-color: #787c9e;
  --bs-dropdown-link-hover-color: #5f38f9;
  --bs-dropdown-link-hover-bg: rgba(95, 56, 249, 0.06);
  --bs-dropdown-link-active-color: #5f38f9;
  --bs-dropdown-link-active-bg: rgba(95, 56, 249, 0.06);
  --bs-dropdown-link-disabled-color: #6b7280;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.375rem;
  --bs-dropdown-header-color: #4b5563;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}
.dropdown-menu > .dropdown-item:first-child,
.dropdown-menu > li:first-child .dropdown-item {
  border-top-left-radius: var(--bs-dropdown-inner-border-radius);
  border-top-right-radius: var(--bs-dropdown-inner-border-radius);
}
.dropdown-menu > .dropdown-item:last-child,
.dropdown-menu > li:last-child .dropdown-item {
  border-bottom-right-radius: var(--bs-dropdown-inner-border-radius);
  border-bottom-left-radius: var(--bs-dropdown-inner-border-radius);
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid rgba(0, 0, 0, 0);
  border-bottom: 0.3em solid;
  border-left: 0.3em solid rgba(0, 0, 0, 0);
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid rgba(0, 0, 0, 0);
  border-right: 0;
  border-bottom: 0.3em solid rgba(0, 0, 0, 0);
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid rgba(0, 0, 0, 0);
  border-right: 0.3em solid;
  border-bottom: 0.3em solid rgba(0, 0, 0, 0);
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.75rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}
.dropdown-menu-dark {
  --bs-dropdown-color: #d1d5db;
  --bs-dropdown-bg: #1f2937;
  --bs-dropdown-border-color: #e8e7ec;
  --bs-dropdown-box-shadow: 0 2px 12px -1px rgba(67, 71, 107, 0.2);
  --bs-dropdown-link-color: #d1d5db;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #e8e7ec;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #5f38f9;
  --bs-dropdown-link-active-bg: rgba(95, 56, 249, 0.06);
  --bs-dropdown-link-disabled-color: #6b7280;
  --bs-dropdown-header-color: #6b7280;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(0.0625rem * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.03125rem;
  padding-left: 1.03125rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(0.0625rem * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  --bs-nav-link-padding-x: 1.125rem;
  --bs-nav-link-padding-y: 0.5625rem;
  --bs-nav-link-font-size: 0.875rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: #787c9e;
  --bs-nav-link-hover-color: #24214b;
  --bs-nav-link-disabled-color: #9ca3af;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 128, 255, 0.25);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #e5e7eb;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e5e7eb #e5e7eb #e5e7eb;
  --bs-nav-tabs-link-active-color: #24214b;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #d1d5db #d1d5db #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid rgba(0, 0, 0, 0);
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #5f38f9;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}
.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid rgba(0, 0, 0, 0);
}
.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.458125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.125rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.125rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5625rem;
  --bs-nav-link-font-size: 0.875rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: rgba(0, 0, 0, 0);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #e8e7ec;
  --bs-card-border-radius: 0.5rem;
  --bs-card-box-shadow: 0 1px 0px rgba(232, 231, 236, 0.5);
  --bs-card-inner-border-radius: calc(0.5rem - 1px);
  --bs-card-cap-padding-y: 0.625rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: #f8f8f9;
  --bs-card-cap-color: #43476b;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.875rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}
.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}
.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}
.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}
.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}
.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.accordion {
  --bs-accordion-color: #43476b;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: all 0.2s;
  --bs-accordion-border-color: #e8e7ec;
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: subtract(0.375rem, 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #43476b;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2343476b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 0.875rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2324214b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #e8e7ec;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: shade-color(#5f38f9, 10%);
  --bs-accordion-active-bg: transparent;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}
[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2366b3ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2366b3ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-font-size: 0.75rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #787c9e;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #787c9e;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(
    --bs-breadcrumb-divider,
    "/"
  ); /* rtl: var(--bs-breadcrumb-divider, "/") */
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.813rem;
  --bs-pagination-font-size: 0.75rem;
  --bs-pagination-color: #43476b;
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #d2d4e4;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: #5f38f9;
  --bs-pagination-hover-bg: #f2efff;
  --bs-pagination-hover-border-color: #d2d4e4;
  --bs-pagination-focus-color: #5f38f9;
  --bs-pagination-focus-bg: #ece7fe;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #5f38f9;
  --bs-pagination-active-bg: #dfd7fe;
  --bs-pagination-active-border-color: #dfd7fe;
  --bs-pagination-disabled-color: #9ca3af;
  --bs-pagination-disabled-bg: transparent;
  --bs-pagination-disabled-border-color: #d2d4e4;
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled,
.disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item .page-link {
  border-radius: var(--bs-pagination-border-radius);
}
.pagination-lg {
  --bs-pagination-padding-x: 1.313rem;
  --bs-pagination-padding-y: 1rem;
  --bs-pagination-font-size: 1.125rem;
  --bs-pagination-border-radius: 0.375rem;
}
.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.438rem;
  --bs-pagination-font-size: 0.75rem;
  --bs-pagination-border-radius: 0.25rem;
}
.badge {
  --bs-badge-padding-x: 0.5rem;
  --bs-badge-padding-y: 0.35rem;
  --bs-badge-font-size: 0.688rem;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1rem;
}
.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}
.alert-purple {
  --bs-alert-color: var(--bs-purple-text-emphasis);
  --bs-alert-bg: var(--bs-purple-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-border-subtle);
  --bs-alert-link-color: var(--bs-purple-text-emphasis);
}
.alert-pink {
  --bs-alert-color: var(--bs-pink-text-emphasis);
  --bs-alert-bg: var(--bs-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-border-subtle);
  --bs-alert-link-color: var(--bs-pink-text-emphasis);
}
.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}
.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}
.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}
.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}
.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}
.alert-lighter {
  --bs-alert-color: var(--bs-lighter-text-emphasis);
  --bs-alert-bg: var(--bs-lighter-bg-subtle);
  --bs-alert-border-color: var(--bs-lighter-border-subtle);
  --bs-alert-link-color: var(--bs-lighter-text-emphasis);
}
.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}
.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}
.alert-darker {
  --bs-alert-color: var(--bs-darker-text-emphasis);
  --bs-alert-bg: var(--bs-darker-bg-subtle);
  --bs-alert-border-color: var(--bs-darker-border-subtle);
  --bs-alert-link-color: var(--bs-darker-text-emphasis);
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.75rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 0.75rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #f1f2f7;
  --bs-progress-border-radius: 0.25rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #5f38f9;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}
.progress-stacked > .progress {
  overflow: visible;
}
.progress-stacked > .progress > .progress-bar {
  width: 100%;
}
.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.list-group {
  --bs-list-group-color: #2e314a;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #374151;
  --bs-list-group-action-hover-color: #374151;
  --bs-list-group-action-hover-bg: #f1f2f7;
  --bs-list-group-action-active-color: #43476b;
  --bs-list-group-action-active-bg: #e5e7eb;
  --bs-list-group-disabled-color: #4b5563;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #5f38f9;
  --bs-list-group-active-border-color: #5f38f9;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}
.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}
.list-group-item-purple {
  --bs-list-group-color: var(--bs-purple-text-emphasis);
  --bs-list-group-bg: var(--bs-purple-bg-subtle);
  --bs-list-group-border-color: var(--bs-purple-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-purple-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-purple-border-subtle);
  --bs-list-group-active-color: var(--bs-purple-bg-subtle);
  --bs-list-group-active-bg: var(--bs-purple-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-purple-text-emphasis);
}
.list-group-item-pink {
  --bs-list-group-color: var(--bs-pink-text-emphasis);
  --bs-list-group-bg: var(--bs-pink-bg-subtle);
  --bs-list-group-border-color: var(--bs-pink-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-pink-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-pink-border-subtle);
  --bs-list-group-active-color: var(--bs-pink-bg-subtle);
  --bs-list-group-active-bg: var(--bs-pink-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-pink-text-emphasis);
}
.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}
.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}
.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}
.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}
.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}
.list-group-item-lighter {
  --bs-list-group-color: var(--bs-lighter-text-emphasis);
  --bs-list-group-bg: var(--bs-lighter-bg-subtle);
  --bs-list-group-border-color: var(--bs-lighter-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-lighter-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-lighter-border-subtle);
  --bs-list-group-active-color: var(--bs-lighter-bg-subtle);
  --bs-list-group-active-bg: var(--bs-lighter-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-lighter-text-emphasis);
}
.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}
.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}
.list-group-item-darker {
  --bs-list-group-color: var(--bs-darker-text-emphasis);
  --bs-list-group-bg: var(--bs-darker-bg-subtle);
  --bs-list-group-border-color: var(--bs-darker-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-darker-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-darker-border-subtle);
  --bs-list-group-active-color: var(--bs-darker-bg-subtle);
  --bs-list-group-active-bg: var(--bs-darker-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-darker-text-emphasis);
}
.btn-close {
  --bs-btn-close-color: #24214b;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2324214b'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: rgba(0, 0, 0, 0) var(--bs-btn-close-bg) center/0.75rem auto
    no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}
.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}
[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}
.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.75rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #4b5563;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}
.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  border-top-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}
.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: #d2d4e4;
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.75rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: subtract(0.75rem, 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: #d2d4e4;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.67;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #d2d4e4;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #24214b;
  --bs-backdrop-opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y))
    calc(-0.5 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}
.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.75rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #24214b;
  --bs-tooltip-border-radius: 0.25rem;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.67;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}
.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: #d2d4e4;
  --bs-popover-border-radius: 0.75rem;
  --bs-popover-inner-border-radius: subtract(0.75rem, 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #43476b;
  --bs-popover-header-bg: shade-color(#fff, 6%);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #43476b;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.67;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 0;
}
.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height)
    calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}
.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}
.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-header-bg);
}
.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0
    calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon,
[data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme="dark"] .carousel .carousel-caption,
[data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}
.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg); /* rtl:ignore */
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: rgba(0, 0, 0, 0);
}
.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1.5rem;
  --bs-offcanvas-padding-y: 1.5rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: #d2d4e4;
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.67;
}
@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #24214b;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.8;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}
.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}
@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(95, 56, 249, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-purple {
  color: #fff !important;
  background-color: RGBA(137, 39, 249, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-pink {
  color: #fff !important;
  background-color: RGBA(242, 74, 139, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(55, 65, 81, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-success {
  color: #fff !important;
  background-color: RGBA(45, 197, 140, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-info {
  color: #fff !important;
  background-color: RGBA(71, 143, 252, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(242, 188, 22, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(223, 60, 78, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-lighter {
  color: #000 !important;
  background-color: RGBA(241, 242, 247, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-light {
  color: #000 !important;
  background-color: RGBA(229, 231, 235, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(31, 41, 55, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-darker {
  color: #fff !important;
  background-color: RGBA(17, 24, 39, var(--bs-bg-opacity, 1)) !important;
}
.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-primary-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-primary:hover,
.link-primary:focus {
  color: RGBA(76, 45, 199, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    76,
    45,
    199,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-purple {
  color: RGBA(var(--bs-purple-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-purple-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-purple:hover,
.link-purple:focus {
  color: RGBA(110, 31, 199, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    110,
    31,
    199,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-pink {
  color: RGBA(var(--bs-pink-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-pink-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-pink:hover,
.link-pink:focus {
  color: RGBA(194, 59, 111, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    194,
    59,
    111,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-secondary-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-secondary:hover,
.link-secondary:focus {
  color: RGBA(44, 52, 65, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    44,
    52,
    65,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-success-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-success:hover,
.link-success:focus {
  color: RGBA(36, 158, 112, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    36,
    158,
    112,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-info-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-info:hover,
.link-info:focus {
  color: RGBA(57, 114, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    57,
    114,
    202,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-warning-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-warning:hover,
.link-warning:focus {
  color: RGBA(245, 201, 69, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    245,
    201,
    69,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-danger-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-danger:hover,
.link-danger:focus {
  color: RGBA(178, 48, 62, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    178,
    48,
    62,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-lighter {
  color: RGBA(var(--bs-lighter-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-lighter-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-lighter:hover,
.link-lighter:focus {
  color: RGBA(244, 245, 249, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    244,
    245,
    249,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-light-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-light:hover,
.link-light:focus {
  color: RGBA(234, 236, 239, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    234,
    236,
    239,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-dark-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-dark:hover,
.link-dark:focus {
  color: RGBA(25, 33, 44, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    25,
    33,
    44,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-darker {
  color: RGBA(var(--bs-darker-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-darker-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-darker:hover,
.link-darker:focus {
  color: RGBA(14, 19, 31, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    14,
    19,
    31,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-body-emphasis {
  color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-opacity, 1)
  ) !important;
  text-decoration-color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-body-emphasis:hover,
.link-body-emphasis:focus {
  color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-opacity, 0.75)
  ) !important;
  text-decoration-color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-underline-opacity, 0.75)
  ) !important;
}
.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0)
    var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width)
    var(--bs-focus-ring-color);
}
.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(
    var(--bs-link-color-rgb),
    var(--bs-link-opacity, 0.5)
  );
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}
.icon-link-hover:hover > .bi,
.icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.object-fit-contain {
  object-fit: contain !important;
}
.object-fit-cover {
  object-fit: cover !important;
}
.object-fit-fill {
  object-fit: fill !important;
}
.object-fit-scale {
  object-fit: scale-down !important;
}
.object-fit-none {
  object-fit: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-x-visible {
  overflow-x: visible !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-y-visible {
  overflow-y: visible !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-inline-grid {
  display: inline-grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.focus-ring-primary {
  --bs-focus-ring-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-purple {
  --bs-focus-ring-color: rgba(
    var(--bs-purple-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-pink {
  --bs-focus-ring-color: rgba(var(--bs-pink-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring-secondary {
  --bs-focus-ring-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-success {
  --bs-focus-ring-color: rgba(
    var(--bs-success-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring-warning {
  --bs-focus-ring-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-danger {
  --bs-focus-ring-color: rgba(
    var(--bs-danger-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-lighter {
  --bs-focus-ring-color: rgba(
    var(--bs-lighter-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-light {
  --bs-focus-ring-color: rgba(
    var(--bs-light-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring-darker {
  --bs-focus-ring-color: rgba(
    var(--bs-darker-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}
.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}
.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}
.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
.border-lighter {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-lighter-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}
.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}
.border-darker {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-darker-rgb), var(--bs-border-opacity)) !important;
}
.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}
.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}
.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}
.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}
.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}
.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}
.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}
.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}
.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}
.border-1 {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.border-opacity-10 {
  --bs-border-opacity: 0.1;
}
.border-opacity-25 {
  --bs-border-opacity: 0.25;
}
.border-opacity-50 {
  --bs-border-opacity: 0.5;
}
.border-opacity-75 {
  --bs-border-opacity: 0.75;
}
.border-opacity-100 {
  --bs-border-opacity: 1;
}
.w-25 {
  width: 25% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.375rem !important;
}
.m-2 {
  margin: 0.75rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 2.75rem !important;
}
.m-gs {
  margin: 1.75rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}
.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}
.mx-gs {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}
.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}
.my-gs {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.375rem !important;
}
.mt-2 {
  margin-top: 0.75rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 2.75rem !important;
}
.mt-gs {
  margin-top: 1.75rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.375rem !important;
}
.me-2 {
  margin-right: 0.75rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 2.75rem !important;
}
.me-gs {
  margin-right: 1.75rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.375rem !important;
}
.mb-2 {
  margin-bottom: 0.75rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 2.75rem !important;
}
.mb-gs {
  margin-bottom: 1.75rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.375rem !important;
}
.ms-2 {
  margin-left: 0.75rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 2.75rem !important;
}
.ms-gs {
  margin-left: 1.75rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.375rem !important;
}
.m-n2 {
  margin: -0.75rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.m-n5 {
  margin: -2.75rem !important;
}
.m-ngs {
  margin: -1.75rem !important;
}
.mx-n1 {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important;
}
.mx-n2 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}
.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
.mx-n5 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}
.mx-ngs {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}
.my-n1 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}
.my-n2 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}
.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.my-n5 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}
.my-ngs {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}
.mt-n1 {
  margin-top: -0.375rem !important;
}
.mt-n2 {
  margin-top: -0.75rem !important;
}
.mt-n3 {
  margin-top: -1rem !important;
}
.mt-n4 {
  margin-top: -1.5rem !important;
}
.mt-n5 {
  margin-top: -2.75rem !important;
}
.mt-ngs {
  margin-top: -1.75rem !important;
}
.me-n1 {
  margin-right: -0.375rem !important;
}
.me-n2 {
  margin-right: -0.75rem !important;
}
.me-n3 {
  margin-right: -1rem !important;
}
.me-n4 {
  margin-right: -1.5rem !important;
}
.me-n5 {
  margin-right: -2.75rem !important;
}
.me-ngs {
  margin-right: -1.75rem !important;
}
.mb-n1 {
  margin-bottom: -0.375rem !important;
}
.mb-n2 {
  margin-bottom: -0.75rem !important;
}
.mb-n3 {
  margin-bottom: -1rem !important;
}
.mb-n4 {
  margin-bottom: -1.5rem !important;
}
.mb-n5 {
  margin-bottom: -2.75rem !important;
}
.mb-ngs {
  margin-bottom: -1.75rem !important;
}
.ms-n1 {
  margin-left: -0.375rem !important;
}
.ms-n2 {
  margin-left: -0.75rem !important;
}
.ms-n3 {
  margin-left: -1rem !important;
}
.ms-n4 {
  margin-left: -1.5rem !important;
}
.ms-n5 {
  margin-left: -2.75rem !important;
}
.ms-ngs {
  margin-left: -1.75rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.375rem !important;
}
.p-2 {
  padding: 0.75rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 2.75rem !important;
}
.p-gs {
  padding: 1.75rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}
.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}
.px-gs {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}
.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}
.py-gs {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.375rem !important;
}
.pt-2 {
  padding-top: 0.75rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 2.75rem !important;
}
.pt-gs {
  padding-top: 1.75rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.375rem !important;
}
.pe-2 {
  padding-right: 0.75rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 2.75rem !important;
}
.pe-gs {
  padding-right: 1.75rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.375rem !important;
}
.pb-2 {
  padding-bottom: 0.75rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 2.75rem !important;
}
.pb-gs {
  padding-bottom: 1.75rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.375rem !important;
}
.ps-2 {
  padding-left: 0.75rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 2.75rem !important;
}
.ps-gs {
  padding-left: 1.75rem !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.375rem !important;
}
.gap-2 {
  gap: 0.75rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 2.75rem !important;
}
.gap-gs {
  gap: 1.75rem !important;
}
.row-gap-0 {
  row-gap: 0 !important;
}
.row-gap-1 {
  row-gap: 0.375rem !important;
}
.row-gap-2 {
  row-gap: 0.75rem !important;
}
.row-gap-3 {
  row-gap: 1rem !important;
}
.row-gap-4 {
  row-gap: 1.5rem !important;
}
.row-gap-5 {
  row-gap: 2.75rem !important;
}
.row-gap-gs {
  row-gap: 1.75rem !important;
}
.column-gap-0 {
  column-gap: 0 !important;
}
.column-gap-1 {
  column-gap: 0.375rem !important;
}
.column-gap-2 {
  column-gap: 0.75rem !important;
}
.column-gap-3 {
  column-gap: 1rem !important;
}
.column-gap-4 {
  column-gap: 1.5rem !important;
}
.column-gap-5 {
  column-gap: 2.75rem !important;
}
.column-gap-gs {
  column-gap: 1.75rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.fs-3 {
  font-size: 1.25rem !important;
}
.fs-4 {
  font-size: 1.125rem !important;
}
.fs-5 {
  font-size: 1rem !important;
}
.fs-6 {
  font-size: 0.875rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.35 !important;
}
.lh-base {
  line-height: 1.67 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}
.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-lighter {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-lighter-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-darker {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-darker-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}
.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}
.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}
.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}
.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}
.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}
.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}
.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}
.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}
.link-opacity-10 {
  --bs-link-opacity: 0.1;
}
.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}
.link-opacity-25 {
  --bs-link-opacity: 0.25;
}
.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}
.link-opacity-50 {
  --bs-link-opacity: 0.5;
}
.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}
.link-opacity-75 {
  --bs-link-opacity: 0.75;
}
.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}
.link-opacity-100 {
  --bs-link-opacity: 1;
}
.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}
.link-offset-1 {
  text-underline-offset: 0.125em !important;
}
.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}
.link-offset-2 {
  text-underline-offset: 0.25em !important;
}
.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}
.link-offset-3 {
  text-underline-offset: 0.375em !important;
}
.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}
.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-purple {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-purple-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-pink-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-success-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-info-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-danger-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-lighter {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-lighter-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-light-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-dark-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-darker {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-darker-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-link-color-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}
.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}
.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}
.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}
.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}
.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}
.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}
.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}
.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}
.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}
.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}
.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}
.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-lighter {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-lighter-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-darker {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-darker-rgb), var(--bs-bg-opacity)) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
}
.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-tertiary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}
.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}
.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}
.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}
.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}
.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}
.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}
.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  user-select: all !important;
}
.user-select-auto {
  user-select: auto !important;
}
.user-select-none {
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}
.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}
.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}
.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}
.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}
.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}
.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}
.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}
.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}
.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}
.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}
.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}
.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}
.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}
.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}
.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}
.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.z-n1 {
  z-index: -1 !important;
}
.z-0 {
  z-index: 0 !important;
}
.z-1 {
  z-index: 1 !important;
}
.z-2 {
  z-index: 2 !important;
}
.z-3 {
  z-index: 3 !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2.75rem !important;
  }
  .m-sm-gs {
    margin: 1.75rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-sm-gs {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-sm-gs {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.75rem !important;
  }
  .mt-sm-gs {
    margin-top: 1.75rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.375rem !important;
  }
  .me-sm-2 {
    margin-right: 0.75rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2.75rem !important;
  }
  .me-sm-gs {
    margin-right: 1.75rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-sm-gs {
    margin-bottom: 1.75rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.375rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.75rem !important;
  }
  .ms-sm-gs {
    margin-left: 1.75rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2.75rem !important;
  }
  .m-sm-ngs {
    margin: -1.75rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-sm-ngs {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-sm-ngs {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-sm-ngs {
    margin-top: -1.75rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2.75rem !important;
  }
  .me-sm-ngs {
    margin-right: -1.75rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-sm-ngs {
    margin-bottom: -1.75rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-sm-ngs {
    margin-left: -1.75rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2.75rem !important;
  }
  .p-sm-gs {
    padding: 1.75rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-sm-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-sm-gs {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-sm-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-sm-gs {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2.75rem !important;
  }
  .pt-sm-gs {
    padding-top: 1.75rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2.75rem !important;
  }
  .pe-sm-gs {
    padding-right: 1.75rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-sm-gs {
    padding-bottom: 1.75rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.375rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2.75rem !important;
  }
  .ps-sm-gs {
    padding-left: 1.75rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.375rem !important;
  }
  .gap-sm-2 {
    gap: 0.75rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2.75rem !important;
  }
  .gap-sm-gs {
    gap: 1.75rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-sm-gs {
    row-gap: 1.75rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-sm-gs {
    column-gap: 1.75rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2.75rem !important;
  }
  .m-md-gs {
    margin: 1.75rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-md-gs {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-md-gs {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.375rem !important;
  }
  .mt-md-2 {
    margin-top: 0.75rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2.75rem !important;
  }
  .mt-md-gs {
    margin-top: 1.75rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.375rem !important;
  }
  .me-md-2 {
    margin-right: 0.75rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2.75rem !important;
  }
  .me-md-gs {
    margin-right: 1.75rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-md-gs {
    margin-bottom: 1.75rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.375rem !important;
  }
  .ms-md-2 {
    margin-left: 0.75rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2.75rem !important;
  }
  .ms-md-gs {
    margin-left: 1.75rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2.75rem !important;
  }
  .m-md-ngs {
    margin: -1.75rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-md-ngs {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .my-md-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-md-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-md-ngs {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-md-ngs {
    margin-top: -1.75rem !important;
  }
  .me-md-n1 {
    margin-right: -0.375rem !important;
  }
  .me-md-n2 {
    margin-right: -0.75rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2.75rem !important;
  }
  .me-md-ngs {
    margin-right: -1.75rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-md-ngs {
    margin-bottom: -1.75rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-md-ngs {
    margin-left: -1.75rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2.75rem !important;
  }
  .p-md-gs {
    padding: 1.75rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-md-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-md-gs {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-md-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-md-gs {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.375rem !important;
  }
  .pt-md-2 {
    padding-top: 0.75rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2.75rem !important;
  }
  .pt-md-gs {
    padding-top: 1.75rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.375rem !important;
  }
  .pe-md-2 {
    padding-right: 0.75rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2.75rem !important;
  }
  .pe-md-gs {
    padding-right: 1.75rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-md-gs {
    padding-bottom: 1.75rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.375rem !important;
  }
  .ps-md-2 {
    padding-left: 0.75rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2.75rem !important;
  }
  .ps-md-gs {
    padding-left: 1.75rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.375rem !important;
  }
  .gap-md-2 {
    gap: 0.75rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2.75rem !important;
  }
  .gap-md-gs {
    gap: 1.75rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-md-gs {
    row-gap: 1.75rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-md-gs {
    column-gap: 1.75rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2.75rem !important;
  }
  .m-lg-gs {
    margin: 1.75rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-lg-gs {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-lg-gs {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.75rem !important;
  }
  .mt-lg-gs {
    margin-top: 1.75rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.375rem !important;
  }
  .me-lg-2 {
    margin-right: 0.75rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2.75rem !important;
  }
  .me-lg-gs {
    margin-right: 1.75rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-lg-gs {
    margin-bottom: 1.75rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.375rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.75rem !important;
  }
  .ms-lg-gs {
    margin-left: 1.75rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2.75rem !important;
  }
  .m-lg-ngs {
    margin: -1.75rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-lg-ngs {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-lg-ngs {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-lg-ngs {
    margin-top: -1.75rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2.75rem !important;
  }
  .me-lg-ngs {
    margin-right: -1.75rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-lg-ngs {
    margin-bottom: -1.75rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-lg-ngs {
    margin-left: -1.75rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2.75rem !important;
  }
  .p-lg-gs {
    padding: 1.75rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-lg-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-lg-gs {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-lg-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-lg-gs {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2.75rem !important;
  }
  .pt-lg-gs {
    padding-top: 1.75rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2.75rem !important;
  }
  .pe-lg-gs {
    padding-right: 1.75rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-lg-gs {
    padding-bottom: 1.75rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.375rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2.75rem !important;
  }
  .ps-lg-gs {
    padding-left: 1.75rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.375rem !important;
  }
  .gap-lg-2 {
    gap: 0.75rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2.75rem !important;
  }
  .gap-lg-gs {
    gap: 1.75rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-lg-gs {
    row-gap: 1.75rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-lg-gs {
    column-gap: 1.75rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2.75rem !important;
  }
  .m-xl-gs {
    margin: 1.75rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-xl-gs {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-xl-gs {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.75rem !important;
  }
  .mt-xl-gs {
    margin-top: 1.75rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2.75rem !important;
  }
  .me-xl-gs {
    margin-right: 1.75rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-xl-gs {
    margin-bottom: 1.75rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.75rem !important;
  }
  .ms-xl-gs {
    margin-left: 1.75rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2.75rem !important;
  }
  .m-xl-ngs {
    margin: -1.75rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-xl-ngs {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-xl-ngs {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-xl-ngs {
    margin-top: -1.75rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2.75rem !important;
  }
  .me-xl-ngs {
    margin-right: -1.75rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-xl-ngs {
    margin-bottom: -1.75rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-xl-ngs {
    margin-left: -1.75rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2.75rem !important;
  }
  .p-xl-gs {
    padding: 1.75rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-xl-gs {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-xl-gs {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2.75rem !important;
  }
  .pt-xl-gs {
    padding-top: 1.75rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2.75rem !important;
  }
  .pe-xl-gs {
    padding-right: 1.75rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-xl-gs {
    padding-bottom: 1.75rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2.75rem !important;
  }
  .ps-xl-gs {
    padding-left: 1.75rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.375rem !important;
  }
  .gap-xl-2 {
    gap: 0.75rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2.75rem !important;
  }
  .gap-xl-gs {
    gap: 1.75rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xl-gs {
    row-gap: 1.75rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xl-gs {
    column-gap: 1.75rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.375rem !important;
  }
  .m-xxl-2 {
    margin: 0.75rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2.75rem !important;
  }
  .m-xxl-gs {
    margin: 1.75rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-xxl-gs {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-xxl-gs {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2.75rem !important;
  }
  .mt-xxl-gs {
    margin-top: 1.75rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2.75rem !important;
  }
  .me-xxl-gs {
    margin-right: 1.75rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-xxl-gs {
    margin-bottom: 1.75rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2.75rem !important;
  }
  .ms-xxl-gs {
    margin-left: 1.75rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.375rem !important;
  }
  .m-xxl-n2 {
    margin: -0.75rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2.75rem !important;
  }
  .m-xxl-ngs {
    margin: -1.75rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-xxl-ngs {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-xxl-ngs {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-xxl-ngs {
    margin-top: -1.75rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2.75rem !important;
  }
  .me-xxl-ngs {
    margin-right: -1.75rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-xxl-ngs {
    margin-bottom: -1.75rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-xxl-ngs {
    margin-left: -1.75rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.375rem !important;
  }
  .p-xxl-2 {
    padding: 0.75rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2.75rem !important;
  }
  .p-xxl-gs {
    padding: 1.75rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-xxl-gs {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-xxl-gs {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2.75rem !important;
  }
  .pt-xxl-gs {
    padding-top: 1.75rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2.75rem !important;
  }
  .pe-xxl-gs {
    padding-right: 1.75rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-xxl-gs {
    padding-bottom: 1.75rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2.75rem !important;
  }
  .ps-xxl-gs {
    padding-left: 1.75rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.375rem !important;
  }
  .gap-xxl-2 {
    gap: 0.75rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2.75rem !important;
  }
  .gap-xxl-gs {
    gap: 1.75rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xxl-gs {
    row-gap: 1.75rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xxl-gs {
    column-gap: 1.75rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.75rem !important;
  }
  .fs-2 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.bg-primary-soft {
  background-color: #ece7fe;
}
.bg-primary-hover:hover {
  background-color: #5f38f9;
}
.bg-primary-soft-hover:hover {
  background-color: #ece7fe;
}
.bg-purple-soft {
  background-color: #f1e5fe;
}
.bg-purple-hover:hover {
  background-color: #8927f9;
}
.bg-purple-soft-hover:hover {
  background-color: #f1e5fe;
}
.bg-pink-soft {
  background-color: #fde9f1;
}
.bg-pink-hover:hover {
  background-color: #f24a8b;
}
.bg-pink-soft-hover:hover {
  background-color: #fde9f1;
}
.bg-secondary-soft {
  background-color: #e7e8ea;
}
.bg-secondary-hover:hover {
  background-color: #374151;
}
.bg-secondary-soft-hover:hover {
  background-color: #e7e8ea;
}
.bg-success-soft {
  background-color: #e6f8f1;
}
.bg-success-hover:hover {
  background-color: #2dc58c;
}
.bg-success-soft-hover:hover {
  background-color: #e6f8f1;
}
.bg-info-soft {
  background-color: #e9f2ff;
}
.bg-info-hover:hover {
  background-color: #478ffc;
}
.bg-info-soft-hover:hover {
  background-color: #e9f2ff;
}
.bg-warning-soft {
  background-color: #fdf7e3;
}
.bg-warning-hover:hover {
  background-color: #f2bc16;
}
.bg-warning-soft-hover:hover {
  background-color: #fdf7e3;
}
.bg-danger-soft {
  background-color: #fbe8ea;
}
.bg-danger-hover:hover {
  background-color: #df3c4e;
}
.bg-danger-soft-hover:hover {
  background-color: #fbe8ea;
}
.bg-lighter-soft {
  background-color: #fdfdfe;
}
.bg-lighter-hover:hover {
  background-color: #f1f2f7;
}
.bg-lighter-soft-hover:hover {
  background-color: #fdfdfe;
}
.bg-light-soft {
  background-color: #fcfcfd;
}
.bg-light-hover:hover {
  background-color: #e5e7eb;
}
.bg-light-soft-hover:hover {
  background-color: #fcfcfd;
}
.bg-dark-soft {
  background-color: #e4e5e7;
}
.bg-dark-hover:hover {
  background-color: #1f2937;
}
.bg-dark-soft-hover:hover {
  background-color: #e4e5e7;
}
.bg-darker-soft {
  background-color: #e2e3e5;
}
.bg-darker-hover:hover {
  background-color: #111827;
}
.bg-darker-soft-hover:hover {
  background-color: #e2e3e5;
}
.text-bg-primary-soft {
  color: #5f38f9;
  background-color: #ece7fe !important;
  border-color: transparent;
}
.text-bg-primary-soft-outline {
  color: #5f38f9;
  background-color: #ece7fe !important;
  border-color: #cfc3fd;
}
.text-bg-purple-soft {
  color: #8927f9;
  background-color: #f1e5fe !important;
  border-color: transparent;
}
.text-bg-purple-soft-outline {
  color: #8927f9;
  background-color: #f1e5fe !important;
  border-color: #dcbefd;
}
.text-bg-pink-soft {
  color: #f24a8b;
  background-color: #fde9f1 !important;
  border-color: transparent;
}
.text-bg-pink-soft-outline {
  color: #f24a8b;
  background-color: #fde9f1 !important;
  border-color: #fbc9dc;
}
.text-bg-secondary-soft {
  color: #374151;
  background-color: #e7e8ea !important;
  border-color: transparent;
}
.text-bg-secondary-soft-outline {
  color: #374151;
  background-color: #e7e8ea !important;
  border-color: #c3c6cb;
}
.text-bg-success-soft {
  color: #2dc58c;
  background-color: #e6f8f1 !important;
  border-color: transparent;
}
.text-bg-success-soft-outline {
  color: #2dc58c;
  background-color: #e6f8f1 !important;
  border-color: #c0eedd;
}
.text-bg-info-soft {
  color: #478ffc;
  background-color: #e9f2ff !important;
  border-color: transparent;
}
.text-bg-info-soft-outline {
  color: #478ffc;
  background-color: #e9f2ff !important;
  border-color: #c8ddfe;
}
.text-bg-warning-soft {
  color: #f2bc16;
  background-color: #fdf7e3 !important;
  border-color: transparent;
}
.text-bg-warning-soft-outline {
  color: #f2bc16;
  background-color: #fdf7e3 !important;
  border-color: #fbebb9;
}
.text-bg-danger-soft {
  color: #df3c4e;
  background-color: #fbe8ea !important;
  border-color: transparent;
}
.text-bg-danger-soft-outline {
  color: #df3c4e;
  background-color: #fbe8ea !important;
  border-color: #f5c5ca;
}
.text-bg-lighter-soft {
  color: #f1f2f7;
  background-color: #fdfdfe !important;
  border-color: transparent;
}
.text-bg-lighter-soft-outline {
  color: #f1f2f7;
  background-color: #fdfdfe !important;
  border-color: #fbfbfd;
}
.text-bg-light-soft {
  color: #e5e7eb;
  background-color: #fcfcfd !important;
  border-color: transparent;
}
.text-bg-light-soft-outline {
  color: #e5e7eb;
  background-color: #fcfcfd !important;
  border-color: #f7f8f9;
}
.text-bg-dark-soft {
  color: #1f2937;
  background-color: #e4e5e7 !important;
  border-color: transparent;
}
.text-bg-dark-soft-outline {
  color: #1f2937;
  background-color: #e4e5e7 !important;
  border-color: #bcbfc3;
}
.text-bg-darker-soft {
  color: #111827;
  background-color: #e2e3e5 !important;
  border-color: transparent;
}
.text-bg-darker-soft-outline {
  color: #111827;
  background-color: #e2e3e5 !important;
  border-color: #b8babe;
}
.accordion-button {
  font-weight: 500;
}
.accordion-button:focus {
  border-width: 1px;
}
.accordion-body {
  color: #43476b;
}
.badge {
  vertical-align: middle;
}
.btn-md {
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-padding-x: 1.125rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.313rem;
}
.btn-xl {
  --bs-btn-padding-y: 0.875rem;
  --bs-btn-padding-x: 1.375rem;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-border-radius: 1rem;
}
.btn-soft.btn-primary {
  --bs-btn-color: #5f38f9;
  --bs-btn-bg: #e7e1fe;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-primary {
  --bs-btn-color: #5f38f9;
  --bs-btn-bg: #e7e1fe;
  --bs-btn-border-color: #bfaffd;
}
.btn-color-primary {
  --bs-btn-color: #5f38f9;
}
.btn-hover-primary {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5f38f9;
  --bs-btn-hover-border-color: #5f38f9;
}
.btn-soft.btn-hover-primary {
  --bs-btn-hover-color: #5f38f9;
  --bs-btn-hover-bg: #e7e1fe;
  --bs-btn-hover-border-color: #e7e1fe;
}
.btn-soft.btn-purple {
  --bs-btn-color: #8927f9;
  --bs-btn-bg: #eddffe;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-purple {
  --bs-btn-color: #8927f9;
  --bs-btn-bg: #eddffe;
  --bs-btn-border-color: #d0a9fd;
}
.btn-color-purple {
  --bs-btn-color: #8927f9;
}
.btn-hover-purple {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8927f9;
  --bs-btn-hover-border-color: #8927f9;
}
.btn-soft.btn-hover-purple {
  --bs-btn-hover-color: #8927f9;
  --bs-btn-hover-bg: #eddffe;
  --bs-btn-hover-border-color: #eddffe;
}
.btn-soft.btn-pink {
  --bs-btn-color: #f24a8b;
  --bs-btn-bg: #fde4ee;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-pink {
  --bs-btn-color: #f24a8b;
  --bs-btn-bg: #fde4ee;
  --bs-btn-border-color: #fab7d1;
}
.btn-color-pink {
  --bs-btn-color: #f24a8b;
}
.btn-hover-pink {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f24a8b;
  --bs-btn-hover-border-color: #f24a8b;
}
.btn-soft.btn-hover-pink {
  --bs-btn-hover-color: #f24a8b;
  --bs-btn-hover-bg: #fde4ee;
  --bs-btn-hover-border-color: #fde4ee;
}
.btn-soft.btn-secondary {
  --bs-btn-color: #374151;
  --bs-btn-bg: #e1e3e5;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-secondary {
  --bs-btn-color: #374151;
  --bs-btn-bg: #e1e3e5;
  --bs-btn-border-color: #afb3b9;
}
.btn-color-secondary {
  --bs-btn-color: #374151;
}
.btn-hover-secondary {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #374151;
  --bs-btn-hover-border-color: #374151;
}
.btn-soft.btn-hover-secondary {
  --bs-btn-hover-color: #374151;
  --bs-btn-hover-bg: #e1e3e5;
  --bs-btn-hover-border-color: #e1e3e5;
}
.btn-soft.btn-success {
  --bs-btn-color: #2dc58c;
  --bs-btn-bg: #e0f6ee;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-success {
  --bs-btn-color: #2dc58c;
  --bs-btn-bg: #e0f6ee;
  --bs-btn-border-color: #abe8d1;
}
.btn-color-success {
  --bs-btn-color: #2dc58c;
}
.btn-hover-success {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2dc58c;
  --bs-btn-hover-border-color: #2dc58c;
}
.btn-soft.btn-hover-success {
  --bs-btn-hover-color: #2dc58c;
  --bs-btn-hover-bg: #e0f6ee;
  --bs-btn-hover-border-color: #e0f6ee;
}
.btn-soft.btn-info {
  --bs-btn-color: #478ffc;
  --bs-btn-bg: #e3eeff;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-info {
  --bs-btn-color: #478ffc;
  --bs-btn-bg: #e3eeff;
  --bs-btn-border-color: #b5d2fe;
}
.btn-color-info {
  --bs-btn-color: #478ffc;
}
.btn-hover-info {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #478ffc;
  --bs-btn-hover-border-color: #478ffc;
}
.btn-soft.btn-hover-info {
  --bs-btn-hover-color: #478ffc;
  --bs-btn-hover-bg: #e3eeff;
  --bs-btn-hover-border-color: #e3eeff;
}
.btn-soft.btn-warning {
  --bs-btn-color: #f2bc16;
  --bs-btn-bg: #fdf5dc;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-warning {
  --bs-btn-color: #f2bc16;
  --bs-btn-bg: #fdf5dc;
  --bs-btn-border-color: #fae4a2;
}
.btn-color-warning {
  --bs-btn-color: #f2bc16;
}
.btn-hover-warning {
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2bc16;
  --bs-btn-hover-border-color: #f2bc16;
}
.btn-soft.btn-hover-warning {
  --bs-btn-hover-color: #f2bc16;
  --bs-btn-hover-bg: #fdf5dc;
  --bs-btn-hover-border-color: #fdf5dc;
}
.btn-soft.btn-danger {
  --bs-btn-color: #df3c4e;
  --bs-btn-bg: #fae2e4;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-danger {
  --bs-btn-color: #df3c4e;
  --bs-btn-bg: #fae2e4;
  --bs-btn-border-color: #f2b1b8;
}
.btn-color-danger {
  --bs-btn-color: #df3c4e;
}
.btn-hover-danger {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #df3c4e;
  --bs-btn-hover-border-color: #df3c4e;
}
.btn-soft.btn-hover-danger {
  --bs-btn-hover-color: #df3c4e;
  --bs-btn-hover-bg: #fae2e4;
  --bs-btn-hover-border-color: #fae2e4;
}
.btn-soft.btn-lighter {
  --bs-btn-color: #f1f2f7;
  --bs-btn-bg: #fdfdfe;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-lighter {
  --bs-btn-color: #f1f2f7;
  --bs-btn-bg: #fdfdfe;
  --bs-btn-border-color: #f9fafc;
}
.btn-color-lighter {
  --bs-btn-color: #f1f2f7;
}
.btn-hover-lighter {
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1f2f7;
  --bs-btn-hover-border-color: #f1f2f7;
}
.btn-soft.btn-hover-lighter {
  --bs-btn-hover-color: #f1f2f7;
  --bs-btn-hover-bg: #fdfdfe;
  --bs-btn-hover-border-color: #fdfdfe;
}
.btn-soft.btn-light {
  --bs-btn-color: #e5e7eb;
  --bs-btn-bg: #fbfbfc;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-light {
  --bs-btn-color: #e5e7eb;
  --bs-btn-bg: #fbfbfc;
  --bs-btn-border-color: #f5f5f7;
}
.btn-color-light {
  --bs-btn-color: #e5e7eb;
}
.btn-hover-light {
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e5e7eb;
  --bs-btn-hover-border-color: #e5e7eb;
}
.btn-soft.btn-hover-light {
  --bs-btn-hover-color: #e5e7eb;
  --bs-btn-hover-bg: #fbfbfc;
  --bs-btn-hover-border-color: #fbfbfc;
}
.btn-soft.btn-dark {
  --bs-btn-color: #1f2937;
  --bs-btn-bg: #dddfe1;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-dark {
  --bs-btn-color: #1f2937;
  --bs-btn-bg: #dddfe1;
  --bs-btn-border-color: #a5a9af;
}
.btn-color-dark {
  --bs-btn-color: #1f2937;
}
.btn-hover-dark {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1f2937;
  --bs-btn-hover-border-color: #1f2937;
}
.btn-soft.btn-hover-dark {
  --bs-btn-hover-color: #1f2937;
  --bs-btn-hover-bg: #dddfe1;
  --bs-btn-hover-border-color: #dddfe1;
}
.btn-soft.btn-darker {
  --bs-btn-color: #111827;
  --bs-btn-bg: #dbdcdf;
  --bs-btn-border-color: transparent;
}
.btn-soft.btn-outline-darker {
  --bs-btn-color: #111827;
  --bs-btn-bg: #dbdcdf;
  --bs-btn-border-color: #a0a3a9;
}
.btn-color-darker {
  --bs-btn-color: #111827;
}
.btn-hover-darker {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #111827;
  --bs-btn-hover-border-color: #111827;
}
.btn-soft.btn-hover-darker {
  --bs-btn-hover-color: #111827;
  --bs-btn-hover-bg: #dbdcdf;
  --bs-btn-hover-border-color: #dbdcdf;
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn img {
  height: 1rem;
}
.btn .icon {
  font-size: 1.3em;
  line-height: 1.5rem;
}
.btn .icon + span,
.btn span + .icon {
  margin-left: 0.625rem;
}
.btn-icon {
  --bs-btn-padding-x: 0;
  width: 2.75rem;
}
.btn-icon.btn-sm,
.btn-group-sm > .btn-icon.btn {
  width: 1.875rem;
}
.btn-icon.btn-md {
  width: 2.375rem;
}
.btn-icon.btn-lg,
.btn-group-lg > .btn-icon.btn {
  width: 3.5rem;
}
.btn-icon.btn-xl {
  width: 3.375rem;
}
.btn-light,
.btn-outline-light {
  color: #2e314a;
}
.btn-no-hover.active,
.btn-no-hover:active,
.btn-no-hover:focus,
.btn-no-hover:hover {
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn-zoom {
  position: relative;
  z-index: 1;
  color: #787c9e;
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn-zoom:focus {
  box-shadow: none;
}
.btn-zoom:before {
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  content: "";
  background-color: #f1f2f7;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s ease;
}
.show > .btn-zoom:before {
  opacity: 1;
  height: 120%;
  width: 120%;
}
.btn-zoom:hover:before,
.btn-zoom:focus:before,
.btn-zoom.active:not(.revarse):before {
  opacity: 1;
  height: 120%;
  width: 120%;
}
.btn-zoom.active:hover:before {
  background-color: #e5e7eb;
}
a:hover .btn-zoom:before {
  opacity: 1;
  height: 120%;
  width: 120%;
}
.breadcrumb-item {
  font-weight: 500;
}
.breadcrumb-item a:not(:hover) {
  color: #43476b;
}
.breadcrumb-arrow .breadcrumb-item + .breadcrumb-item::before {
  content: "";
  font-family: "Nioicon";
}
.btn-close-white {
  opacity: 0.7;
}
.btn-close-white:hover {
  opacity: 0.9;
}
.dropdown-menu {
  box-shadow: 0 2px 12px -1px rgba(67, 71, 107, 0.2);
}
.dropdown-divider {
  opacity: 0.5;
}
.dropdown-content {
  padding-left: var(--bs-dropdown-item-padding-x);
  padding-right: var(--bs-dropdown-item-padding-x);
}
.dropdown-content-x-lg {
  --bs-dropdown-item-padding-x: 1.5rem;
}
.dropdown-option .dropdown-item {
  display: flex;
  align-items: center;
}
.dropdown-option .dropdown-item .icon {
  font-size: 1rem;
  width: 1.625rem;
  opacity: 0.8;
}
.dropdown-toggle.no-caret:after {
  display: none;
}
.dropdown-menu-xxs {
  min-width: 70px;
}
.dropdown-menu-xs {
  min-width: 120px;
}
.dropdown-menu-sm {
  min-width: 160px;
}
.dropdown-menu-md {
  min-width: 280px;
}
.dropdown-menu-lg {
  min-width: 320px;
}
.dropdown-menu-xl {
  min-width: 360px;
}
.nav-link {
  line-height: 1.5rem;
}
.nav-sm .nav-link {
  padding: 0.5rem 0.625rem;
}
.nav-tabs-s1 .nav-item {
  padding-right: 2rem;
}
.nav-tabs-s1 .nav-item:last-child {
  padding-right: 0;
}
.nav-tabs-s1 .nav-link {
  padding-left: 0;
  padding-right: 0;
  border: rgba(0, 0, 0, 0);
  margin-bottom: 0;
  position: relative;
}
.nav-tabs-s1 .nav-link::after {
  left: 0;
  right: 0;
  bottom: -1px;
  height: 2px;
  position: absolute;
  content: "";
  background-color: #5f38f9;
  width: 100%;
  opacity: 0;
  transform: scale(0.6);
  transition: 0.2s;
}
.nav-tabs-s1 .nav-link.active {
  color: #5f38f9;
}
.nav-tabs-s1 .nav-link.active::after {
  opacity: 1;
  transform: scale(1);
}
.nav-tabs .nav-link {
  line-height: 1.5rem;
}
.nav-pills-border .nav-link {
  border: 1px dashed #d2d4e4;
  font-weight: 500;
}
.nav-pills-border .nav-link:hover,
.nav-pills-border .nav-link.active {
  color: #5f38f9;
  background-color: #f7f5ff;
  border-color: #5f38f9;
}
.nav-pills-soft .nav-link {
  font-weight: 500;
}
.nav-pills-soft .nav-link.active {
  background-color: #e7e1fe;
  color: #5f38f9;
}
.nav-pills .nav-link {
  border: 1px solid rgba(0, 0, 0, 0);
  line-height: 1.5rem;
}
.page-link {
  min-width: 2.625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-weight: 500;
}
.pagination-sm .page-link {
  min-width: 1.688rem;
}
.pagination-s1 {
  align-items: center;
}
.pagination-s1 .page-item {
  margin-right: 0.5rem;
}
.pagination-s1 .page-item:last-child {
  margin-right: 0;
}
.pagination-s1 .page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.pagination-s1 .page-item:first-child .page-link:hover,
.pagination-s1 .page-item:last-child .page-link:hover {
  background-color: rgba(0, 0, 0, 0);
}
.pagination-s1 .page-item.active .page-link {
  color: #5f38f9;
  background-color: #dfd7fe;
}
.pagination-s1 .page-link {
  border-radius: 0.375rem;
  border: 0;
  display: flex;
}
.pagination-s1 .page-link:hover {
  color: #5f38f9;
  background-color: #f2efff;
}
.pagination-s1 .page-link .icon {
  font-size: 1.125rem;
}
.list-group-item.active h6,
.list-group-item.active .h6,
.list-group-item.active h5,
.list-group-item.active .h5,
.list-group-item.active h4,
.list-group-item.active .h4,
.list-group-item.active h3,
.list-group-item.active .h3,
.list-group-item.active h2,
.list-group-item.active .h2,
.list-group-item.active h1,
.list-group-item.active .h1 {
  color: currentColor;
}
.list-group-item .title {
  color: #2e314a;
}
.list-group-item .text {
  color: #787c9e;
}
.list-group-heading {
  border-bottom: 1px solid #e8e7ec;
  padding-bottom: 0.5rem;
  margin-bottom: 0.25rem;
}
.list-group-heading .title {
  margin-bottom: 0;
}
.list-group-borderless {
  border-radius: 0;
}
.list-group-borderless .list-group-item {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
.list-group-sm .list-group-item {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-top {
  align-items: flex-start;
}
.modal-dialog-bottom {
  align-items: flex-end;
}
.card {
  box-shadow: 0 1px 0px rgba(232, 231, 236, 0.5);
}
.card-gutter-md {
  --bs-card-spacer-y: 1.625rem;
  --bs-card-spacer-x: 1.625rem;
}
.card-gutter-lg {
  --bs-card-spacer-y: 2.5rem;
  --bs-card-spacer-x: 2.5rem;
}
.card-title .icon + * {
  margin-left: 0.5rem;
}
.card-title-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-title-group .card-title {
  --bs-card-title-spacer-y: 0;
}
.card-row {
  display: flex;
  flex-direction: column;
}
.card .card {
  --bs-card-border-radius: 0.375rem;
}
.card-auth-mh {
  display: flex;
  flex-direction: column;
  min-height: 684px;
}
.card-auth-mh > * {
  flex-grow: 1;
  height: 100%;
}
@media (min-width: 992px) {
  .card-row-lg {
    flex-direction: row;
  }
  .card-row-lg .card-aside {
    width: 370px;
    flex-shrink: 0;
  }
}
@media (min-width: 1200px) {
  .card-row-xl {
    flex-direction: row;
  }
  .card-row-xl .card-aside {
    width: 370px;
    flex-shrink: 0;
  }
}
@media (min-width: 1400px) {
  .card-row-xxl {
    flex-direction: row;
  }
  .card-row-xxl .card-aside {
    width: 370px;
    flex-shrink: 0;
  }
}
.col-sep > * + * {
  border-top: 1px solid #e8e7ec;
}
.col-sep-vr > * + * {
  border-left: 1px solid #e8e7ec;
}
@media (min-width: 576px) {
  .col-sep-sm > * + * {
    border-top: none;
    border-left: 1px solid #e8e7ec;
  }
  .col-sep-sm-vr > * + * {
    border-left: none;
    border-top: 1px solid #e8e7ec;
  }
}
@media (min-width: 768px) {
  .col-sep-md > * + * {
    border-top: none;
    border-left: 1px solid #e8e7ec;
  }
  .col-sep-md-vr > * + * {
    border-left: none;
    border-top: 1px solid #e8e7ec;
  }
}
@media (min-width: 992px) {
  .col-sep-lg > * + * {
    border-top: none;
    border-left: 1px solid #e8e7ec;
  }
  .col-sep-lg-vr > * + * {
    border-left: none;
    border-top: 1px solid #e8e7ec;
  }
}
@media (min-width: 1200px) {
  .col-sep-xl > * + * {
    border-top: none;
    border-left: 1px solid #e8e7ec;
  }
  .col-sep-xl-vr > * + * {
    border-left: none;
    border-top: 1px solid #e8e7ec;
  }
}
@media (min-width: 1400px) {
  .col-sep-xxl > * + * {
    border-top: none;
    border-left: 1px solid #e8e7ec;
  }
  .col-sep-xxl-vr > * + * {
    border-left: none;
    border-top: 1px solid #e8e7ec;
  }
}
.progress-xs {
  height: 4px;
}
.progress-sm {
  height: 6px;
}
.progress-md {
  height: 8px;
}
.progress-rg {
  height: 10px;
}
.progress-lg {
  height: 12px;
}
.progress-xl {
  height: 16px;
}
.offcanvas .offcanvas-header {
  --bs-offcanvas-padding-y: 1.25rem;
}
.offcanvas-size-sm {
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
}
.offcanvas-size-sm.offcanvas-start,
.offcanvas-size-sm.offcanvas-end {
  --bs-offcanvas-width: 300px;
}
.offcanvas-size-sm .offcanvas-header {
  --bs-offcanvas-padding-y: 1rem;
}
.offcanvas-size-lg {
  --bs-offcanvas-padding-x: 2rem;
  --bs-offcanvas-padding-y: 2rem;
}
.offcanvas-size-lg.offcanvas-start,
.offcanvas-size-lg.offcanvas-end {
  --bs-offcanvas-width: 500px;
}
.offcanvas-size-lg .offcanvas-header {
  --bs-offcanvas-padding-y: 1.375rem;
}
.card .table > :not(caption) > * > *:first-child {
  padding-left: 1.25rem;
}
.card .table > :not(caption) > * > *:last-child {
  padding-right: 1.25rem;
}
.toast-container {
  z-index: 1;
}
.form-control:not([type="file"]):read-only {
  background-color: #f2f3f8;
}
.form-control-wrap {
  position: relative;
}
.form-control-hint {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #787c9e;
  padding: 0 1.125rem;
  font-size: 0.813rem;
}
.form-control-icon {
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 0;
  padding: 0 0.84375rem;
  height: 2.75rem;
}
.form-control-icon.start + .form-control {
  padding-left: 2.4625rem;
}
.form-control-icon.end {
  left: auto;
  right: 0;
}
.form-control-icon.end + .form-control {
  padding-right: 2.4625rem;
}
.form-control-icon.sm {
  padding: 0 0.5625rem;
  height: 1.875rem;
}
.form-control-icon.sm.start + .form-control {
  padding-left: 1.975rem;
}
.form-control-icon.sm.end + .form-control {
  padding-right: 1.975rem;
}
.form-control-icon.md {
  padding: 0 0.84375rem;
  height: 2.375rem;
}
.form-control-icon.md.start + .form-control {
  padding-left: 2.4625rem;
}
.form-control-icon.md.end + .form-control {
  padding-right: 2.4625rem;
}
.form-control-icon.lg {
  padding: 0 1.03125rem;
  height: 3.5rem;
}
.form-control-icon.lg.start + .form-control {
  padding-left: 2.7875rem;
}
.form-control-icon.lg.end + .form-control {
  padding-right: 2.7875rem;
}
.form-control-plaintext:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
}
.form-control-plaintext.form-control-sm {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.form-control-plaintext.form-control-md {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.form-control-plaintext.form-control-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.form-select {
  padding-right: 2.75rem;
  background-position: right 0.875rem center;
}
.form-select-sm {
  background-position: right 0.4375rem center;
  padding-right: 1.875rem;
}
.form-select-lg {
  background-position: right 1.25rem center;
  padding-right: 3.5rem;
}
.form-note {
  font-size: 0.813rem;
  color: #787c9e;
}
.form-inline {
  display: inline-flex;
  flex-wrap: wrap;
}
.form-error {
  font-size: 0.875rem;
  color: #df3c4e;
  padding-top: 0.25rem;
}
.floating-feedbacks .form-error {
  position: absolute;
  color: #fff;
  background: #df3c4e;
  padding: 0 0.375rem;
  border-radius: 2px;
  font-size: 0.75rem;
  right: 0;
  bottom: 100%;
  margin-bottom: 0.4rem;
}
.floating-feedbacks .form-error:after {
  position: absolute;
  content: "";
  right: 0.5rem;
  bottom: -6px;
  height: 0;
  width: 0;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #df3c4e;
}
.floating-feedbacks .form-check-wrap .form-error {
  margin-bottom: 0.325rem;
  left: 0;
  right: auto;
}
.floating-feedbacks .form-check-wrap .form-error:after {
  left: 0.375rem;
}
textarea.form-control {
  min-height: 5.625rem;
}
textarea.form-control-sm {
  min-height: 4rem;
}
textarea.form-control-lg {
  min-height: 7rem;
}
.password-toggle {
  color: #787c9e;
}
.password-toggle .active {
  display: block;
}
.password-toggle .inactive {
  display: none;
}
.password-toggle.is-shown .active {
  display: none;
}
.password-toggle.is-shown .inactive {
  display: block;
}
.form-check {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.form-check-inline {
  display: inline-flex;
}
.form-check-label {
  line-height: 1.75rem;
  padding-left: 0.75rem;
  font-size: 1rem;
}
.form-check-input {
  float: none !important;
  margin-top: 0.25rem;
}
.form-check-input:only-child {
  margin-top: 0.125rem;
  align-self: center;
}
.form-check-reverse {
  flex-direction: row-reverse;
}
.form-check-reverse .form-check-label {
  padding-left: 0;
  padding-right: 0.75rem;
}
.form-check-sm {
  min-height: 1.375rem;
}
.form-check-sm .form-check-label {
  line-height: 1.375rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
}
.form-check-sm .form-check-input {
  margin-top: 0.1875rem;
  height: 1rem;
  width: 1rem;
}
.form-check-sm.form-switch .form-check-input {
  width: 2rem;
}
.form-check-lg {
  min-height: 2rem;
}
.form-check-lg .form-check-label {
  line-height: 2rem;
  padding-left: 0.875rem;
  font-size: 1.125rem;
}
.form-check-lg .form-check-input {
  margin-top: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}
.form-check-lg.form-switch .form-check-input {
  width: 3rem;
}
.form-check-xl {
  min-height: 2.25rem;
}
.form-check-xl .form-check-label {
  line-height: 2.25rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.form-check-xl .form-check-input {
  margin-top: 0.125rem;
  height: 2rem;
  width: 2rem;
}
.form-check-xl.form-switch .form-check-input {
  width: 3.75rem;
}
.input-group .datepicker-input:last-of-type {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}
.form-floating .form-control-plaintext {
  border-width: 0.0625rem;
  border-radius: 0.375rem;
}
.form-floating .form-control-plaintext:focus {
  border-color: rgba(95, 56, 249, 0.65);
}
.form-floating .form-control-plaintext:focus-visible {
  outline: none;
}
.form-label {
  color: #2e314a;
}

.ni {
  font-family: "Nioicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ni-nioboard:before {
  content: "";
}
.ni-graphql:before {
  content: "";
}
.ni-node-js:before {
  content: "";
}
.ni-node:before {
  content: "";
}
.ni-svelte:before {
  content: "";
}
.ni-typescript:before {
  content: "";
}
.ni-vue:before {
  content: "";
}
.ni-centos:before {
  content: "";
}
.ni-covid:before {
  content: "";
}
.ni-fedora:before {
  content: "";
}
.ni-hot-fill:before {
  content: "";
}
.ni-hot:before {
  content: "";
}
.ni-linux-server:before {
  content: "";
}
.ni-linux:before {
  content: "";
}
.ni-note-add-fill:before {
  content: "";
}
.ni-repeat-fill:before {
  content: "";
}
.ni-tranx-fill:before {
  content: "";
}
.ni-ubuntu:before {
  content: "";
}
.ni-virus:before {
  content: "";
}
.ni-b-chrome:before {
  content: "";
}
.ni-b-edge:before {
  content: "";
}
.ni-b-firefox:before {
  content: "";
}
.ni-b-ie:before {
  content: "";
}
.ni-b-opera:before {
  content: "";
}
.ni-b-safari:before {
  content: "";
}
.ni-b-si:before {
  content: "";
}
.ni-b-uc:before {
  content: "";
}
.ni-brick-fill:before {
  content: "";
}
.ni-brick:before {
  content: "";
}
.ni-col-3s:before {
  content: "";
}
.ni-col-4s:before {
  content: "";
}
.ni-col-2s:before {
  content: "";
}
.ni-comments:before {
  content: "";
}
.ni-dot-sq:before {
  content: "";
}
.ni-dot:before {
  content: "";
}
.ni-footer:before {
  content: "";
}
.ni-header:before {
  content: "";
}
.ni-heading:before {
  content: "";
}
.ni-layout-alt-fill:before {
  content: "";
}
.ni-layout-alt:before {
  content: "";
}
.ni-layout-fill1:before {
  content: "";
}
.ni-layout1:before {
  content: "";
}
.ni-list-index-fill:before {
  content: "";
}
.ni-list-index:before {
  content: "";
}
.ni-list-thumb-alt-fill:before {
  content: "";
}
.ni-list-thumb-alt:before {
  content: "";
}
.ni-list-thumb-fill:before {
  content: "";
}
.ni-list-thumb:before {
  content: "";
}
.ni-masonry-fill:before {
  content: "";
}
.ni-masonry:before {
  content: "";
}
.ni-menu-circled:before {
  content: "";
}
.ni-menu-squared:before {
  content: "";
}
.ni-notice:before {
  content: "";
}
.ni-pen2:before {
  content: "";
}
.ni-property-blank:before {
  content: "";
}
.ni-property-add:before {
  content: "";
}
.ni-property-alt:before {
  content: "";
}
.ni-property-remove:before {
  content: "";
}
.ni-property:before {
  content: "";
}
.ni-puzzle-fill:before {
  content: "";
}
.ni-puzzle:before {
  content: "";
}
.ni-quote-left:before {
  content: "";
}
.ni-quote-right:before {
  content: "";
}
.ni-row-mix:before {
  content: "";
}
.ni-row-view1:before {
  content: "";
}
.ni-sidebar-r:before {
  content: "";
}
.ni-text2:before {
  content: "";
}
.ni-tile-thumb-fill:before {
  content: "";
}
.ni-tile-thumb:before {
  content: "";
}
.ni-view-col-fill:before {
  content: "";
}
.ni-view-col-sq:before {
  content: "";
}
.ni-view-col:before {
  content: "";
}
.ni-view-col2:before {
  content: "";
}
.ni-view-col3:before {
  content: "";
}
.ni-view-cols-fill:before {
  content: "";
}
.ni-view-cols-sq:before {
  content: "";
}
.ni-view-cols:before {
  content: "";
}
.ni-view-grid-fill:before {
  content: "";
}
.ni-view-grid-sq:before {
  content: "";
}
.ni-view-grid-wd:before {
  content: "";
}
.ni-view-grid:before {
  content: "";
}
.ni-view-grid2-wd:before {
  content: "";
}
.ni-view-grid3-wd:before {
  content: "";
}
.ni-view-group-fill:before {
  content: "";
}
.ni-view-group-wd:before {
  content: "";
}
.ni-view-list-fill:before {
  content: "";
}
.ni-view-list-sq:before {
  content: "";
}
.ni-view-list-wd:before {
  content: "";
}
.ni-view-list:before {
  content: "";
}
.ni-view-panel-fill:before {
  content: "";
}
.ni-view-panel-sq:before {
  content: "";
}
.ni-view-panel:before {
  content: "";
}
.ni-view-row-fill:before {
  content: "";
}
.ni-view-row-sq:before {
  content: "";
}
.ni-view-row-wd:before {
  content: "";
}
.ni-view-row:before {
  content: "";
}
.ni-view-x1:before {
  content: "";
}
.ni-view-x2:before {
  content: "";
}
.ni-view-x3:before {
  content: "";
}
.ni-view-x4:before {
  content: "";
}
.ni-view-x5:before {
  content: "";
}
.ni-view-x6:before {
  content: "";
}
.ni-view-x7:before {
  content: "";
}
.ni-dashlite:before {
  content: "";
}
.ni-dashlite-circle:before {
  content: "";
}
.ni-dashlite-alt:before {
  content: "";
}
.ni-master-card:before {
  content: "";
}
.ni-paypal:before {
  content: "";
}
.ni-visa-alt:before {
  content: "";
}
.ni-coin-eur:before {
  content: "";
}
.ni-coin-gbp:before {
  content: "";
}
.ni-sign-ada-alt:before {
  content: "";
}
.ni-sign-bch-alt:before {
  content: "";
}
.ni-sign-bgp-alt:before {
  content: "";
}
.ni-sign-bnb-alt:before {
  content: "";
}
.ni-sign-brl-alt:before {
  content: "";
}
.ni-sign-btc-alt:before {
  content: "";
}
.ni-sign-cc-alt:before {
  content: "";
}
.ni-sign-cc-alt2:before {
  content: "";
}
.ni-sign-chf-alt:before {
  content: "";
}
.ni-sign-cny-alt:before {
  content: "";
}
.ni-sign-czk-alt:before {
  content: "";
}
.ni-sign-dash-alt:before {
  content: "";
}
.ni-sign-dkk-alt:before {
  content: "";
}
.ni-sign-eos-alt:before {
  content: "";
}
.ni-sign-eth-alt:before {
  content: "";
}
.ni-sign-eur-alt2:before {
  content: "";
}
.ni-sign-euro-alt:before {
  content: "";
}
.ni-sign-gbp-alt2:before {
  content: "";
}
.ni-sign-hkd-alt:before {
  content: "";
}
.ni-sign-idr-alt:before {
  content: "";
}
.ni-sign-inr-alt:before {
  content: "";
}
.ni-sign-jpy-alt:before {
  content: "";
}
.ni-sign-kr-alt:before {
  content: "";
}
.ni-sign-ltc-alt:before {
  content: "";
}
.ni-sign-ltc:before {
  content: "";
}
.ni-sign-mxn-alt:before {
  content: "";
}
.ni-sign-mxr-alt:before {
  content: "";
}
.ni-sign-myr-alt:before {
  content: "";
}
.ni-sign-paypal-alt:before {
  content: "";
}
.ni-sign-paypal-full:before {
  content: "";
}
.ni-sign-php-alt:before {
  content: "";
}
.ni-sign-pln-alt:before {
  content: "";
}
.ni-sign-rub-alt:before {
  content: "";
}
.ni-sign-sek-alt:before {
  content: "";
}
.ni-sign-sgd-alt:before {
  content: "";
}
.ni-sign-kobo-alt:before {
  content: "";
}
.ni-sign-steem-alt:before {
  content: "";
}
.ni-sign-steller-alt:before {
  content: "";
}
.ni-sign-stripe-fulll:before {
  content: "";
}
.ni-sign-thb-alt:before {
  content: "";
}
.ni-sign-trx-alt:before {
  content: "";
}
.ni-sign-try-alt:before {
  content: "";
}
.ni-sign-usd-alt:before {
  content: "";
}
.ni-sign-usd-alt2:before {
  content: "";
}
.ni-sign-usdc-alt:before {
  content: "";
}
.ni-sign-usdt-alt:before {
  content: "";
}
.ni-sign-visa-alt:before {
  content: "";
}
.ni-sign-vnd-alt:before {
  content: "";
}
.ni-sign-waves-alt:before {
  content: "";
}
.ni-sign-xem-alt:before {
  content: "";
}
.ni-sign-xrp-new-alt:before {
  content: "";
}
.ni-sign-xrp-old-alt:before {
  content: "";
}
.ni-sign-zcash-alt:before {
  content: "";
}
.ni-chevron-left:before {
  content: "";
}
.ni-chevron-right:before {
  content: "";
}
.ni-chevron-up:before {
  content: "";
}
.ni-chevron-down:before {
  content: "";
}
.ni-chevron-left-round:before {
  content: "";
}
.ni-chevron-right-round:before {
  content: "";
}
.ni-chevron-up-round:before {
  content: "";
}
.ni-chevron-down-round:before {
  content: "";
}
.ni-chevron-left-round-fill:before {
  content: "";
}
.ni-chevron-right-round-fill:before {
  content: "";
}
.ni-chevron-up-round-fill:before {
  content: "";
}
.ni-chevron-down-round-fill:before {
  content: "";
}
.ni-chevron-left-c:before {
  content: "";
}
.ni-chevron-right-c:before {
  content: "";
}
.ni-chevron-up-c:before {
  content: "";
}
.ni-chevron-down-c:before {
  content: "";
}
.ni-chevron-left-fill-c:before {
  content: "";
}
.ni-chevron-right-fill-c:before {
  content: "";
}
.ni-chevron-up-fill-c:before {
  content: "";
}
.ni-chevron-down-fill-c:before {
  content: "";
}
.ni-chevron-left-circle:before {
  content: "";
}
.ni-chevron-right-circle:before {
  content: "";
}
.ni-chevron-up-circle:before {
  content: "";
}
.ni-chevron-down-circle:before {
  content: "";
}
.ni-chevron-left-circle-fill:before {
  content: "";
}
.ni-chevron-right-circle-fill:before {
  content: "";
}
.ni-chevron-up-circle-fill:before {
  content: "";
}
.ni-chevron-down-circle-fill:before {
  content: "";
}
.ni-caret-left:before {
  content: "";
}
.ni-caret-right:before {
  content: "";
}
.ni-caret-up:before {
  content: "";
}
.ni-caret-down:before {
  content: "";
}
.ni-caret-left-fill:before {
  content: "";
}
.ni-caret-right-fill:before {
  content: "";
}
.ni-caret-up-fill:before {
  content: "";
}
.ni-caret-down-fill:before {
  content: "";
}
.ni-sort:before {
  content: "";
}
.ni-sort-up:before {
  content: "";
}
.ni-sort-down:before {
  content: "";
}
.ni-sort-fill:before {
  content: "";
}
.ni-sort-up-fill:before {
  content: "";
}
.ni-sort-down-fill:before {
  content: "";
}
.ni-sort-v:before {
  content: "";
}
.ni-swap-v:before {
  content: "";
}
.ni-swap:before {
  content: "";
}
.ni-arrow-left-round:before {
  content: "";
}
.ni-arrow-right-round:before {
  content: "";
}
.ni-arrow-up-round:before {
  content: "";
}
.ni-arrow-down-round:before {
  content: "";
}
.ni-arrow-left-round-fill:before {
  content: "";
}
.ni-arrow-right-round-fill:before {
  content: "";
}
.ni-arrow-up-round-fill:before {
  content: "";
}
.ni-arrow-down-round-fill:before {
  content: "";
}
.ni-arrow-left-c:before {
  content: "";
}
.ni-arrow-right-c:before {
  content: "";
}
.ni-arrow-up-c:before {
  content: "";
}
.ni-arrow-down-c:before {
  content: "";
}
.ni-arrow-left-fill-c:before {
  content: "";
}
.ni-arrow-right-fill-c:before {
  content: "";
}
.ni-arrow-up-fill-c:before {
  content: "";
}
.ni-arrow-down-fill-c:before {
  content: "";
}
.ni-arrow-left-circle:before {
  content: "";
}
.ni-arrow-right-circle:before {
  content: "";
}
.ni-arrow-up-circle:before {
  content: "";
}
.ni-arrow-down-circle:before {
  content: "";
}
.ni-arrow-left-circle-fill:before {
  content: "";
}
.ni-arrow-up-circle-fill:before {
  content: "";
}
.ni-arrow-down-circle-fill:before {
  content: "";
}
.ni-arrow-right-circle-fill:before {
  content: "";
}
.ni-chevrons-left:before {
  content: "";
}
.ni-chevrons-right:before {
  content: "";
}
.ni-chevrons-up:before {
  content: "";
}
.ni-chevrons-down:before {
  content: "";
}
.ni-first:before {
  content: "";
}
.ni-last:before {
  content: "";
}
.ni-back-ios:before {
  content: "";
}
.ni-forward-ios:before {
  content: "";
}
.ni-upword-ios:before {
  content: "";
}
.ni-downward-ios:before {
  content: "";
}
.ni-back-alt:before {
  content: "";
}
.ni-forward-alt:before {
  content: "";
}
.ni-upword-alt:before {
  content: "";
}
.ni-downward-alt:before {
  content: "";
}
.ni-back-alt-fill:before {
  content: "";
}
.ni-forward-alt-fill:before {
  content: "";
}
.ni-upword-alt-fill:before {
  content: "";
}
.ni-downward-alt-fill:before {
  content: "";
}
.ni-arrow-long-left:before {
  content: "";
}
.ni-arrow-long-right:before {
  content: "";
}
.ni-arrow-long-up:before {
  content: "";
}
.ni-arrow-long-down:before {
  content: "";
}
.ni-arrow-left:before {
  content: ">>" !important;
  font-family: monospace;
}
.ni-arrow-right:before {
  content: "";
}
.ni-arrow-up:before {
  content: "";
}
.ni-arrow-down:before {
  content: "";
}
.ni-arrow-up-left:before {
  content: "";
}
.ni-arrow-up-right:before {
  content: "";
}
.ni-arrow-down-left:before {
  content: "";
}
.ni-arrow-down-right:before {
  content: "";
}
.ni-arrow-to-left:before {
  content: "";
}
.ni-arrow-to-right:before {
  content: "";
}
.ni-arrow-to-up:before {
  content: "";
}
.ni-arrow-to-down:before {
  content: "";
}
.ni-arrow-from-left:before {
  content: "";
}
.ni-arrow-from-right:before {
  content: "";
}
.ni-arrow-from-up:before {
  content: "";
}
.ni-arrow-from-down:before {
  content: "";
}
.ni-curve-down-left:before {
  content: "";
}
.ni-curve-up-right:before {
  content: "";
}
.ni-curve-up-left:before {
  content: "";
}
.ni-curve-down-right:before {
  content: "";
}
.ni-curve-left-up:before {
  content: "";
}
.ni-curve-right-up:before {
  content: "";
}
.ni-curve-left-down:before {
  content: "";
}
.ni-curve-right-down:before {
  content: "";
}
.ni-back-arrow:before {
  content: "";
}
.ni-forward-arrow:before {
  content: "";
}
.ni-back-arrow-fill:before {
  content: "";
}
.ni-forward-arrow-fill:before {
  content: "";
}
.ni-navigate:before {
  content: "";
}
.ni-navigate-up:before {
  content: "";
}
.ni-navigate-fill:before {
  content: "";
}
.ni-navigate-up-fill:before {
  content: "";
}
.ni-send:before {
  content: "";
}
.ni-send-alt:before {
  content: "";
}
.ni-unfold-less:before {
  content: "";
}
.ni-unfold-more:before {
  content: "";
}
.ni-exchange-v:before {
  content: "";
}
.ni-exchange:before {
  content: "";
}
.ni-expand:before {
  content: "";
}
.ni-shrink:before {
  content: "";
}
.ni-focus:before {
  content: "";
}
.ni-maximize:before {
  content: "";
}
.ni-minimize:before {
  content: "";
}
.ni-maximize-alt:before {
  content: "";
}
.ni-minimize-alt:before {
  content: "";
}
.ni-shuffle:before {
  content: "";
}
.ni-cross-sm:before {
  content: "";
}
.ni-cross:before {
  content: "";
}
.ni-cross-round:before {
  content: "";
}
.ni-cross-circle:before {
  content: "";
}
.ni-cross-c:before {
  content: "";
}
.ni-cross-round-fill:before {
  content: "";
}
.ni-cross-circle-fill:before {
  content: "";
}
.ni-cross-fill-c:before {
  content: "";
}
.ni-na:before {
  content: "";
}
.ni-check:before {
  content: "";
}
.ni-check-thick:before {
  content: "";
}
.ni-done:before {
  content: "";
}
.ni-check-round:before {
  content: "";
}
.ni-check-circle:before {
  content: "";
}
.ni-check-c:before {
  content: "";
}
.ni-check-round-fill:before {
  content: "";
}
.ni-check-circle-fill:before {
  content: "";
}
.ni-check-fill-c:before {
  content: "";
}
.ni-check-circle-cut:before {
  content: "";
}
.ni-check-round-cut:before {
  content: "";
}
.ni-bullet:before {
  content: "";
}
.ni-circle:before {
  content: "";
}
.ni-square:before {
  content: "";
}
.ni-square-c:before {
  content: "";
}
.ni-bullet-fill:before {
  content: "";
}
.ni-circle-fill:before {
  content: "";
}
.ni-square-fill:before {
  content: "";
}
.ni-square-fill-c:before {
  content: "";
}
.ni-plus-sm:before {
  content: "";
}
.ni-minus-sm:before {
  content: "";
}
.ni-plus:before {
  content: "";
}
.ni-minus:before {
  content: "";
}
.ni-plus-round:before {
  content: "";
}
.ni-minus-round:before {
  content: "";
}
.ni-plus-circle:before {
  content: "";
}
.ni-minus-circle:before {
  content: "";
}
.ni-plus-c:before {
  content: "";
}
.ni-minus-c:before {
  content: "";
}
.ni-plus-round-fill:before {
  content: "";
}
.ni-plus-circle-fill:before {
  content: "";
}
.ni-minus-round-fill:before {
  content: "";
}
.ni-minus-circle-fill:before {
  content: "";
}
.ni-plus-fill-c:before {
  content: "";
}
.ni-minus-fill-c:before {
  content: "";
}
.ni-plus-medi:before {
  content: "";
}
.ni-plus-medi-fill:before {
  content: "";
}
.ni-equal-sm:before {
  content: "";
}
.ni-equal:before {
  content: "";
}
.ni-calc:before {
  content: "";
}
.ni-search:before {
  content: "";
}
.ni-zoom-out:before {
  content: "";
}
.ni-zoom-in:before {
  content: "";
}
.ni-play:before {
  content: "";
}
.ni-play-fill:before {
  content: "";
}
.ni-play-circle:before {
  content: "";
}
.ni-play-circle-fill:before {
  content: "";
}
.ni-pause:before {
  content: "";
}
.ni-pause-fill:before {
  content: "";
}
.ni-pause-circle:before {
  content: "";
}
.ni-pause-circle-fill:before {
  content: "";
}
.ni-stop:before {
  content: "";
}
.ni-stop-fill:before {
  content: "";
}
.ni-stop-circle:before {
  content: "";
}
.ni-stop-circle-fill:before {
  content: "";
}
.ni-rewind:before {
  content: "";
}
.ni-forward:before {
  content: "";
}
.ni-rewind-fill:before {
  content: "";
}
.ni-forward-fill:before {
  content: "";
}
.ni-step-back:before {
  content: "";
}
.ni-step-forward:before {
  content: "";
}
.ni-vol-off:before {
  content: "";
}
.ni-vol-no:before {
  content: "";
}
.ni-vol-half:before {
  content: "";
}
.ni-vol:before {
  content: "";
}
.ni-mic:before {
  content: "";
}
.ni-mic-off:before {
  content: "";
}
.ni-video:before {
  content: "";
}
.ni-video-off:before {
  content: "";
}
.ni-video-fill:before {
  content: "";
}
.ni-loader:before {
  content: "";
}
.ni-power:before {
  content: "";
}
.ni-signout:before {
  content: "";
}
.ni-signin:before {
  content: "";
}
.ni-upload:before {
  content: "";
}
.ni-download:before {
  content: "";
}
.ni-alert-circle:before {
  content: "";
}
.ni-alert:before {
  content: "";
}
.ni-caution:before {
  content: "";
}
.ni-report:before {
  content: "";
}
.ni-alert-c:before {
  content: "";
}
.ni-alert-circle-fill:before {
  content: "";
}
.ni-alert-fill:before {
  content: "";
}
.ni-caution-fill:before {
  content: "";
}
.ni-report-fill:before {
  content: "";
}
.ni-alert-fill-c:before {
  content: "";
}
.ni-info-i:before {
  content: "";
}
.ni-info:before {
  content: "";
}
.ni-info-fill:before {
  content: "";
}
.ni-help:before {
  content: "";
}
.ni-help-fill:before {
  content: "";
}
.ni-archived:before {
  content: "";
}
.ni-archive:before {
  content: "";
}
.ni-unarchive:before {
  content: "";
}
.ni-archived-fill:before {
  content: "";
}
.ni-archive-fill:before {
  content: "";
}
.ni-unarchive-fill:before {
  content: "";
}
.ni-bag:before {
  content: "";
}
.ni-bag-fill:before {
  content: "";
}
.ni-bell:before {
  content: "";
}
.ni-bell-off:before {
  content: "";
}
.ni-bell-fill:before {
  content: "";
}
.ni-bell-off-fill:before {
  content: "";
}
.ni-wifi:before {
  content: "";
}
.ni-wifi-off:before {
  content: "";
}
.ni-live:before {
  content: "";
}
.ni-signal:before {
  content: "";
}
.ni-bluetooth:before {
  content: "";
}
.ni-blank-alt:before {
  content: "";
}
.ni-blank:before {
  content: "";
}
.ni-blankf-fill:before {
  content: "";
}
.ni-block-over:before {
  content: "";
}
.ni-book-read:before {
  content: "";
}
.ni-book:before {
  content: "";
}
.ni-book-fill:before {
  content: "";
}
.ni-bulb-fill:before {
  content: "";
}
.ni-bulb:before {
  content: "";
}
.ni-calendar-alt-fill:before {
  content: "";
}
.ni-calendar-alt:before {
  content: "";
}
.ni-calendar-booking-fill:before {
  content: "";
}
.ni-calendar-booking:before {
  content: "";
}
.ni-calendar-check-fill:before {
  content: "";
}
.ni-calendar-check:before {
  content: "";
}
.ni-calendar-fill:before {
  content: "";
}
.ni-calendar:before {
  content: "";
}
.ni-calender-date-fill:before {
  content: "";
}
.ni-calender-date:before {
  content: "";
}
.ni-call:before {
  content: "";
}
.ni-call-alt:before {
  content: "";
}
.ni-call-alt-fill:before {
  content: "";
}
.ni-call-fill:before {
  content: "";
}
.ni-camera-fill:before {
  content: "";
}
.ni-camera:before {
  content: "";
}
.ni-capsule:before {
  content: "";
}
.ni-capsule-fill:before {
  content: "";
}
.ni-cards:before {
  content: "";
}
.ni-cards-fill:before {
  content: "";
}
.ni-cart:before {
  content: "";
}
.ni-cart-fill:before {
  content: "";
}
.ni-cc:before {
  content: "";
}
.ni-cc-alt:before {
  content: "";
}
.ni-cc-alt2:before {
  content: "";
}
.ni-cc-secure:before {
  content: "";
}
.ni-cc-new:before {
  content: "";
}
.ni-cc-off:before {
  content: "";
}
.ni-cc-fill:before {
  content: "";
}
.ni-cc-alt-fill:before {
  content: "";
}
.ni-cc-alt2-fill:before {
  content: "";
}
.ni-cc-secure-fill:before {
  content: "";
}
.ni-msg-circle:before {
  content: "";
}
.ni-chat-circle:before {
  content: "";
}
.ni-msg:before {
  content: "";
}
.ni-chat:before {
  content: "";
}
.ni-question-alt:before {
  content: "";
}
.ni-question:before {
  content: "";
}
.ni-msg-circle-fill:before {
  content: "";
}
.ni-chat-circle-fill:before {
  content: "";
}
.ni-msg-fill:before {
  content: "";
}
.ni-chat-fill:before {
  content: "";
}
.ni-clip-h:before {
  content: "";
}
.ni-clip-v:before {
  content: "";
}
.ni-clip:before {
  content: "";
}
.ni-link-alt:before {
  content: "";
}
.ni-unlink:before {
  content: "";
}
.ni-unlink-alt:before {
  content: "";
}
.ni-link-h:before {
  content: "";
}
.ni-link-v:before {
  content: "";
}
.ni-link:before {
  content: "";
}
.ni-clipboard:before {
  content: "";
}
.ni-clipboad-check:before {
  content: "";
}
.ni-clipboard-fill:before {
  content: "";
}
.ni-clipboad-check-fill:before {
  content: "";
}
.ni-clock:before {
  content: "";
}
.ni-clock-fill:before {
  content: "";
}
.ni-cloud:before {
  content: "";
}
.ni-upload-cloud:before {
  content: "";
}
.ni-download-cloud:before {
  content: "";
}
.ni-cloud-fill:before {
  content: "";
}
.ni-contact:before {
  content: "";
}
.ni-contact-fill:before {
  content: "";
}
.ni-coffee:before {
  content: "";
}
.ni-coffee-fill:before {
  content: "";
}
.ni-box-view:before {
  content: "";
}
.ni-col-view:before {
  content: "";
}
.ni-sidebar:before {
  content: "";
}
.ni-layout:before {
  content: "";
}
.ni-table-view:before {
  content: "";
}
.ni-layout2:before {
  content: "";
}
.ni-row-view:before {
  content: "";
}
.ni-dot-box:before {
  content: "";
}
.ni-layout-fill:before {
  content: "";
}
.ni-box-view-fill:before {
  content: "";
}
.ni-sidebar-fill:before {
  content: "";
}
.ni-table-view-fill:before {
  content: "";
}
.ni-dot-box-fill:before {
  content: "";
}
.ni-template:before {
  content: "";
}
.ni-browser:before {
  content: "";
}
.ni-toolbar:before {
  content: "";
}
.ni-browser-fill:before {
  content: "";
}
.ni-toolbar-fill:before {
  content: "";
}
.ni-template-fill:before {
  content: "";
}
.ni-box:before {
  content: "";
}
.ni-package:before {
  content: "";
}
.ni-layer:before {
  content: "";
}
.ni-layers:before {
  content: "";
}
.ni-panel:before {
  content: "";
}
.ni-server:before {
  content: "";
}
.ni-layer-fill:before {
  content: "";
}
.ni-layers-fill:before {
  content: "";
}
.ni-package-fill:before {
  content: "";
}
.ni-panel-fill:before {
  content: "";
}
.ni-server-fill:before {
  content: "";
}
.ni-color-palette:before {
  content: "";
}
.ni-color-palette-fill:before {
  content: "";
}
.ni-copy:before {
  content: "";
}
.ni-copy-fill:before {
  content: "";
}
.ni-crop-alt:before {
  content: "";
}
.ni-crop:before {
  content: "";
}
.ni-target:before {
  content: "";
}
.ni-crosshair:before {
  content: "";
}
.ni-crosshair-fill:before {
  content: "";
}
.ni-db-fill:before {
  content: "";
}
.ni-db:before {
  content: "";
}
.ni-hard-drive:before {
  content: "";
}
.ni-cpu:before {
  content: "";
}
.ni-disk:before {
  content: "";
}
.ni-pen:before {
  content: "";
}
.ni-edit-alt:before {
  content: "";
}
.ni-pen-fill:before {
  content: "";
}
.ni-edit-alt-fill:before {
  content: "";
}
.ni-pen-alt-fill:before {
  content: "";
}
.ni-edit-fill:before {
  content: "";
}
.ni-edit:before {
  content: "";
}
.ni-external-alt:before {
  content: "";
}
.ni-external:before {
  content: "";
}
.ni-eye-alt:before {
  content: "";
}
.ni-eye-alt-fill:before {
  content: "";
}
.ni-eye:before {
  content: "";
}
.ni-eye-fill:before {
  content: "";
}
.ni-eye-off:before {
  content: "";
}
.ni-eye-off-fill:before {
  content: "";
}
.ni-file:before {
  content: "";
}
.ni-file-minus:before {
  content: "";
}
.ni-file-plus:before {
  content: "";
}
.ni-file-remove:before {
  content: "";
}
.ni-file-check:before {
  content: "";
}
.ni-file-code:before {
  content: "";
}
.ni-file-docs:before {
  content: "";
}
.ni-file-img:before {
  content: "";
}
.ni-file-doc:before {
  content: "";
}
.ni-file-pdf:before {
  content: "";
}
.ni-file-xls:before {
  content: "";
}
.ni-file-zip:before {
  content: "";
}
.ni-file-download:before {
  content: "";
}
.ni-file-text:before {
  content: "";
}
.ni-files:before {
  content: "";
}
.ni-file-fill:before {
  content: "";
}
.ni-file-minus-fill:before {
  content: "";
}
.ni-file-plus-fill:before {
  content: "";
}
.ni-file-remove-fill:before {
  content: "";
}
.ni-file-check-fill:before {
  content: "";
}
.ni-file-text-fill:before {
  content: "";
}
.ni-files-fill:before {
  content: "";
}
.ni-folder:before {
  content: "";
}
.ni-folder-minus:before {
  content: "";
}
.ni-folder-plus:before {
  content: "";
}
.ni-folder-remove:before {
  content: "";
}
.ni-folder-check:before {
  content: "";
}
.ni-folder-list:before {
  content: "";
}
.ni-folders:before {
  content: "";
}
.ni-folder-fill:before {
  content: "";
}
.ni-folders-fill:before {
  content: "";
}
.ni-filter-alt:before {
  content: "";
}
.ni-sort-line:before {
  content: "";
}
.ni-filter-fill:before {
  content: "";
}
.ni-filter:before {
  content: "";
}
.ni-flag:before {
  content: "";
}
.ni-flag-fill:before {
  content: "";
}
.ni-notify:before {
  content: "";
}
.ni-dashboard:before {
  content: "";
}
.ni-dashboard-fill:before {
  content: "";
}
.ni-grid-sq:before {
  content: "";
}
.ni-grid:before {
  content: "";
}
.ni-grid-c:before {
  content: "";
}
.ni-grid-alt:before {
  content: "";
}
.ni-grid-plus:before {
  content: "";
}
.ni-grid-add-c:before {
  content: "";
}
.ni-grid-fill:before {
  content: "";
}
.ni-grid-fill-c:before {
  content: "";
}
.ni-grid-alt-fill:before {
  content: "";
}
.ni-grid-plus-fill:before {
  content: "";
}
.ni-grid-add-fill-c:before {
  content: "";
}
.ni-grid-box-alt-fill:before {
  content: "";
}
.ni-grid-box-alt:before {
  content: "";
}
.ni-grid-box:before {
  content: "";
}
.ni-grid-box-fill:before {
  content: "";
}
.ni-grid-line:before {
  content: "";
}
.ni-menu-alt-left:before {
  content: "";
}
.ni-menu-alt-r:before {
  content: "";
}
.ni-menu-alt:before {
  content: "";
}
.ni-menu-center:before {
  content: "";
}
.ni-menu-left:before {
  content: "";
}
.ni-menu-right:before {
  content: "";
}
.ni-menu:before {
  content: "";
}
.ni-trend-up:before {
  content: "";
}
.ni-trend-down:before {
  content: "";
}
.ni-line-chart-down:before {
  content: "";
}
.ni-line-chart-up:before {
  content: "";
}
.ni-line-chart:before {
  content: "";
}
.ni-bar-chart:before {
  content: "";
}
.ni-bar-chart-alt:before {
  content: "";
}
.ni-chart-up:before {
  content: "";
}
.ni-chart-down:before {
  content: "";
}
.ni-growth:before {
  content: "";
}
.ni-growth-fill:before {
  content: "";
}
.ni-bar-chart-fill:before {
  content: "";
}
.ni-bar-c:before {
  content: "";
}
.ni-bar-fill-c:before {
  content: "";
}
.ni-pie:before {
  content: "";
}
.ni-pie-alt:before {
  content: "";
}
.ni-pie-fill:before {
  content: "";
}
.ni-activity:before {
  content: "";
}
.ni-activity-alt:before {
  content: "";
}
.ni-activity-round:before {
  content: "";
}
.ni-activity-round-fill:before {
  content: "";
}
.ni-meter:before {
  content: "";
}
.ni-speed:before {
  content: "";
}
.ni-happy:before {
  content: "";
}
.ni-sad:before {
  content: "";
}
.ni-meh:before {
  content: "";
}
.ni-happyf-fill:before {
  content: "";
}
.ni-sad-fill:before {
  content: "";
}
.ni-meh-fill:before {
  content: "";
}
.ni-home:before {
  content: "";
}
.ni-home-alt:before {
  content: "";
}
.ni-home-fill:before {
  content: "";
}
.ni-img:before {
  content: "";
}
.ni-img-fill:before {
  content: "";
}
.ni-inbox:before {
  content: "";
}
.ni-inbox-in:before {
  content: "";
}
.ni-inbox-out:before {
  content: "";
}
.ni-inbox-fill:before {
  content: "";
}
.ni-inbox-in-fill:before {
  content: "";
}
.ni-inbox-out-fill:before {
  content: "";
}
.ni-link-group:before {
  content: "";
}
.ni-lock:before {
  content: "";
}
.ni-lock-alt:before {
  content: "";
}
.ni-lock-fill:before {
  content: "";
}
.ni-lock-alt-fill:before {
  content: "";
}
.ni-unlock:before {
  content: "";
}
.ni-unlock-fill:before {
  content: "";
}
.ni-mail:before {
  content: "";
}
.ni-emails:before {
  content: "";
}
.ni-mail-fill:before {
  content: "";
}
.ni-emails-fill:before {
  content: "";
}
.ni-map-pin:before {
  content: "";
}
.ni-location:before {
  content: "";
}
.ni-map:before {
  content: "";
}
.ni-map-pin-fill:before {
  content: "";
}
.ni-list:before {
  content: "";
}
.ni-list-ol:before {
  content: "";
}
.ni-align-center:before {
  content: "";
}
.ni-align-justify:before {
  content: "";
}
.ni-align-left:before {
  content: "";
}
.ni-align-right:before {
  content: "";
}
.ni-list-check:before {
  content: "";
}
.ni-list-round:before {
  content: "";
}
.ni-card-view:before {
  content: "";
}
.ni-list-fill:before {
  content: "";
}
.ni-save:before {
  content: "";
}
.ni-save-fill:before {
  content: "";
}
.ni-move:before {
  content: "";
}
.ni-scissor:before {
  content: "";
}
.ni-text:before {
  content: "";
}
.ni-text-a:before {
  content: "";
}
.ni-bold:before {
  content: "";
}
.ni-italic:before {
  content: "";
}
.ni-underline:before {
  content: "";
}
.ni-percent:before {
  content: "";
}
.ni-at:before {
  content: "";
}
.ni-hash:before {
  content: "";
}
.ni-code:before {
  content: "";
}
.ni-code-download:before {
  content: "";
}
.ni-terminal:before {
  content: "";
}
.ni-cmd:before {
  content: "";
}
.ni-sun:before {
  content: "";
}
.ni-sun-fill:before {
  content: "";
}
.ni-moon-fill:before {
  content: "";
}
.ni-moon:before {
  content: "";
}
.ni-light:before {
  content: "";
}
.ni-light-fill:before {
  content: "";
}
.ni-more-v:before {
  content: "";
}
.ni-more-h:before {
  content: "";
}
.ni-more-h-alt:before {
  content: "";
}
.ni-more-v-alt:before {
  content: "";
}
.ni-music:before {
  content: "";
}
.ni-movie:before {
  content: "";
}
.ni-offer:before {
  content: "";
}
.ni-offer-fill:before {
  content: "";
}
.ni-opt-alt:before {
  content: "";
}
.ni-opt:before {
  content: "";
}
.ni-opt-dot-alt:before {
  content: "";
}
.ni-opt-dot:before {
  content: "";
}
.ni-opt-dot-fill:before {
  content: "";
}
.ni-opt-alt-fill:before {
  content: "";
}
.ni-user-alt:before {
  content: "";
}

.ni-user:before {
  content: "";
}
.ni-users:before {
  content: "";
}
.ni-user-add:before {
  content: "";
}
.ni-user-remove:before {
  content: "";
}
.ni-user-check:before {
  content: "";
}
.ni-user-cross:before {
  content: "";
}
.ni-account-setting:before {
  content: "";
}
.ni-account-setting-alt:before {
  content: "";
}
.ni-user-list:before {
  content: "";
}
.ni-user-fill:before {
  content: "";
}
.ni-users-fill:before {
  content: "";
}
.ni-user-add-fill:before {
  content: "";
}
.ni-user-remove-fill:before {
  content: "";
}
.ni-user-check-fill:before {
  content: "";
}
.ni-user-cross-fill:before {
  content: "";
}
.ni-account-setting-fill:before {
  content: "";
}
.ni-user-list-fill:before {
  content: "";
}
.ni-user-circle:before {
  content: "";
}
.ni-user-circle-fill:before {
  content: "";
}
.ni-user-c:before {
  content: "";
}
.ni-user-fill-c:before {
  content: "";
}
.ni-user-round:before {
  content: "";
}
.ni-printer:before {
  content: "";
}
.ni-printer-fill:before {
  content: "";
}
.ni-laptop:before {
  content: "";
}
.ni-monitor:before {
  content: "";
}
.ni-tablet:before {
  content: "";
}
.ni-mobile:before {
  content: "";
}
.ni-undo:before {
  content: "";
}
.ni-redo:before {
  content: "";
}
.ni-reload-alt:before {
  content: "";
}
.ni-reload:before {
  content: "";
}
.ni-regen-alt:before {
  content: "";
}
.ni-regen:before {
  content: "";
}
.ni-invest:before {
  content: "";
}
.ni-history:before {
  content: "";
}
.ni-update:before {
  content: "";
}
.ni-repeat:before {
  content: "";
}
.ni-repeat-v:before {
  content: "";
}
.ni-tranx:before {
  content: "";
}
.ni-reply-all:before {
  content: "";
}
.ni-reply:before {
  content: "";
}
.ni-reply-fill:before {
  content: "";
}
.ni-reply-all-fill:before {
  content: "";
}
.ni-notes:before {
  content: "";
}
.ni-note-add:before {
  content: "";
}
.ni-notes-alt:before {
  content: "";
}
.ni-article:before {
  content: "";
}
.ni-text-rich:before {
  content: "";
}
.ni-todo:before {
  content: "";
}
.ni-report-profit:before {
  content: "";
}
.ni-reports-alt:before {
  content: "";
}
.ni-reports:before {
  content: "";
}
.ni-task:before {
  content: "";
}
.ni-note-add-c:before {
  content: "";
}
.ni-task-c:before {
  content: "";
}
.ni-todo-fill:before {
  content: "";
}
.ni-note-add-fill-c:before {
  content: "";
}
.ni-task-fill-c:before {
  content: "";
}
.ni-scan-fill:before {
  content: "";
}
.ni-scan:before {
  content: "";
}
.ni-qr:before {
  content: "";
}
.ni-money:before {
  content: "";
}
.ni-coins:before {
  content: "";
}
.ni-coin:before {
  content: "";
}
.ni-coin-alt:before {
  content: "";
}
.ni-coin-alt-fill:before {
  content: "";
}
.ni-setting-alt-fill:before {
  content: "";
}
.ni-setting-alt:before {
  content: "";
}
.ni-setting-fill:before {
  content: "";
}
.ni-setting:before {
  content: "";
}
.ni-share-alt:before {
  content: "";
}
.ni-share-fill:before {
  content: "";
}
.ni-share:before {
  content: "";
}
.ni-network:before {
  content: "";
}
.ni-rss:before {
  content: "";
}
.ni-shield:before {
  content: "";
}
.ni-shield-star:before {
  content: "";
}
.ni-shield-check:before {
  content: "";
}
.ni-shield-alert:before {
  content: "";
}
.ni-shield-off:before {
  content: "";
}
.ni-security:before {
  content: "";
}
.ni-policy:before {
  content: "";
}
.ni-shield-alert-fill:before {
  content: "";
}
.ni-shield-check-fill:before {
  content: "";
}
.ni-shield-fill:before {
  content: "";
}
.ni-shield-half:before {
  content: "";
}
.ni-shield-star-fill:before {
  content: "";
}
.ni-policy-fill:before {
  content: "";
}
.ni-spark:before {
  content: "";
}
.ni-spark-off:before {
  content: "";
}
.ni-spark-fill:before {
  content: "";
}
.ni-spark-off-fill:before {
  content: "";
}
.ni-wallet:before {
  content: "";
}
.ni-wallet-alt:before {
  content: "";
}
.ni-wallet-in:before {
  content: "";
}
.ni-wallet-out:before {
  content: "";
}
.ni-wallet-saving:before {
  content: "";
}
.ni-wallet-fill:before {
  content: "";
}
.ni-star:before {
  content: "";
}
.ni-star-half:before {
  content: "";
}
.ni-star-half-fill:before {
  content: "";
}
.ni-star-fill:before {
  content: "";
}
.ni-star-round:before {
  content: "";
}
.ni-heart:before {
  content: "";
}
.ni-heart-fill:before {
  content: "";
}
.ni-swap-alt-fill:before {
  content: "";
}
.ni-swap-alt:before {
  content: "";
}
.ni-thumbs-down:before {
  content: "";
}
.ni-thumbs-up:before {
  content: "";
}
.ni-tag:before {
  content: "";
}
.ni-tag-alt:before {
  content: "";
}
.ni-tags:before {
  content: "";
}
.ni-tag-fill:before {
  content: "";
}
.ni-tag-alt-fill:before {
  content: "";
}
.ni-tags-fill:before {
  content: "";
}
.ni-bookmark:before {
  content: "";
}
.ni-bookmark-fill:before {
  content: "";
}
.ni-label:before {
  content: "";
}
.ni-label-fill:before {
  content: "";
}
.ni-priority:before {
  content: "";
}
.ni-priority-fill:before {
  content: "";
}
.ni-label-alt:before {
  content: "";
}
.ni-label-alt-fill:before {
  content: "";
}
.ni-ticket-alt:before {
  content: "";
}
.ni-ticket:before {
  content: "";
}
.ni-ticket-minus:before {
  content: "";
}
.ni-ticket-plus:before {
  content: "";
}
.ni-ticket-alt-fill:before {
  content: "";
}
.ni-ticket-fill:before {
  content: "";
}
.ni-ticket-minus-fill:before {
  content: "";
}
.ni-ticket-plus-fill:before {
  content: "";
}
.ni-toggle-off:before {
  content: "";
}
.ni-toggle-on:before {
  content: "";
}
.ni-trash-alt:before {
  content: "";
}
.ni-trash-empty:before {
  content: "";
}
.ni-trash:before {
  content: "";
}
.ni-trash-fill:before {
  content: "";
}
.ni-trash-empty-fill:before {
  content: "";
}
.ni-delete-fill:before {
  content: "";
}
.ni-delete:before {
  content: "";
}
.ni-alarm-alt:before {
  content: "";
}
.ni-alarm:before {
  content: "";
}
.ni-bugs:before {
  content: "";
}
.ni-building:before {
  content: "";
}
.ni-building-fill:before {
  content: "";
}
.ni-headphone:before {
  content: "";
}
.ni-headphone-fill:before {
  content: "";
}
.ni-aperture:before {
  content: "";
}
.ni-help-alt:before {
  content: "";
}
.ni-award:before {
  content: "";
}
.ni-briefcase:before {
  content: "";
}
.ni-gift:before {
  content: "";
}
.ni-globe:before {
  content: "";
}
.ni-umbrela:before {
  content: "";
}
.ni-truck:before {
  content: "";
}
.ni-sign-usd:before {
  content: "";
}
.ni-sign-dollar:before {
  content: "";
}
.ni-sign-mxn:before {
  content: "";
}
.ni-sign-sgd:before {
  content: "";
}
.ni-sign-euro:before {
  content: "";
}
.ni-sign-eur:before {
  content: "";
}
.ni-sign-gbp:before {
  content: "";
}
.ni-sign-pound:before {
  content: "";
}
.ni-sign-thb:before {
  content: "";
}
.ni-sign-inr:before {
  content: "";
}
.ni-sign-jpy:before {
  content: "";
}
.ni-sign-yen:before {
  content: "";
}
.ni-sign-cny:before {
  content: "";
}
.ni-sign-kobo:before {
  content: "";
}
.ni-sign-chf:before {
  content: "";
}
.ni-sign-vnd:before {
  content: "";
}
.ni-sign-php:before {
  content: "";
}
.ni-sign-brl:before {
  content: "";
}
.ni-sign-idr:before {
  content: "";
}
.ni-sign-czk:before {
  content: "";
}
.ni-sign-hkd:before {
  content: "";
}
.ni-sign-kr:before {
  content: "";
}
.ni-sign-dkk:before {
  content: "";
}
.ni-sign-nok:before {
  content: "";
}
.ni-sign-sek:before {
  content: "";
}
.ni-sign-rub:before {
  content: "";
}
.ni-sign-myr:before {
  content: "";
}
.ni-sign-pln:before {
  content: "";
}
.ni-sign-try:before {
  content: "";
}
.ni-sign-waves:before {
  content: "";
}
.ni-waves:before {
  content: "";
}
.ni-sign-trx:before {
  content: "";
}
.ni-tron:before {
  content: "";
}
.ni-sign-xem:before {
  content: "";
}
.ni-nem:before {
  content: "";
}
.ni-sign-mxr:before {
  content: "";
}
.ni-monero:before {
  content: "";
}
.ni-sign-usdc:before {
  content: "";
}
.ni-sign-steller:before {
  content: "";
}
.ni-sign-steem:before {
  content: "";
}
.ni-sign-usdt:before {
  content: "";
}
.ni-tether:before {
  content: "";
}
.ni-sign-btc:before {
  content: "";
}
.ni-bitcoin:before {
  content: "";
}
.ni-sign-bch:before {
  content: "";
}
.ni-bitcoin-cash:before {
  content: "";
}
.ni-sign-bnb:before {
  content: "";
}
.ni-binance:before {
  content: "";
}
.ni-sign-ada:before {
  content: "";
}
.ni-sign-zcash:before {
  content: "";
}
.ni-sign-eth:before {
  content: "";
}
.ni-ethereum:before {
  content: "";
}
.ni-sign-dash:before {
  content: "";
}
.ni-dash:before {
  content: "";
}
.ni-sign-xrp-old:before {
  content: "";
}
.ni-ripple-old:before {
  content: "";
}
.ni-sign-eos:before {
  content: "";
}
.ni-eos:before {
  content: "";
}
.ni-sign-xrp:before {
  content: "";
}
.ni-ripple:before {
  content: "";
}
.ni-american-express:before {
  content: "";
}
.ni-jcb:before {
  content: "";
}
.ni-cc-jcb:before {
  content: "";
}
.ni-mc:before {
  content: "";
}
.ni-cc-mc:before {
  content: "";
}
.ni-discover:before {
  content: "";
}
.ni-cc-discover:before {
  content: "";
}
.ni-visa:before {
  content: "";
}
.ni-cc-visa:before {
  content: "";
}
.ni-cc-paypal:before {
  content: "";
}
.ni-cc-stripe:before {
  content: "";
}
.ni-amazon-pay:before {
  content: "";
}
.ni-amazon-pay-fill:before {
  content: "";
}
.ni-google-pay:before {
  content: "";
}
.ni-google-pay-fill:before {
  content: "";
}
.ni-apple-pay:before {
  content: "";
}
.ni-apple-pay-fill:before {
  content: "";
}
.ni-angular:before {
  content: "";
}
.ni-react:before {
  content: "";
}
.ni-laravel:before {
  content: "";
}
.ni-html5:before {
  content: "";
}
.ni-css3-fill:before {
  content: "";
}
.ni-css3:before {
  content: "";
}
.ni-js:before {
  content: "";
}
.ni-php:before {
  content: "";
}
.ni-python:before {
  content: "";
}
.ni-bootstrap:before {
  content: "";
}
.ni-ebay:before {
  content: "";
}
.ni-google-wallet:before {
  content: "";
}
.ni-google-drive:before {
  content: "";
}
.ni-google-play-store:before {
  content: "";
}
.ni-android:before {
  content: "";
}
.ni-blogger-fill:before {
  content: "";
}
.ni-blogger:before {
  content: "";
}
.ni-hangout:before {
  content: "";
}
.ni-apple-store:before {
  content: "";
}
.ni-apple-store-ios:before {
  content: "";
}
.ni-stripe:before {
  content: "";
}
.ni-apple:before {
  content: "";
}
.ni-microsoft:before {
  content: "";
}
.ni-windows:before {
  content: "";
}
.ni-amazon:before {
  content: "";
}
.ni-paypal-alt:before {
  content: "";
}
.ni-airbnb:before {
  content: "";
}
.ni-adobe:before {
  content: "";
}
.ni-mailchimp:before {
  content: "";
}
.ni-dropbox:before {
  content: "";
}
.ni-digital-ocean:before {
  content: "";
}
.ni-slack:before {
  content: "";
}
.ni-slack-hash:before {
  content: "";
}
.ni-stack-overflow:before {
  content: "";
}
.ni-soundcloud:before {
  content: "";
}
.ni-blackberry:before {
  content: "";
}
.ni-spotify:before {
  content: "";
}
.ni-kickstarter:before {
  content: "";
}
.ni-houzz:before {
  content: "";
}
.ni-vine:before {
  content: "";
}
.ni-yelp:before {
  content: "";
}
.ni-yoast:before {
  content: "";
}
.ni-envato:before {
  content: "";
}
.ni-wordpress:before {
  content: "";
}
.ni-wp:before {
  content: "";
}
.ni-wordpress-fill:before {
  content: "";
}
.ni-elementor:before {
  content: "";
}
.ni-joomla:before {
  content: "";
}
.ni-megento:before {
  content: "";
}
.ni-git:before {
  content: "";
}
.ni-github:before {
  content: "";
}
.ni-github-round:before {
  content: "";
}
.ni-github-circle:before {
  content: "";
}
.ni-dribbble:before {
  content: "";
}
.ni-dribbble-round:before {
  content: "";
}
.ni-behance:before {
  content: "";
}
.ni-behance-fill:before {
  content: "";
}
.ni-flickr:before {
  content: "";
}
.ni-flickr-round:before {
  content: "";
}
.ni-medium:before {
  content: "";
}
.ni-medium-round:before {
  content: "";
}
.ni-reddit:before {
  content: "";
}
.ni-reddit-round:before {
  content: "";
}
.ni-reddit-circle:before {
  content: "";
}
.ni-google:before {
  content: "";
}
.ni-facebook-f:before {
  content: "";
}
.ni-facebook-fill:before {
  content: "";
}
.ni-facebook-circle:before {
  content: "";
}
.ni-instagram:before {
  content: "";
}
.ni-instagram-round:before {
  content: "";
}
.ni-linkedin:before {
  content: "";
}
.ni-linkedin-round:before {
  content: "";
}
.ni-twitter:before {
  content: "";
}
.ni-twitter-round:before {
  content: "";
}
.ni-pinterest:before {
  content: "";
}
.ni-pinterest-round:before {
  content: "";
}
.ni-pinterest-circle:before {
  content: "";
}
.ni-tumblr:before {
  content: "";
}
.ni-tumblr-round:before {
  content: "";
}
.ni-skype:before {
  content: "";
}
.ni-viber:before {
  content: "";
}
.ni-whatsapp:before {
  content: "";
}
.ni-whatsapp-round:before {
  content: "";
}
.ni-snapchat:before {
  content: "";
}
.ni-snapchat-fill:before {
  content: "";
}
.ni-telegram:before {
  content: "";
}
.ni-telegram-circle:before {
  content: "";
}
.ni-youtube-line:before {
  content: "";
}
.ni-youtube:before {
  content: "";
}
.ni-youtube-fill:before {
  content: "";
}
.ni-youtube-round:before {
  content: "";
}
.ni-vimeo:before {
  content: "";
}
.ni-vimeo-fill:before {
  content: "";
}
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper,
.simplebar-scroll-content {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden scroll;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-scroll-content::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}
.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}
.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}
.simplebar-scrollbar .hidden-scrollbar {
  visibility: hidden;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #6b7280;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 8px !important;
  transition: width 0.1s;
}
.simplebar-track.simplebar-vertical.simplebar-hover {
  width: 10px !important;
}
.simplebar-track.simplebar-vertical .nk-sidebar {
  visibility: hidden !important;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 8px !important;
  transition: height 0.1s;
}
.simplebar-track.simplebar-horizontal.simplebar-hover {
  height: 10px !important;
}
.nk-sidebar .simplebar-track.simplebar-horizontal,
.nk-sidebar-fat .simplebar-track.simplebar-horizontal,
.nk-apps-sidebar .simplebar-track.simplebar-horizontal {
  visibility: hidden !important;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}
.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 14px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #f2f3f8;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}
.choices[data-type*="select-one"] {
  cursor: pointer;
}
.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 0.5625rem 1.125rem;
  border-bottom: 1px solid #d2d4e4;
  background-color: #fff;
  margin: 0;
}
.choices[data-type*="select-one"] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}
.choices[data-type*="select-one"] .choices__button:hover,
.choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #5f38f9;
}
.choices[data-type*="select-one"]
  .choices__item[data-value=""]
  .choices__button {
  display: none;
}
.choices[data-type*="select-one"]:after {
  content: "";
  font-family: "Nioicon";
  position: absolute;
  right: 11.5px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #787c9e;
}
.choices[data-type*="select-one"].is-open:after {
  content: "";
}
.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}
.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text;
}
.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 0.375rem;
  width: 0.375rem;
  line-height: 1;
  opacity: 0.4;
  filter: brightness(0);
}
.choices[data-type*="select-multiple"] .choices__button:hover,
.choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}
.choices__inner {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #fff;
  padding: 0.5625rem;
  border: 1px solid #d2d4e4;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  min-height: 2.75rem;
  overflow: hidden;
}
.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: rgba(95, 56, 249, 0.65);
  box-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
}
.choices__inner-plaintext {
  padding-left: 0;
  padding-right: 0;
  border: 0;
}
.is-focused .choices__inner-plaintext,
.is-open .choices__inner-plaintext {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.choices__list--single {
  display: inline-block;
  width: 100%;
}
[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
  padding: 0 0.5625rem;
}
.choices__list--multiple {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -2px;
}
.choices__list--multiple .choices__item {
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding: 0 8px;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  margin: 2px;
  background-color: #d4d7e6;
  color: #24214b;
  word-break: break-all;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0);
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #e7e1fe;
  border-color: #e7e1fe;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #e1e4ef;
  border-color: #d7dbea;
}
.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #fff;
  top: 100%;
  border-radius: 0.375rem;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
  box-shadow: 0 2px 12px -1px rgba(67, 71, 107, 0.2);
  margin-top: 4px;
}
.choices__list--dropdown.is-active {
  visibility: visible;
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  padding: 0.5rem 0;
}
.choices__list--dropdown .choices__item {
  position: relative;
  padding: 0.375rem 1rem;
  font-size: 14px;
  color: #43476b;
}
[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    display: flex;
    align-items: center;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    margin-left: auto;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: rgba(95, 56, 249, 0.06);
  color: #5f38f9;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}
.choices__item {
  cursor: default;
}
.choices__item--selectable {
  cursor: pointer;
  color: #43476b;
}
.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}
.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f2f2f7;
  color: gray;
}
.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}
.choices__input {
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5rem;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 0 0.8125rem;
  flex-grow: 1;
}
.choices__input:focus {
  outline: 0;
}
[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}
.choices__placeholder {
  opacity: 0.5;
}
.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075),
    1px 2px 4px rgba(0, 0, 0, 0.075), 1px 3px 8px rgba(0, 0, 0, 0.075),
    2px 4px 16px rgba(0, 0, 0, 0.075);
  pointer-events: all;
}
.swal2-popup.swal2-toast > * {
  grid-column: 2;
}
.swal2-popup.swal2-toast .swal2-title {
  margin: 0.5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial;
}
.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center;
}
.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: 0.5em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}
.swal2-popup.swal2-toast .swal2-close {
  grid-column: 3/3;
  grid-row: 1/99;
  align-self: center;
  width: 0.8em;
  height: 0.8em;
  margin: 0;
  font-size: 2em;
}
.swal2-popup.swal2-toast .swal2-html-container {
  margin: 0.5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial;
}
.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-loader {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: 0.25em;
}
.swal2-popup.swal2-toast .swal2-icon {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 0.5em 0 0;
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  top: 0.875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="left"] {
  left: 0.3125em;
}
.swal2-popup.swal2-toast
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="right"] {
  right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
  justify-content: flex-start;
  height: auto;
  margin: 0;
  margin-top: 0.5em;
  padding: 0 0.5em;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0.25em 0.5em;
  padding: 0.4em 0.6em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #2dc58c;
}
.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  top: -0.8em;
  left: -0.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-circular-line"][class$="right"] {
  top: -0.25em;
  left: 0.9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"] {
  height: 0.3125em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-line"][class$="tip"] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-line"][class$="long"] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast
  .swal2-success.swal2-icon-show
  .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip 0.75s;
}
.swal2-popup.swal2-toast
  .swal2-success.swal2-icon-show
  .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long 0.75s;
}
.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide 0.1s forwards;
}
.swal2-container {
  display: grid;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(
      min-content,
      auto
    );
  height: 100%;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(46, 49, 74, 0.4);
}
.swal2-container.swal2-backdrop-hide {
  background: rgba(0, 0, 0, 0) !important;
}
.swal2-container.swal2-top-start,
.swal2-container.swal2-center-start,
.swal2-container.swal2-bottom-start {
  grid-template-columns: minmax(0, 1fr) auto auto;
}
.swal2-container.swal2-top,
.swal2-container.swal2-center,
.swal2-container.swal2-bottom {
  grid-template-columns: auto minmax(0, 1fr) auto;
}
.swal2-container.swal2-top-end,
.swal2-container.swal2-center-end,
.swal2-container.swal2-bottom-end {
  grid-template-columns: auto auto minmax(0, 1fr);
}
.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start;
}
.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  align-self: start;
  justify-self: center;
}
.swal2-container.swal2-top-end > .swal2-popup,
.swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  align-self: start;
  justify-self: end;
}
.swal2-container.swal2-center-start > .swal2-popup,
.swal2-container.swal2-center-left > .swal2-popup {
  grid-row: 2;
  align-self: center;
}
.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
}
.swal2-container.swal2-center-end > .swal2-popup,
.swal2-container.swal2-center-right > .swal2-popup {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
  justify-self: end;
}
.swal2-container.swal2-bottom-start > .swal2-popup,
.swal2-container.swal2-bottom-left > .swal2-popup {
  grid-column: 1;
  grid-row: 3;
  align-self: end;
}
.swal2-container.swal2-bottom > .swal2-popup {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  align-self: end;
}
.swal2-container.swal2-bottom-end > .swal2-popup,
.swal2-container.swal2-bottom-right > .swal2-popup {
  grid-column: 3;
  grid-row: 3;
  align-self: end;
  justify-self: end;
}
.swal2-container.swal2-grow-row > .swal2-popup,
.swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-column: 1/4;
  width: 100%;
}
.swal2-container.swal2-grow-column > .swal2-popup,
.swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1/4;
  align-self: stretch;
}
.swal2-container.swal2-no-transition {
  transition: none !important;
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 2.25rem 1.25em;
  border: none;
  border-radius: 0.375rem;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-popup:focus {
  outline: none;
}
.swal2-popup.swal2-loading {
  overflow-y: hidden;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.5rem;
  color: #43476b;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0;
}
.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: 0.4;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: 0.25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 rgba(0, 0, 0, 0) #2778c4 rgba(0, 0, 0, 0);
}
.swal2-styled {
  margin: 0.3125rem;
  padding: 0.5rem 1.125rem;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  font-weight: 500;
  min-width: 80px;
}
.swal2-styled:focus {
  box-shadow: none;
}
.swal2-styled:not([disabled]) {
  cursor: pointer;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #2dc58c;
  color: #fff;
  font-size: 0.875rem;
}
.swal2-styled.swal2-deny {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 0.938rem;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #df3c4e;
  color: #fff;
  font-size: 0.938rem;
}
.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-styled:focus {
  outline: none;
}
.swal2-styled::-moz-focus-inner {
  border: 0;
}
.swal2-footer {
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border-top: 1px solid #eee;
  color: inherit;
  font-size: 1em;
}
.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: auto !important;
  overflow: hidden;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.swal2-timer-progress-bar {
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2);
}
.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em;
}
.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s, box-shadow 0.1s;
  border: none;
  border-radius: 0.375rem;
  background: rgba(0, 0, 0, 0);
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end;
}
.swal2-close:hover {
  transform: none;
  background: rgba(0, 0, 0, 0);
  color: #df3c4e;
}
.swal2-close:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-close::-moz-focus-inner {
  border: 0;
}
.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 1em;
  padding: 0;
  overflow: auto;
  color: #43476b;
  font-size: 0.938rem;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}
.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1em 2em 3px;
}
.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: auto;
  transition: border-color 0.1s, box-shadow 0.1s;
  border: 1px solid #d2d4e4;
  border-radius: 0.375rem;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(0, 0, 0, 0);
  color: inherit;
  font-size: 0.875rem;
}
.swal2-input.swal2-inputerror,
.swal2-file.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #df3c4e !important;
  box-shadow: 0 0 2px #df3c4e !important;
}
.swal2-input:focus,
.swal2-file:focus,
.swal2-textarea:focus {
  border: 1px solid rgba(95, 56, 249, 0.65);
  outline: none;
  box-shadow: none;
}
.swal2-input::placeholder,
.swal2-file::placeholder,
.swal2-textarea::placeholder {
  color: #ccc;
}
.swal2-range {
  margin: 1em 2em 3px;
  background: #fff;
}
.swal2-range input {
  width: 80%;
}
.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}
.swal2-range input,
.swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 0.875rem;
  line-height: 2.625em;
}
.swal2-input {
  padding: 0.5625rem 1.125rem;
}
.swal2-file {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  background: inherit;
  font-size: 0.875rem;
}
.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}
.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  background: inherit;
  color: inherit;
  font-size: 0.875rem;
}
.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}
.swal2-radio label,
.swal2-checkbox label {
  margin: 0 0.6em;
  font-size: 0.875rem;
}
.swal2-radio input,
.swal2-checkbox input {
  flex-shrink: 0;
  margin: 0 0.4em;
}
.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto 0;
}
.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300;
}
.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #df3c4e;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}
.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #df3c4e;
  color: #df3c4e;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #df3c4e;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #f4c638;
  color: #f2bc16;
}
.swal2-icon.swal2-warning.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark 0.5s;
}
.swal2-icon.swal2-info {
  border-color: #abccfe;
  color: #478ffc;
}
.swal2-icon.swal2-info.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark 0.8s;
}
.swal2-icon.swal2-question {
  border-color: #60728e;
  color: #374151;
}
.swal2-icon.swal2-question.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-question-mark 0.8s;
}
.swal2-icon.swal2-success {
  border-color: #2dc58c;
  color: #2dc58c;
}
.swal2-icon.swal2-success [class^="swal2-success-circular-line"] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="right"] {
  top: -0.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(45, 197, 140, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #2dc58c;
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
  top: 2.875em;
  left: 0.8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}
.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  background: inherit;
  font-weight: 600;
}
.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}
.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step-line {
  background: #add8e6;
}
.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #2778c4;
}
[class^="swal2"] {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.swal2-show {
  animation: swal2-show 0.3s;
}
.swal2-hide {
  animation: swal2-hide 0.15s forwards;
}
.swal2-noanimation {
  transition: none;
}
.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0;
}
.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}
@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}
@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes swal2-show {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }
  100% {
    transform: rotateY(0);
  }
}
@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }
  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }
  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}
body.swal2-height-auto {
  height: auto !important;
}
body.swal2-no-backdrop .swal2-container {
  background-color: rgba(0, 0, 0, 0) !important;
  pointer-events: none;
}
body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all;
}
body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px rgba(46, 49, 74, 0.4);
}
@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown)
    > [aria-hidden="true"] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown)
    .swal2-container {
    position: static !important;
  }
}
body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
}
body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
body.swal2-toast-shown .swal2-container.swal2-top-start,
body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-center-start,
body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-toast-shown .swal2-container.swal2-center-end,
body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-start,
body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-end,
body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}
.dataTable-wrapper.no-header .dataTable-container {
  border-top: 1px solid #d2d4e4;
}
.dataTable-top,
.dataTable-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.25rem;
}
.dataTable-top > *,
.dataTable-bottom > * {
  width: 100%;
}
.dataTable-top > * + *,
.dataTable-bottom > * + * {
  margin-bottom: 0.75rem;
}
.card-body .dataTable-top {
  padding: 0 0 1.25rem 0;
}
.card-body .dataTable-bottom {
  padding: 1.25rem 0 0 0;
}
.dataTable-dropdown {
  position: relative;
}
.dataTable-dropdown label {
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
}
.dataTable-selector {
  padding: 0.375rem 2.375rem 0.375rem 1.125rem;
  border: 1px solid #d2d4e4;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  appearance: none;
  margin-right: 0.75rem;
  outline: none;
  color: #43476b;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231F2937' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.875rem center;
  background-size: 16px 12px;
}
.dataTable-selector:focus {
  border-color: rgba(95, 56, 249, 0.65);
  box-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
}
.dataTable-search {
  order: -1;
}
.dataTable-input {
  padding: 0.375rem 1.125rem;
  border: 1px solid #d2d4e4;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  outline: none;
  color: #43476b;
  width: 100%;
}
.dataTable-input:focus {
  border-color: rgba(95, 56, 249, 0.65);
  box-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
}
.dataTable-info {
  font-size: 0.875rem;
}
.dataTable-pagination {
  order: -1;
}
.dataTable-pagination ul {
  margin: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
}
.dataTable-pagination li {
  list-style: none;
  display: inline-flex;
}
.dataTable-pagination li:not(:last-child) {
  margin-right: 0.25rem;
}
.dataTable-pagination a {
  padding: 0.375rem 0.25rem;
  position: relative;
  text-decoration: none;
  min-width: 2.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #43476b;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0);
}
.dataTable-pagination a:hover {
  color: #5f38f9;
  background-color: #e7e1fe;
  border-color: #e7e1fe;
}
.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background-color: #dfd7fe;
  cursor: default;
  color: #5f38f9;
  border-color: #dfd7fe;
}
.dataTable-pagination .ellipsis a,
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
}
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}
.dataTable-pagination .pager a {
  font-size: 0.875rem;
}
.dataTable-pagination .pager .icon {
  font-size: 1.09375rem;
  line-height: 1.5rem;
}
@media (min-width: 576px) {
  .dataTable-top,
  .dataTable-bottom {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .dataTable-top > *,
  .dataTable-bottom > * {
    width: auto;
  }
  .dataTable-top > * + *,
  .dataTable-bottom > * + * {
    margin-bottom: 0;
  }
  .dataTable-input {
    width: 200px;
  }
  .dataTable-dropdown label {
    flex-direction: row-reverse;
  }
  .dataTable-selector {
    margin-left: 0.75rem;
    margin-right: 0;
  }
}
.dataTable-table {
  font-size: 0.875rem;
  margin-bottom: 0;
}
.dataTable-container {
  border-bottom: 1px solid #e8e7ec;
}
.dataTable-table .status-text {
  font-weight: 500;
}
.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
  vertical-align: middle;
}
.dataTable-table th {
  vertical-align: bottom;
}
.dataTable-table th a {
  text-decoration: none;
  color: inherit;
}
.dataTable-table tbody tr:last-child td {
  border-bottom-width: 0;
}
.dataTable-sorter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
  color: #787c9e !important;
  padding-right: 1.5rem;
}
.dataTable-sorter::before,
.dataTable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 3px solid rgba(0, 0, 0, 0);
  border-right: 3px solid rgba(0, 0, 0, 0);
  opacity: 0.3;
}
.dataTable-sorter::before {
  border-top: 3px solid #24214b;
  bottom: calc(50% - 6px);
}
.dataTable-sorter::after {
  border-bottom: 3px solid #24214b;
  top: calc(50% - 6px);
}
.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
  opacity: 0.6;
}
.dataTables-empty {
  text-align: center;
  font-size: 0.875rem;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc-unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fc {
  display: flex;
  flex-direction: column;
  font-size: 1em;
}
.fc,
.fc *,
.fc *:before,
.fc *:after {
  box-sizing: border-box;
}
.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}
.fc th {
  text-align: center;
}
.fc th,
.fc td {
  vertical-align: top;
  padding: 0;
}
.fc a[data-navlink] {
  cursor: pointer;
}
.fc a[data-navlink]:hover {
  text-decoration: underline;
}
.fc-direction-ltr {
  direction: ltr;
  text-align: left;
}
.fc-direction-rtl {
  direction: rtl;
  text-align: right;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
}
.fc-liquid-hack td,
.fc-liquid-hack th {
  position: relative;
}

.fc-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "fcicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fc-icon-chevron-left:before {
  content: "";
}
.fc-icon-chevron-right:before {
  content: "";
}
.fc-icon-chevrons-left:before {
  content: "";
}
.fc-icon-chevrons-right:before {
  content: "";
}
.fc-icon-minus-square:before {
  content: "";
}
.fc-icon-plus-square:before {
  content: "";
}
.fc-icon-x:before {
  content: "";
}
.fc .fc-button {
  border-radius: 0;
  overflow: visible;
  text-transform: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.fc .fc-button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.fc .fc-button {
  -webkit-appearance: button;
}
.fc .fc-button:not(:disabled) {
  cursor: pointer;
}
.fc .fc-button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.fc-icon-chevron-left:before,
.fa-chevron-left:before {
  content: "";
  font-family: "Nioicon";
}
.fc-icon-chevron-right:before,
.fa-chevron-right:before {
  content: "";
  font-family: "Nioicon";
}
.fc .fc-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 0.4em 0.65em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
}
.fc .fc-button:hover {
  text-decoration: none;
}
.fc .fc-button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}
.fc .fc-button:disabled {
  opacity: 0.65;
}
.fc .fc-button-primary {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2c3e50;
  background-color: var(--fc-button-bg-color, #2c3e50);
  border-color: #2c3e50;
  border-color: var(--fc-button-border-color, #2c3e50);
}
.fc .fc-button-primary:hover {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #1e2b37;
  background-color: var(--fc-button-hover-bg-color, #1e2b37);
  border-color: #1a252f;
  border-color: var(--fc-button-hover-border-color, #1a252f);
}
.fc .fc-button-primary:disabled {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2c3e50;
  background-color: var(--fc-button-bg-color, #2c3e50);
  border-color: #2c3e50;
  border-color: var(--fc-button-border-color, #2c3e50);
}
.fc .fc-button-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #1a252f;
  background-color: var(--fc-button-active-bg-color, #1a252f);
  border-color: #151e27;
  border-color: var(--fc-button-active-border-color, #151e27);
}
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn {
  position: relative;
  font-weight: 600;
  font-size: 0.75rem;
  background: rgba(0, 0, 0, 0);
  color: #43476b;
  border: 1px solid #d2d4e4;
  text-transform: capitalize;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn.active {
  background: #f1f2f7;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:focus {
  box-shadow: none;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-prev-button,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-next-button {
  border: 0;
  padding: 2px 8px;
  line-height: 1;
}
.fc-prev-button > .fa,
.fc-next-button > .fa {
  font-size: 1.25rem;
}
.fc .fc-button .fc-icon {
  vertical-align: middle;
  font-size: 1.5em;
}
.fc .fc-button-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.fc .fc-button-group > .fc-button {
  position: relative;
  flex: 1 1 auto;
}
.fc .fc-button-group > .fc-button:hover {
  z-index: 1;
}
.fc .fc-button-group > .fc-button:focus,
.fc .fc-button-group > .fc-button:active,
.fc .fc-button-group > .fc-button.fc-button-active {
  z-index: 1;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-direction-rtl .fc-button-group > .fc-button:not(:first-child) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-direction-rtl .fc-button-group > .fc-button:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc .fc-toolbar.fc-header-toolbar .fc-dayGridMonth-button,
.fc .fc-toolbar.fc-header-toolbar .fc-timeGridWeek-button,
.fc .fc-toolbar.fc-header-toolbar .fc-timeGridDay-button,
.fc .fc-toolbar.fc-header-toolbar .fc-listWeek-button {
  color: rgba(0, 0, 0, 0) !important;
  width: 2.125rem;
}
.fc .fc-toolbar.fc-header-toolbar .fc-dayGridMonth-button::before,
.fc .fc-toolbar.fc-header-toolbar .fc-timeGridWeek-button::before,
.fc .fc-toolbar.fc-header-toolbar .fc-timeGridDay-button::before,
.fc .fc-toolbar.fc-header-toolbar .fc-listWeek-button::before {
  color: #43476b;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fc .fc-toolbar.fc-header-toolbar .fc-dayGridMonth-button::before {
  content: "M";
}
.fc .fc-toolbar.fc-header-toolbar .fc-timeGridWeek-button::before {
  content: "W";
}
.fc .fc-toolbar.fc-header-toolbar .fc-timeGridDay-button::before {
  content: "D";
}
.fc .fc-toolbar.fc-header-toolbar .fc-listWeek-button::before {
  content: "L";
}
.fc-toolbar-chunk {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  justify-content: space-between;
  width: 100%;
}
.fc .fc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.75rem;
}
.fc .fc-toolbar.fc-footer-toolbar {
  margin-top: 1.5rem;
}
.fc .fc-toolbar-title {
  font-size: 1.125em;
  margin: 0;
  font-weight: 600;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0.75em;
}
.fc-direction-rtl .fc-toolbar > * > :not(:first-child) {
  margin-right: 0.75em;
}
.fc-direction-rtl .fc-toolbar-ltr {
  flex-direction: row-reverse;
}
.fc .fc-scroller {
  -webkit-overflow-scrolling: touch;
  position: relative;
}
.fc .fc-scroller-liquid {
  height: 100%;
}
.fc .fc-scroller-liquid-absolute {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.fc .fc-scroller-harness {
  position: relative;
  overflow: hidden;
  direction: ltr;
}
.fc .fc-scroller-harness-liquid {
  height: 100%;
}
.fc-direction-rtl .fc-scroller-harness > .fc-scroller {
  direction: rtl;
}
.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
}
.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
  width: 100%;
  table-layout: fixed;
}
.fc .fc-scrollgrid table {
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
}
.fc .fc-scrollgrid {
  border-collapse: separate;
  border-right-width: 0;
  border-bottom-width: 0;
}
.fc .fc-scrollgrid-liquid {
  height: 100%;
}
.fc .fc-scrollgrid-section {
  height: 1px;
}
.fc .fc-scrollgrid-section > td {
  height: 1px;
}
.fc .fc-scrollgrid-section table {
  height: 1px;
}
.fc .fc-scrollgrid-section-liquid > td {
  height: 100%;
}
.fc .fc-scrollgrid-section > * {
  border-top-width: 0;
  border-left-width: 0;
}
.fc .fc-scrollgrid-section-header > * {
  border-bottom-width: 0 !important;
}
.fc .fc-scrollgrid-section-footer > * {
  border-bottom-width: 1px;
}
.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  border-bottom-style: hidden;
}
.fc .table-bordered th {
  border: 1px solid #d2d4e4;
}
.fc .table-bordered td {
  border: 1px solid #d2d4e4;
}
.fc .fc-scrollgrid-section-sticky > * {
  background: #fff;
  background: var(--fc-page-bg-color, #fff);
  position: sticky;
  z-index: 3;
}
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 0;
}
.fc .fc-scrollgrid-section-footer.fc-scrollgrid-section-sticky > * {
  bottom: 0;
}
.fc .fc-scrollgrid-sticky-shim {
  height: 1px;
  margin-bottom: -1px;
}
.fc-sticky {
  position: sticky;
}
.fc .fc-view-harness {
  flex-grow: 1;
  position: relative;
}
.fc .fc-view-harness-active > .fc-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  font-size: 14px;
}
.fc .fc-bg-event,
.fc .fc-non-business,
.fc .fc-highlight {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc .fc-non-business {
  background: rgba(215, 215, 215, 0.3);
  background: var(--fc-non-business-color, rgba(215, 215, 215, 0.3));
}
.fc .fc-bg-event {
  background: #8fdf82;
  background: var(--fc-bg-event-color, rgb(143, 223, 130));
  opacity: 0.3;
  opacity: var(--fc-bg-event-opacity, 0.3);
}
.fc .fc-bg-event .fc-event-title {
  margin: 0.5em;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  font-style: italic;
}
.fc .fc-highlight {
  background: rgba(188, 232, 241, 0.3);
  background: var(--fc-highlight-color, rgba(188, 232, 241, 0.3));
}
.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: rgba(208, 208, 208, 0.3);
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
}
a.fc-event,
a.fc-event:hover {
  text-decoration: none;
}
a.fc-event-primary {
  background-color: #5f38f9 !important;
  border-color: #5f38f9 !important;
  color: #fff !important;
}
a.fc-event-primary-soft {
  background-color: #e7e1fe !important;
  border-color: #e7e1fe !important;
  color: #5f38f9 !important;
}
a.fc-event-purple {
  background-color: #8927f9 !important;
  border-color: #8927f9 !important;
  color: #fff !important;
}
a.fc-event-purple-soft {
  background-color: #eddffe !important;
  border-color: #eddffe !important;
  color: #8927f9 !important;
}
a.fc-event-pink {
  background-color: #f24a8b !important;
  border-color: #f24a8b !important;
  color: #fff !important;
}
a.fc-event-pink-soft {
  background-color: #fde4ee !important;
  border-color: #fde4ee !important;
  color: #f24a8b !important;
}
a.fc-event-secondary {
  background-color: #374151 !important;
  border-color: #374151 !important;
  color: #fff !important;
}
a.fc-event-secondary-soft {
  background-color: #e1e3e5 !important;
  border-color: #e1e3e5 !important;
  color: #374151 !important;
}
a.fc-event-success {
  background-color: #2dc58c !important;
  border-color: #2dc58c !important;
  color: #fff !important;
}
a.fc-event-success-soft {
  background-color: #e0f6ee !important;
  border-color: #e0f6ee !important;
  color: #2dc58c !important;
}
a.fc-event-info {
  background-color: #478ffc !important;
  border-color: #478ffc !important;
  color: #fff !important;
}
a.fc-event-info-soft {
  background-color: #e3eeff !important;
  border-color: #e3eeff !important;
  color: #478ffc !important;
}
a.fc-event-warning {
  background-color: #f2bc16 !important;
  border-color: #f2bc16 !important;
  color: #000 !important;
}
a.fc-event-warning-soft {
  background-color: #fdf5dc !important;
  border-color: #fdf5dc !important;
  color: #f2bc16 !important;
}
a.fc-event-danger {
  background-color: #df3c4e !important;
  border-color: #df3c4e !important;
  color: #fff !important;
}
a.fc-event-danger-soft {
  background-color: #fae2e4 !important;
  border-color: #fae2e4 !important;
  color: #df3c4e !important;
}
a.fc-event-lighter {
  background-color: #f1f2f7 !important;
  border-color: #f1f2f7 !important;
  color: #000 !important;
}
a.fc-event-lighter-soft {
  background-color: #fdfdfe !important;
  border-color: #fdfdfe !important;
  color: #f1f2f7 !important;
}
a.fc-event-light {
  background-color: #e5e7eb !important;
  border-color: #e5e7eb !important;
  color: #000 !important;
}
a.fc-event-light-soft {
  background-color: #fbfbfc !important;
  border-color: #fbfbfc !important;
  color: #e5e7eb !important;
}
a.fc-event-dark {
  background-color: #1f2937 !important;
  border-color: #1f2937 !important;
  color: #fff !important;
}
a.fc-event-dark-soft {
  background-color: #dddfe1 !important;
  border-color: #dddfe1 !important;
  color: #1f2937 !important;
}
a.fc-event-darker {
  background-color: #111827 !important;
  border-color: #111827 !important;
  color: #fff !important;
}
a.fc-event-darker-soft {
  background-color: #dbdcdf !important;
  border-color: #dbdcdf !important;
  color: #111827 !important;
}
.fc-event[href],
.fc-event.fc-event-draggable {
  cursor: pointer;
}
.fc .fc-event {
  padding: 3px 6px;
  margin: 15px 8px;
}
.fc-event .fc-event-main {
  position: relative;
  z-index: 2;
}
.fc-h-event .fc-event-main {
  color: inherit;
}
.fc-list-event-title,
.fc-list-event-time,
.fc-list-day-text,
.fc-list-day-side-text {
  font-size: 14px;
}
.fc-list-day {
  border-color: #d2d4e4;
}
.fc-event-dragging:not(.fc-event-selected) {
  opacity: 0.75;
}
.fc-event-dragging.fc-event-selected {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}
.fc-event .fc-event-resizer {
  display: none;
  position: absolute;
  z-index: 4;
}
.fc-event:hover .fc-event-resizer,
.fc-event-selected .fc-event-resizer {
  display: block;
}
.fc-event-selected .fc-event-resizer {
  border-radius: 4px;
  border-radius: calc(var(--fc-event-resizer-dot-total-width, 8px) / 2);
  border-width: 1px;
  border-width: var(--fc-event-resizer-dot-border-width, 1px);
  width: 8px;
  width: var(--fc-event-resizer-dot-total-width, 8px);
  height: 8px;
  height: var(--fc-event-resizer-dot-total-width, 8px);
  border-style: solid;
  border-color: inherit;
  background: #fff;
  background: var(--fc-page-bg-color, #fff);
}
.fc-event-selected .fc-event-resizer:before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
}
.fc-event-selected,
.fc-event:focus {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.fc-event-selected:before,
.fc-event:focus:before {
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc-event-selected:after,
.fc-event:focus:after {
  content: "";
  background: rgba(0, 0, 0, 0.25);
  background: var(--fc-event-selected-overlay-color, rgba(0, 0, 0, 0.25));
  position: absolute;
  z-index: 1;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
}
.fc-h-event {
  display: block;
}
.fc-h-event .fc-event-main-frame {
  display: flex;
}
.fc-h-event .fc-event-time {
  max-width: 100%;
  overflow: hidden;
}
.fc-h-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}
.fc-h-event .fc-event-title {
  display: inline-block;
  vertical-align: top;
  left: 0;
  right: 0;
  max-width: 100%;
  overflow: hidden;
}
.fc-h-event.fc-event-selected:before {
  top: -10px;
  bottom: -10px;
}
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}
.fc-h-event:not(.fc-event-selected) .fc-event-resizer {
  top: 0;
  bottom: 0;
  width: 8px;
  width: var(--fc-event-resizer-thickness, 8px);
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start,
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
  cursor: w-resize;
  left: -4px;
  left: calc(-0.5 * var(--fc-event-resizer-thickness, 8px));
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end,
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start {
  cursor: e-resize;
  right: -4px;
  right: calc(-0.5 * var(--fc-event-resizer-thickness, 8px));
}
.fc-h-event.fc-event-selected .fc-event-resizer {
  top: 50%;
  margin-top: -4px;
  margin-top: calc(-0.5 * var(--fc-event-resizer-dot-total-width, 8px));
}
.fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-start,
.fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-end {
  left: -4px;
  left: calc(-0.5 * var(--fc-event-resizer-dot-total-width, 8px));
}
.fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-end,
.fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-start {
  right: -4px;
  right: calc(-0.5 * var(--fc-event-resizer-dot-total-width, 8px));
}
.fc .fc-popover {
  position: absolute;
  z-index: 1069;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}
.fc .fc-popover-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}
.fc .fc-popover-title {
  margin: 0 2px;
}
.fc .fc-popover-close {
  cursor: pointer;
  opacity: 0.65;
  font-size: 1.1em;
}
.fc .fc-popover-close::before {
  content: "";
  font-family: "Nioicon";
}
.fc-theme-standard .fc-popover {
  border: 1px solid #d2d4e4;
  background: #fff;
}
.fc-theme-standard .fc-popover-header {
  background: rgba(208, 208, 208, 0.3);
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
}
:root {
  --fc-daygrid-event-dot-width: 5px;
}
.fc-daygrid-day-frame:before,
.fc-daygrid-day-events:before,
.fc-daygrid-event-harness:before {
  content: "";
  clear: both;
  display: table;
}
.fc-daygrid-day-frame:after,
.fc-daygrid-day-events:after,
.fc-daygrid-event-harness:after {
  content: "";
  clear: both;
  display: table;
}
.fc .fc-daygrid-body {
  position: relative;
  z-index: 1;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(67, 71, 107, 0.06);
}
.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 100%;
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
}
.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 0.3;
}
.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
  font-size: 14px;
}
.fc .fc-daygrid-day-events {
  margin-top: 1px;
}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  position: absolute;
  left: 0;
  right: 0;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: relative;
  min-height: 2em;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 1em;
}
.fc .fc-daygrid-event-harness {
  position: relative;
}
.fc .fc-daygrid-event-harness-abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc .fc-daygrid-bg-harness {
  position: absolute;
  top: 0;
  bottom: 0;
}
.fc .fc-daygrid-day-bg .fc-non-business {
  z-index: 1;
}
.fc .fc-daygrid-day-bg .fc-bg-event {
  z-index: 2;
}
.fc .fc-daygrid-day-bg .fc-highlight {
  z-index: 3;
}
.fc .fc-daygrid-event {
  z-index: 6;
  margin: 0.375rem 0.5rem !important;
  font-size: 12px;
  padding: 2px 6px;
  font-weight: 500;
}
.fc .fc-daygrid-event.fc-event-mirror {
  z-index: 7;
}
.fc .fc-daygrid-day-bottom {
  font-size: 0.75rem;
  padding: 2px 3px 0;
}
.fc .fc-daygrid-day-bottom:before {
  content: "";
  clear: both;
  display: table;
}
.fc .fc-daygrid-more-link {
  position: relative;
  z-index: 4;
  cursor: pointer;
}
.fc .fc-daygrid-week-number {
  position: absolute;
  z-index: 5;
  top: 0;
  padding: 2px;
  min-width: 1.5em;
  text-align: center;
  background-color: rgba(208, 208, 208, 0.3);
  background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  color: gray;
  color: var(--fc-neutral-text-color, #808080);
}
.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  padding: 10px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 6px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 6px;
}
.fc-direction-ltr .fc-daygrid-week-number {
  left: 0;
  border-radius: 0 0 3px 0;
}
.fc-direction-rtl .fc-daygrid-week-number {
  right: 0;
  border-radius: 0 0 0 3px;
}
.fc-liquid-hack .fc-daygrid-day-frame {
  position: static;
}
.fc-daygrid-event {
  position: relative;
  white-space: nowrap;
  border-radius: 3px;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
}
.fc-daygrid-block-event .fc-event-time {
  font-weight: bold;
}
.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 1px;
}
.fc-daygrid-dot-event {
  display: flex;
  align-items: center;
  padding: 2px 0;
}
.fc-daygrid-dot-event .fc-event-title {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
}
.fc-daygrid-dot-event:hover {
  background-color: rgba(95, 56, 249, 0.04);
}
.fc-daygrid-dot-event.fc-event-mirror {
  background: rgba(0, 0, 0, 0.1);
}
.fc-daygrid-dot-event.fc-event-selected:before {
  top: -10px;
  bottom: -10px;
}
.fc-daygrid-event-dot {
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 3px solid currentColor;
  border-radius: 5rem;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 3px;
}
.fc-direction-rtl .fc-daygrid-event .fc-event-time {
  margin-left: 3px;
}
.fc-v-event {
  display: block;
  border: 1px solid #5f38f9;
  background-color: #5f38f9;
}
.fc-v-event .fc-event-main {
  height: 100%;
}
.fc-v-event .fc-event-main-frame {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.fc-v-event .fc-event-time {
  flex-grow: 0;
  flex-shrink: 0;
  max-height: 100%;
  overflow: hidden;
}
.fc-v-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
}
.fc-v-event .fc-event-title {
  top: 0;
  bottom: 0;
  max-height: 100%;
  overflow: hidden;
}
.fc-v-event:not(.fc-event-start) {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.fc-v-event:not(.fc-event-end) {
  border-bottom-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-v-event.fc-event-selected:before {
  left: -10px;
  right: -10px;
}
.fc-v-event .fc-event-resizer-start {
  cursor: n-resize;
}
.fc-v-event .fc-event-resizer-end {
  cursor: s-resize;
}
.fc-v-event:not(.fc-event-selected) .fc-event-resizer {
  height: 8px;
  height: var(--fc-event-resizer-thickness, 8px);
  left: 0;
  right: 0;
}
.fc-v-event:not(.fc-event-selected) .fc-event-resizer-start {
  top: -4px;
  top: calc(var(--fc-event-resizer-thickness, 8px) / -2);
}
.fc-v-event:not(.fc-event-selected) .fc-event-resizer-end {
  bottom: -4px;
  bottom: calc(var(--fc-event-resizer-thickness, 8px) / -2);
}
.fc-v-event.fc-event-selected .fc-event-resizer {
  left: 50%;
  margin-left: -4px;
  margin-left: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
}
.fc-v-event.fc-event-selected .fc-event-resizer-start {
  top: -4px;
  top: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
}
.fc-v-event.fc-event-selected .fc-event-resizer-end {
  bottom: -4px;
  bottom: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
}
.fc .fc-timegrid .fc-daygrid-body {
  z-index: 2;
}
.fc .fc-timegrid-divider {
  padding: 0 0 2px;
}
.fc .fc-timegrid-body {
  position: relative;
  z-index: 1;
  min-height: 100%;
}
.fc .fc-timegrid-axis-chunk {
  position: relative;
}
.fc .fc-timegrid-axis-chunk > table {
  position: relative;
  z-index: 1;
}
.fc .fc-timegrid-slots {
  position: relative;
  z-index: 1;
}
.fc .fc-timegrid-slot {
  height: 2.5rem;
  border-bottom: 0;
  font-size: 14px;
}
.fc .fc-timegrid-slot:empty:before {
  content: " ";
}
.fc .fc-timegrid-slot-minor {
  border-top-style: dotted;
}
.fc .fc-timegrid-slot-label-cushion {
  display: inline-block;
  white-space: nowrap;
}
.fc .fc-timegrid-slot-label {
  vertical-align: middle;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  padding: 0 4px;
}
.fc .fc-timegrid-axis-frame-liquid {
  height: 100%;
}
.fc .fc-timegrid-axis-frame {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fc .fc-timegrid-axis-cushion {
  max-width: 60px;
  flex-shrink: 0;
}
.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: right;
}
.fc-direction-rtl .fc-timegrid-slot-label-frame {
  text-align: left;
}
.fc-liquid-hack .fc-timegrid-axis-frame-liquid {
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(67, 71, 107, 0.08);
}
.fc .fc-timegrid-col-frame {
  min-height: 100%;
  position: relative;
}
.fc-media-screen.fc-liquid-hack .fc-timegrid-col-frame {
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fc-media-screen .fc-timegrid-cols {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc-media-screen .fc-timegrid-cols > table {
  height: 100%;
}
.fc-media-screen .fc-timegrid-col-bg,
.fc-media-screen .fc-timegrid-col-events,
.fc-media-screen .fc-timegrid-now-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc .fc-timegrid-col-bg {
  z-index: 2;
}
.fc .fc-timegrid-col-bg .fc-non-business {
  z-index: 1;
}
.fc .fc-timegrid-col-bg .fc-bg-event {
  z-index: 2;
}
.fc .fc-timegrid-col-bg .fc-highlight {
  z-index: 3;
}
.fc .fc-timegrid-bg-harness {
  position: absolute;
  left: 0;
  right: 0;
}
.fc .fc-timegrid-col-events {
  z-index: 3;
}
.fc .fc-timegrid-now-indicator-container {
  bottom: 0;
  overflow: hidden;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 2.5% 0 2px;
}
.fc-direction-rtl .fc-timegrid-col-events {
  margin: 0 2px 0 2.5%;
}
.fc-timegrid-event-harness {
  position: absolute;
}
.fc-timegrid-event-harness > .fc-timegrid-event {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: 0px 0px 0px 1px #fff;
}
.fc-timegrid-event,
.fc-timegrid-more-link {
  font-size: 0.85em;
  border-radius: 0.25rem;
}
.fc-timegrid-event {
  margin-bottom: 1px;
  font-size: 0.75rem;
}
.fc-timegrid-event .fc-event-main {
  padding: 1px 1px 0;
}
.fc-timegrid-event .fc-event-time {
  white-space: nowrap;
  font-size: 0.85em;
  margin-bottom: 1px;
}
.fc-timegrid-event-short .fc-event-main-frame {
  flex-direction: row;
  overflow: hidden;
}
.fc-timegrid-event-short .fc-event-time:after {
  content: " - ";
}
.fc-timegrid-event-short .fc-event-title {
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
}
.fc-timegrid-more-link {
  position: absolute;
  z-index: 9999;
  color: inherit;
  color: var(--fc-more-link-text-color, inherit);
  background: #d0d0d0;
  background: var(--fc-more-link-bg-color, #d0d0d0);
  cursor: pointer;
  margin-bottom: 1px;
}
.fc-timegrid-more-link-inner {
  padding: 3px 2px;
  top: 0;
}
.fc-direction-ltr .fc-timegrid-more-link {
  right: 0;
}
.fc-direction-rtl .fc-timegrid-more-link {
  left: 0;
}
.fc .fc-timegrid-now-indicator-line {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  border-style: solid;
  border-color: red;
  border-color: var(--fc-now-indicator-color, red);
  border-width: 1px 0 0;
}
.fc .fc-timegrid-now-indicator-arrow {
  position: absolute;
  z-index: 4;
  margin-top: -5px;
  border-style: solid;
  border-color: red;
  border-color: var(--fc-now-indicator-color, red);
}
.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  left: 0;
  border-width: 5px 0 5px 6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}
.fc-direction-rtl .fc-timegrid-now-indicator-arrow {
  right: 0;
  border-width: 5px 6px 5px 0;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}
:root {
  --fc-list-event-dot-width: 8px;
  --fc-list-event-hover-bg-color: #f5f5f5;
}
.fc-theme-standard .fc-list {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
}
.fc .fc-list-empty {
  background-color: rgba(208, 208, 208, 0.3);
  background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc .fc-list-empty-cushion {
  margin: 5em 0;
}
.fc .fc-list-table {
  width: 100%;
  border-style: hidden;
}
.fc .fc-list-table tr > * {
  border-left: 0;
  border-right: 0;
}
.fc .fc-list-sticky .fc-list-day > * {
  position: sticky;
  top: 0;
  background: #fff;
  background: var(--fc-page-bg-color, #fff);
}
.fc .fc-list-table thead {
  position: absolute;
  left: -10000px;
}
.fc .fc-list-table tbody > tr:first-child th {
  border-top: 0;
}
.fc .fc-list-table th {
  padding: 0;
}
.fc .fc-list-table td,
.fc .fc-list-day-cushion {
  padding: 8px 14px;
}
.fc .fc-list-day-cushion:after {
  content: "";
  clear: both;
  display: table;
}
.fc-theme-standard .fc-list-day-cushion {
  background-color: rgba(208, 208, 208, 0.3);
  background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
}
.fc-direction-ltr .fc-list-day-text,
.fc-direction-rtl .fc-list-day-side-text {
  float: left;
}
.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
  float: right;
}
.fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding-right: 0;
}
.fc-direction-rtl .fc-list-table .fc-list-event-graphic {
  padding-left: 0;
}
.fc .fc-list-event.fc-event-forced-url {
  cursor: pointer;
}
.fc .fc-list-event:hover td {
  background-color: rgba(67, 71, 107, 0.02);
}
.fc .fc-list-event-graphic,
.fc .fc-list-event-time {
  white-space: nowrap;
  width: 1px;
}
.fc .fc-list-event-dot {
  display: inline-block;
  box-sizing: content-box;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  position: relative;
  top: -2px;
  background-color: #5f38f9;
  border: 2px solid #5f38f9;
}
.fc-event-primary .fc-list-event-dot {
  background-color: #5f38f9 !important;
  border-color: #5f38f9 !important;
}
.fc-event-primary-soft .fc-list-event-dot {
  background-color: #e7e1fe !important;
  border-color: #5f38f9 !important;
}
.fc-event-purple .fc-list-event-dot {
  background-color: #8927f9 !important;
  border-color: #8927f9 !important;
}
.fc-event-purple-soft .fc-list-event-dot {
  background-color: #eddffe !important;
  border-color: #8927f9 !important;
}
.fc-event-pink .fc-list-event-dot {
  background-color: #f24a8b !important;
  border-color: #f24a8b !important;
}
.fc-event-pink-soft .fc-list-event-dot {
  background-color: #fde4ee !important;
  border-color: #f24a8b !important;
}
.fc-event-secondary .fc-list-event-dot {
  background-color: #374151 !important;
  border-color: #374151 !important;
}
.fc-event-secondary-soft .fc-list-event-dot {
  background-color: #e1e3e5 !important;
  border-color: #374151 !important;
}
.fc-event-success .fc-list-event-dot {
  background-color: #2dc58c !important;
  border-color: #2dc58c !important;
}
.fc-event-success-soft .fc-list-event-dot {
  background-color: #e0f6ee !important;
  border-color: #2dc58c !important;
}
.fc-event-info .fc-list-event-dot {
  background-color: #478ffc !important;
  border-color: #478ffc !important;
}
.fc-event-info-soft .fc-list-event-dot {
  background-color: #e3eeff !important;
  border-color: #478ffc !important;
}
.fc-event-warning .fc-list-event-dot {
  background-color: #f2bc16 !important;
  border-color: #f2bc16 !important;
}
.fc-event-warning-soft .fc-list-event-dot {
  background-color: #fdf5dc !important;
  border-color: #f2bc16 !important;
}
.fc-event-danger .fc-list-event-dot {
  background-color: #df3c4e !important;
  border-color: #df3c4e !important;
}
.fc-event-danger-soft .fc-list-event-dot {
  background-color: #fae2e4 !important;
  border-color: #df3c4e !important;
}
.fc-event-lighter .fc-list-event-dot {
  background-color: #f1f2f7 !important;
  border-color: #f1f2f7 !important;
}
.fc-event-lighter-soft .fc-list-event-dot {
  background-color: #fdfdfe !important;
  border-color: #f1f2f7 !important;
}
.fc-event-light .fc-list-event-dot {
  background-color: #e5e7eb !important;
  border-color: #e5e7eb !important;
}
.fc-event-light-soft .fc-list-event-dot {
  background-color: #fbfbfc !important;
  border-color: #e5e7eb !important;
}
.fc-event-dark .fc-list-event-dot {
  background-color: #1f2937 !important;
  border-color: #1f2937 !important;
}
.fc-event-dark-soft .fc-list-event-dot {
  background-color: #dddfe1 !important;
  border-color: #1f2937 !important;
}
.fc-event-darker .fc-list-event-dot {
  background-color: #111827 !important;
  border-color: #111827 !important;
}
.fc-event-darker-soft .fc-list-event-dot {
  background-color: #dbdcdf !important;
  border-color: #111827 !important;
}
.fc .fc-list-event-title a {
  color: inherit;
  text-decoration: none;
}
.fc .fc-list-event.fc-event-forced-url:hover a {
  text-decoration: underline;
}
.fc-theme-bootstrap a:not([href]) {
  color: inherit;
}
.fc-theme-bootstrap5 a:not([href]) {
  color: inherit;
  text-decoration: inherit;
}
.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  border: 1px solid var(--bs-gray-400);
}
.fc-theme-bootstrap5 .fc-scrollgrid {
  border-right-width: 0;
  border-bottom-width: 0;
}
.fc-theme-bootstrap5-shaded {
  background-color: var(--bs-gray-200);
}
.fc-category-select .choices__item {
  display: flex;
  align-items: center;
}
.fc-select-dot {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 1rem;
  border: 1px solid #5f38f9;
  background-color: #5f38f9;
  margin-right: 0.5rem;
}
.fc-select-dot.fc-event-primary {
  background-color: #5f38f9 !important;
  border-color: #5f38f9 !important;
}
.fc-select-dot.fc-event-primary-soft {
  background-color: #e7e1fe !important;
  border-color: #5f38f9 !important;
}
.fc-select-dot.fc-event-purple {
  background-color: #8927f9 !important;
  border-color: #8927f9 !important;
}
.fc-select-dot.fc-event-purple-soft {
  background-color: #eddffe !important;
  border-color: #8927f9 !important;
}
.fc-select-dot.fc-event-pink {
  background-color: #f24a8b !important;
  border-color: #f24a8b !important;
}
.fc-select-dot.fc-event-pink-soft {
  background-color: #fde4ee !important;
  border-color: #f24a8b !important;
}
.fc-select-dot.fc-event-secondary {
  background-color: #374151 !important;
  border-color: #374151 !important;
}
.fc-select-dot.fc-event-secondary-soft {
  background-color: #e1e3e5 !important;
  border-color: #374151 !important;
}
.fc-select-dot.fc-event-success {
  background-color: #2dc58c !important;
  border-color: #2dc58c !important;
}
.fc-select-dot.fc-event-success-soft {
  background-color: #e0f6ee !important;
  border-color: #2dc58c !important;
}
.fc-select-dot.fc-event-info {
  background-color: #478ffc !important;
  border-color: #478ffc !important;
}
.fc-select-dot.fc-event-info-soft {
  background-color: #e3eeff !important;
  border-color: #478ffc !important;
}
.fc-select-dot.fc-event-warning {
  background-color: #f2bc16 !important;
  border-color: #f2bc16 !important;
}
.fc-select-dot.fc-event-warning-soft {
  background-color: #fdf5dc !important;
  border-color: #f2bc16 !important;
}
.fc-select-dot.fc-event-danger {
  background-color: #df3c4e !important;
  border-color: #df3c4e !important;
}
.fc-select-dot.fc-event-danger-soft {
  background-color: #fae2e4 !important;
  border-color: #df3c4e !important;
}
.fc-select-dot.fc-event-lighter {
  background-color: #f1f2f7 !important;
  border-color: #f1f2f7 !important;
}
.fc-select-dot.fc-event-lighter-soft {
  background-color: #fdfdfe !important;
  border-color: #f1f2f7 !important;
}
.fc-select-dot.fc-event-light {
  background-color: #e5e7eb !important;
  border-color: #e5e7eb !important;
}
.fc-select-dot.fc-event-light-soft {
  background-color: #fbfbfc !important;
  border-color: #e5e7eb !important;
}
.fc-select-dot.fc-event-dark {
  background-color: #1f2937 !important;
  border-color: #1f2937 !important;
}
.fc-select-dot.fc-event-dark-soft {
  background-color: #dddfe1 !important;
  border-color: #1f2937 !important;
}
.fc-select-dot.fc-event-darker {
  background-color: #111827 !important;
  border-color: #111827 !important;
}
.fc-select-dot.fc-event-darker-soft {
  background-color: #dbdcdf !important;
  border-color: #111827 !important;
}
@media (min-width: 768px) {
  .fc-toolbar-chunk {
    width: auto;
    justify-content: inherit;
  }
  .fc .fc-toolbar.fc-header-toolbar .fc-dayGridMonth-button,
  .fc .fc-toolbar.fc-header-toolbar .fc-timeGridWeek-button,
  .fc .fc-toolbar.fc-header-toolbar .fc-timeGridDay-button,
  .fc .fc-toolbar.fc-header-toolbar .fc-listWeek-button {
    color: #43476b !important;
    width: auto;
  }
  .fc .fc-toolbar.fc-header-toolbar .fc-dayGridMonth-button::before,
  .fc .fc-toolbar.fc-header-toolbar .fc-timeGridWeek-button::before,
  .fc .fc-toolbar.fc-header-toolbar .fc-timeGridDay-button::before,
  .fc .fc-toolbar.fc-header-toolbar .fc-listWeek-button::before {
    color: inherit;
    position: inherit;
    top: auto;
    left: auto;
    transform: none;
    content: "";
  }
}
.kanban-container {
  position: relative;
  box-sizing: border-box;
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}
.kanban-container * {
  box-sizing: border-box;
}
.kanban-board {
  position: relative;
  background: #f1f2f7;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem !important;
}
.kanban-board.disabled-board {
  opacity: 0.3;
}
.kanban-board.is-moving.gu-mirror {
  transform: rotate(3deg);
}
.kanban-board.is-moving.gu-mirror .kanban-drag {
  overflow: hidden;
  padding-right: 50px;
}
.kanban-board header {
  font-weight: 500;
  font-size: 0.938rem;
  padding: 0.75rem 1rem;
  color: #43476b;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}
.kanban-board header .kanban-title-content {
  display: flex;
  align-items: center;
}
.kanban-board header .kanban-title-content .title {
  font-weight: 600;
  margin: 0 0.5rem 0 0;
  color: #43476b;
  font-size: 0.938rem;
}
.kanban-board header .kanban-title-content .count {
  border: 1px solid #d2d4e4;
  display: inline-block;
  border-radius: 0.375rem;
  padding: 0.188rem 0.313rem;
  font-size: 0.688rem;
  font-weight: 600;
  line-height: 1;
}
.kanban-board header .kanban-title-button {
  float: right;
}
.kanban-board header.kanban-light {
  border-color: #e5e7eb;
}
.kanban-board header.kanban-primary {
  border-color: #5f38f9;
}
.kanban-board header.kanban-warning {
  border-color: #f2bc16;
}
.kanban-board header.kanban-success {
  border-color: #2dc58c;
}
.kanban-board header.kanban-danger {
  border-color: #df3c4e;
}
.kanban-board .kanban-drag {
  min-height: 200px;
  padding: 1rem 1rem;
}
.kanban-item {
  background: #fff;
  padding: 0.75rem 1rem;
  margin-bottom: 20px;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 0.875rem;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);
}
.kanban-item:hover {
  cursor: move;
}
.kanban-item:last-child {
  margin: 0;
}
.kanban-item.is-moving.gu-mirror {
  transform: rotate(3deg);
  height: auto !important;
}
.kanban-item-title .title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.938rem;
}
.kanban-custom-board {
  overflow: auto;
}
.kanban-custom-board .kanban-container {
  background-color: #fff;
  border-radius: 0.375rem;
  border: 1px solid #e8e7ec;
  width: 1240px !important;
  flex-wrap: inherit;
}
.kanban-custom-board .kanban-board {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  width: auto;
  flex: 1;
  border-radius: 0;
}
.kanban-custom-board .kanban-board:not(:last-child) {
  border-right: 1px solid #e8e7ec;
}
.kanban-custom-board .kanban-drag {
  flex-grow: 1;
}
.kanban-custom-board .kanban-item {
  border: 1px solid #e8e7ec;
}
.kanban-colored header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.kanban-colored .kanban-item {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0);
}
.kanban-colored [data-id="_inprocess"] header {
  background-color: #5f38f9;
  color: #fff;
}
.kanban-colored [data-id="_inprocess"] .kanban-item {
  background-color: rgba(95, 56, 249, 0.1);
  color: #5f38f9;
  border-color: rgba(95, 56, 249, 0.3);
}
.kanban-colored [data-id="_working"] header {
  background-color: #f2bc16;
  color: #fff;
}
.kanban-colored [data-id="_working"] .kanban-item {
  background-color: rgba(242, 188, 22, 0.1);
  color: #f2bc16;
  border-color: rgba(242, 188, 22, 0.3);
}
.kanban-colored [data-id="_done"] header {
  background-color: #2dc58c;
  color: #fff;
}
.kanban-colored [data-id="_done"] .kanban-item {
  background-color: rgba(45, 197, 140, 0.1);
  color: #2dc58c;
  border-color: rgba(45, 197, 140, 0.3);
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2 !important;
  transform: rotate(0deg) !important;
}
.drag_handler {
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;
  float: left;
  top: -3px;
  margin-right: 4px;
}
.drag_handler:hover {
  cursor: move;
}
.drag_handler_icon {
  position: relative;
  display: block;
  background: #000;
  width: 24px;
  height: 2px;
  top: 12px;
  transition: 0.5s ease-in-out;
}
.drag_handler_icon:before,
.drag_handler_icon:after {
  background: #000;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.5s ease-in-out;
}
.drag_handler_icon:before {
  top: 6px;
}
.drag_handler_icon:after {
  bottom: 6px;
}
.datepicker {
  display: none;
}
.datepicker.active {
  display: block;
}
.datepicker-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-top: 4px;
}
.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}
.datepicker-picker {
  display: inline-block;
  border-radius: 0.375rem;
  background-color: #fff;
  padding: 0.5rem;
}
.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 12px -1px rgba(67, 71, 107, 0.2);
}
.datepicker-picker span {
  display: block;
  flex: 1;
  border: 0;
  border-radius: 0.375rem;
  cursor: default;
  text-align: center;
  -webkit-touch-callout: none;
  user-select: none;
}
.datepicker-main {
  padding: 2px;
}
.datepicker-grid,
.datepicker-view .days-of-week,
.datepicker-view,
.datepicker-controls {
  display: flex;
}
.datepicker-grid {
  flex-wrap: wrap;
}
.datepicker-view .days .datepicker-cell,
.datepicker-view .dow {
  flex-basis: 14.2857142857%;
}
.datepicker-view.datepicker-grid .datepicker-cell {
  flex-basis: 25%;
}
.datepicker-cell,
.datepicker-view .week {
  height: 2.25rem;
  line-height: 2.25rem;
}
.datepicker-title {
  border-bottom: 1px solid #e8e7ec;
  background-color: rgba(0, 0, 0, 0);
  padding: 0 0.75rem 0.375rem;
  margin: 0 -0.5rem 0.125rem;
  text-align: center;
  font-weight: 500;
  color: #2e314a;
}
.datepicker-header .datepicker-controls {
  padding: 2px 2px 0.25rem;
}
.datepicker-controls .btn {
  border-color: #e5e7eb;
  background-color: #fff;
}
.datepicker-controls .btn:hover {
  border-color: #c8ccd5;
  background-color: #cfd3da;
  color: #000;
}
.datepicker-controls .btn:focus {
  border-color: #c8ccd5;
  box-shadow: 0 0 0 0.25rem rgba(195, 196, 200, 0.5);
  background-color: #cfd3da;
  color: #000;
}
.datepicker-controls .btn:disabled {
  border-color: #e5e7eb;
  background-color: #e5e7eb;
  color: #000;
}
.datepicker-controls .btn:not(:disabled):active {
  border-color: #c1c6cf;
  background-color: #c8ccd5;
  color: #000;
}
.datepicker-controls .btn:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.25rem rgba(195, 196, 200, 0.5);
}
.datepicker-header .datepicker-controls .btn {
  border-color: rgba(0, 0, 0, 0);
  font-weight: bold;
}
.datepicker-footer .datepicker-controls .btn {
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .prev-btn,
.datepicker-controls .next-btn {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  width: 2.25rem;
}
.datepicker-controls .prev-btn.disabled,
.datepicker-controls .next-btn.disabled {
  visibility: hidden;
}
.datepicker-view .dow {
  height: 1.3125rem;
  line-height: 1.3125rem;
  font-size: 0.8125rem;
  font-weight: 700;
}
.datepicker-view .week {
  width: 2.25rem;
  color: #d1d5db;
  font-size: 0.75rem;
}
@media (max-width: 22.5rem) {
  .datepicker-view .week {
    width: 1.96875rem;
  }
}
.datepicker-grid {
  width: 15.75rem;
  font-size: 0.875rem;
}
@media (max-width: 22.5rem) {
  .calendar-weeks + .days .datepicker-grid {
    width: 13.78125rem;
  }
}
.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.datepicker-cell.focused:not(.selected) {
  background-color: rgba(85, 44, 249, 0.06);
}
.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  background-color: rgba(95, 56, 249, 0.06);
  color: #5f38f9;
  font-weight: 600;
}
.datepicker-cell.disabled {
  color: #6b7280;
}
.datepicker-cell.prev:not(.disabled),
.datepicker-cell.next:not(.disabled) {
  color: color("gray");
}
.datepicker-cell.prev.selected,
.datepicker-cell.next.selected {
  color: #3807f7;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: #f1f2f7;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #e9eaf2;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: rgba(85, 44, 249, 0.06);
}
.datepicker-cell.today:not(.selected) {
  background-color: #2acc9c;
}
.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}
.datepicker-cell.today.focused:not(.selected) {
  background-color: #28c194;
}
.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: #374151;
  color: #5f38f9;
}
.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
  background-color: #323b49;
}
.datepicker-cell.range-start {
  border-radius: 0.375rem 0 0 0.375rem;
}
.datepicker-cell.range-end {
  border-radius: 0 0.375rem 0.375rem 0;
}
.datepicker-cell.range-start.range-end {
  border-radius: 0.375rem;
}
.datepicker-cell.range {
  border-radius: 0;
  background-color: #e5e7eb;
}
.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #dee0e5;
}
.datepicker-cell.range.disabled {
  color: #c8ccd5;
}
.datepicker-cell.range.focused {
  background-color: #d7dae0;
}
.datepicker-view.datepicker-grid .datepicker-cell {
  height: 4.5rem;
  line-height: 4.5rem;
}
.datepicker-input.in-edit {
  border-color: rgba(75, 31, 248, 0.65);
}
.datepicker-input.in-edit:focus,
.datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(75, 31, 248, 0.2);
}
@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
}
.dropzone,
.dropzone * {
  box-sizing: border-box;
}
.dropzone {
  min-height: 160px;
  border: 0.0625rem solid #d2d4e4;
  border-radius: 0.375rem;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone.dz-clickable * {
  cursor: default;
}
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-style: solid;
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}
.dropzone .dz-message {
  text-align: center;
  margin: 0.5rem 0 0.75rem;
}
.dropzone .dz-message .dz-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0.5rem;
  min-height: 110px;
}
.dropzone .dz-preview:hover {
  z-index: 1000;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 0.375rem;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}
.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-image-preview {
  background: #fff;
}
.dropzone .dz-preview.dz-image-preview .dz-details {
  transition: opacity 0.2s linear;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}
.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 1.5rem 0.75rem;
  text-align: center;
  color: #2e314a;
  line-height: 150%;
}
.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
  color: #24214b;
}
.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
  display: inline-flex;
  border-radius: 0.25rem;
}
.dropzone .dz-preview .dz-details .dz-filename span {
  border: 1px solid #e8e7ec;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: #fff;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
}
.dropzone .dz-preview:hover .dz-image img {
  transform: scale(1.05, 1.05);
  filter: blur(8px);
}
.dropzone .dz-preview .dz-image {
  border-radius: 0.375rem;
  overflow: hidden;
  width: 110px;
  height: 110px;
  position: relative;
  display: block;
  z-index: 10;
  border: 0.0625rem solid #d2d4e4;
}
.dropzone .dz-preview .dz-image img {
  display: block;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0.5rem;
  bottom: 0.5rem;
  font-size: 0.84375rem;
  line-height: 1;
  width: 1.125rem;
  height: 1.125rem;
  color: #fff;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0);
}
.dropzone .dz-preview .dz-success-mark:after,
.dropzone .dz-preview .dz-error-mark:after {
  font-family: "Nioicon";
}
.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  display: none;
}
.dropzone .dz-preview .dz-error-mark {
  background-color: #df3c4e;
  border-color: #ea7e8a;
}
.dropzone .dz-preview .dz-error-mark:after {
  content: "";
}
.dropzone .dz-preview .dz-success-mark {
  background-color: #2dc58c;
  border-color: #63dbae;
}
.dropzone .dz-preview .dz-success-mark:after {
  content: "";
}
.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  transition: all 0.2s linear;
}
.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  animation: pulse 6s ease infinite;
}
.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 20px;
  top: 50%;
  margin-top: -10px;
  left: 15%;
  right: 15%;
  border: 3px solid rgba(0, 0, 0, 0.8);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  overflow: hidden;
}
.dropzone .dz-preview .dz-progress .dz-upload {
  background: #fff;
  display: block;
  position: relative;
  height: 100%;
  width: 0;
  transition: width 300ms ease-in-out;
  border-radius: 17px;
}
.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}
.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 120px;
  left: 50%;
  transform: translateX(-110px);
  width: 220px;
  background: #df3c4e;
  padding: 0.5em 1em;
  color: #fff;
}
.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 104px;
  width: 0;
  height: 0;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid #df3c4e;
}
.dropzone .dz-message {
  color: #43476b;
}
.dropzone .dz-message-icon {
  color: #787c9e;
}
.dropzone .dz-message-text small,
.dropzone .dz-message-text .small {
  font-size: 70%;
  color: #787c9e;
  margin-left: 0.25rem;
}
.svgMap-wrapper,
.svgMap-container {
  position: relative;
}
.svgMap-block-zoom-notice {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  opacity: 0;
  color: #fff;
  transition: opacity 250ms;
}
.svgMap-block-zoom-notice-active .svgMap-block-zoom-notice {
  pointer-events: all;
  top: 0;
  opacity: 1;
}
.svgMap-block-zoom-notice > div {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 32px;
  transform: translateY(-50%);
  font-size: 28px;
}
@media (max-width: 900px) {
  .svgMap-block-zoom-notice > div {
    font-size: 22px;
  }
}
.svgMap-map-wrapper {
  position: relative;
  width: 100%;
  padding-top: 50%;
  overflow: hidden;
  color: #111;
}
.svgMap-map-wrapper * {
  box-sizing: border-box;
}
.svgMap-map-wrapper :focus:not(:focus-visible) {
  outline: 0;
}
.svgMap-map-wrapper .svgMap-map-image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
.svgMap-map-wrapper .svgMap-map-controls-wrapper {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  display: flex;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.svgMap-map-wrapper .svgMap-map-controls-zoom,
.svgMap-map-wrapper .svgMap-map-controls-move {
  display: flex;
  margin-right: 5px;
  overflow: hidden;
  background: #fff;
}
.svgMap-map-wrapper .svgMap-map-controls-zoom:last-child,
.svgMap-map-wrapper .svgMap-map-controls-move:last-child {
  margin-right: 0;
}
.svgMap-map-wrapper .svgMap-control-button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;
}
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:before,
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #666;
  transition: background-color 250ms;
}
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:before {
  width: 11px;
  height: 3px;
}
.svgMap-map-wrapper
  .svgMap-control-button.svgMap-zoom-button.svgMap-zoom-reset-button::before {
  width: 11px;
  height: 11px;
  background: none;
  border: 2px solid #666;
}
@media (hover: hover) {
  .svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:hover:before,
  .svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:hover:after {
    background: #111;
  }
}
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:active:before,
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:active:after {
  background: #111;
}
.svgMap-map-wrapper
  .svgMap-control-button.svgMap-zoom-button.svgMap-disabled:before,
.svgMap-map-wrapper
  .svgMap-control-button.svgMap-zoom-button.svgMap-disabled:after {
  background: #ccc;
}
.svgMap-map-wrapper
  .svgMap-control-button.svgMap-zoom-button.svgMap-zoom-reset-button.svgMap-disabled:before {
  border: 2px solid #ccc;
  background: none;
}
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-in-button {
  margin: 1px 0 1px 1px;
}
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-in-button:after {
  width: 3px;
  height: 11px;
}
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-out-button {
  margin: 1px 1px 1px 0;
}
.svgMap-map-wrapper .svgMap-map-continent-controls-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  border-radius: 2px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.svgMap-map-wrapper .svgMap-country {
  stroke: #fff;
  stroke-width: 1;
  stroke-linejoin: round;
  vector-effect: non-scaling-stroke;
  transition: fill 250ms, stroke 250ms;
}
.svgMap-map-wrapper .svgMap-country[data-link] {
  cursor: pointer;
}
@media (hover: hover) {
  .svgMap-map-wrapper .svgMap-country:hover {
    stroke: #333;
    stroke-width: 1.5;
  }
}
.svgMap-map-wrapper .svgMap-country.svgMap-active {
  stroke: #333;
  stroke-width: 1.5;
}
.svgMap-tooltip {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  border-radius: 6px;
  background: #fff;
  transform: translate(-50%, -100%);
  display: none;
  pointer-events: none;
  min-width: 60px;
}
.svgMap-tooltip.svgMap-tooltip-flipped {
  transform: translate(-50%, 0);
  border-bottom: 0;
  border-top: 1px solid #fff;
}
.svgMap-tooltip.svgMap-active {
  display: block;
}
.svgMap-tooltip .svgMap-tooltip-content-container {
  position: relative;
  padding: 10px 20px;
}
.svgMap-tooltip
  .svgMap-tooltip-content-container
  .svgMap-tooltip-flag-container {
  text-align: center;
  margin: 2px 0 5px;
}
.svgMap-tooltip
  .svgMap-tooltip-content-container
  .svgMap-tooltip-flag-container.svgMap-tooltip-flag-container-emoji {
  font-size: 50px;
  line-height: 0;
  padding: 25px 0 15px;
}
.svgMap-tooltip
  .svgMap-tooltip-content-container
  .svgMap-tooltip-flag-container
  .svgMap-tooltip-flag {
  display: block;
  margin: auto;
  width: auto;
  height: 32px;
  padding: 2px;
  border-radius: 2px;
}
.svgMap-tooltip .svgMap-tooltip-title {
  white-space: nowrap;
  font-size: 16px;
  line-height: 28px;
  padding: 0 0 8px;
  text-align: center;
  font-weight: 500;
  color: #2e314a;
}
.svgMap-tooltip .svgMap-tooltip-content {
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  color: #777;
  margin: -5px 0 0;
}
.svgMap-tooltip .svgMap-tooltip-content table {
  padding: 0;
  border-spacing: 0px;
  margin: auto;
}
.svgMap-tooltip .svgMap-tooltip-content table td {
  padding: 2px 0;
  text-align: left;
}
.svgMap-tooltip .svgMap-tooltip-content table td span {
  color: #111;
}
.svgMap-tooltip .svgMap-tooltip-content table td:first-child {
  padding-right: 10px;
  text-align: right;
}
.svgMap-tooltip .svgMap-tooltip-content table td sup {
  vertical-align: baseline;
  position: relative;
  top: -5px;
}
.svgMap-tooltip .svgMap-tooltip-content .svgMap-tooltip-no-data {
  padding: 2px 0;
  color: #777;
  font-style: italic;
}
.svgMap-tooltip .svgMap-tooltip-pointer {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  height: 10px;
  width: 30px;
}
.svgMap-tooltip .svgMap-tooltip-pointer:after {
  content: "";
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #fff;
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.svgMap-tooltip.svgMap-tooltip-flipped .svgMap-tooltip-pointer {
  bottom: auto;
  top: -10px;
  transform: translateX(-50%) scaleY(-1);
}
.js-svgmap-zoom-off .svgMap-map-controls-wrapper {
  display: none;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-horizontal {
  height: 0.675rem;
}
.noUi-horizontal .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  right: -0.875rem;
  top: -0.5375rem;
}
.noUi-vertical {
  width: 0.675rem;
  height: 200px;
}
.noUi-vertical .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  right: -0.5375rem;
  bottom: -0.875rem;
}
.noUi-target {
  background: #e5e7eb;
  border-radius: 50rem;
}
.noUi-connects {
  border-radius: 50rem;
}
.noUi-connect {
  background: #5f38f9;
}
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #d2d4e4;
  border-radius: 50rem;
  background: #fff;
  cursor: default;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 0.75rem;
  width: 1px;
  background: #e5e7eb;
  left: 0.9375rem;
  top: 0.46875rem;
}
.noUi-handle:after {
  left: auto;
  right: 0.9375rem;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 0.75rem;
  height: 1px;
  left: 0.46875rem;
  top: 0.9375rem;
}
.noUi-vertical .noUi-handle:after {
  top: auto;
  bottom: 0.9375rem;
}
[disabled] .noUi-connect {
  background: #f1f2f7;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
.noUi-marker {
  position: absolute;
  background: #ccc;
}
.noUi-marker-sub {
  background: #aaa;
}
.noUi-marker-large {
  background: #aaa;
}
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d2d4e4;
  border-radius: 0.25rem;
  background: #24214b;
  color: #fff;
  padding: 0.25rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  font-size: 0.875rem;
  opacity: 0;
  transition: 0.3s ease;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}
.noUi-active .noUi-tooltip,
.noUi-handle:hover .noUi-tooltip {
  opacity: 1;
}
.nk-body {
  outline: none;
}
.nk-app-root {
  outline: none;
}
.nk-main {
  position: relative;
}
.nk-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.nk-content {
  padding: 2rem 0.5rem;
}
.nk-content-stretch {
  padding: 0 !important;
}
.nk-content-stretch .nk-content-body {
  overflow: hidden;
  height: calc(100vh - 3.75rem - 5.5rem - 2px);
}
@media (min-width: 576px) {
  .nk-content {
    padding: 2.5rem 1rem;
  }
  .nk-content-stretch .nk-content-body {
    height: calc(100vh - 3.75rem - 3.5rem - 2px);
  }
}
.logo-wrap {
  display: inline-flex;
  position: relative;
  height: 20px;
}
.logo-link {
  display: inline-flex;
}
.logo-svg {
  height: 100%;
  --logo-text-color: #24214b;
  --logo-sym-accent-1: #5f38f9;
  --logo-sym-accent-2: #478ffc;
  --logo-sym-secondary-1: #0080ff;
  --logo-sym-secondary-2: #17c5eb;
}
.is-theme .logo-svg,
.is-dark .logo-svg {
  --logo-text-color: #fff;
}
.logo-text {
  color: var(--logo-text-color);
  transition: 0.3s ease;
}
.is-compact:not(:hover) .logo-text {
  display: none;
}
.logo-img {
  height: 100%;
}
.logo-img:not(:first-child) {
  position: absolute;
  left: 0;
}
.logo-dark {
  opacity: 1;
}
.is-theme .logo-dark,
.is-dark .logo-dark {
  opacity: 0;
}
.is-compact:not(:hover) .logo-dark {
  opacity: 0;
}
.logo-light {
  opacity: 0;
}
.is-theme .logo-light,
.is-dark .logo-light {
  opacity: 1;
}
.is-compact:not(:hover) .logo-light {
  opacity: 0;
}
.logo-icon {
  opacity: 0;
}
.is-compact:not(:hover) .logo-icon {
  opacity: 1;
}
@media (min-width: 576px) {
  .logo-wrap {
    height: 34px;
    margin: 0.125rem 0;
  }
}
.nk-menu {
  padding: 1rem 0;
}
.nk-menu-heading {
  padding: 0.75rem 1.75rem;
}
.nk-menu-heading:not(:first-child) {
  padding-top: 2rem;
}
.nk-menu-heading .overline-title {
  white-space: nowrap;
}
.nk-menu-link {
  position: relative;
  display: flex;
  align-items: center;
  color: #2e314a;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0.625rem 1.75rem;
  background-color: rgba(0, 0, 0, 0);
}
.nk-menu-link::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}
.nk-menu-link:hover {
  color: #9b82fb;
}
.active > .nk-menu-link {
  color: #9b82fb;
  background-color: rgba(95, 56, 249, 0.04);
}
/* .active > .nk-menu-link::after {
  background-color: #9b82fb;
} */
.active > .nk-menu-link .icon {
  color: #9b82fb;
}
.nk-menu-icon {
  width: 1.125rem;
  display: inline-flex;
  justify-content: center;
  margin-right: 0.875rem;
}
.nk-menu-icon .icon {
  transition: color 0.3s;
  font-size: 1.125rem;
  color: #2e314a;
}
.nk-menu-text {
  flex-grow: 1;
  display: inline-block;
  white-space: nowrap;
}
.nk-menu-item .nk-menu-sub {
  margin-left: 1.975rem;
}
.nk-menu-item .nk-menu-sub .nk-menu-sub {
  margin-left: 1.325rem;
}
.nk-menu-sub .nk-menu-text {
  padding-left: 0;
}
.nk-menu-sub .nk-menu-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.813rem;
  font-weight: 400;
  color: #43476b;
  background-color: rgba(0, 0, 0, 0);
}
.nk-menu-sub .nk-menu-link::after {
  display: none;
}
.nk-menu-sub .nk-menu-link:hover {
  color: #9b82fb;
}
.nk-menu-sub .nk-menu-sub {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-left: 1px solid #d2d4e4;
}
.nk-menu-sub .active > .nk-menu-link {
  color: #9b82fb;
}
.nk-menu-toggle {
  position: relative;
}
.nk-menu-toggle::before {
  position: absolute;
  font-family: "Nioicon";
  top: 50%;
  content: unset;
  right: 1.5rem;
  transform: translateY(-50%);
  font-size: 18px;
  transition: 0.4s;
}
.has-sub.active > .nk-menu-toggle::before {
  transform: translateY(-50%) rotate(-180deg);
}
.nk-menu-toggle + .nk-menu-sub {
  display: none;
}
.is-theme .nk-menu .nk-menu-icon .icon {
  color: #f8f8f9;
}
.is-theme .nk-menu .nk-menu-link {
  color: #f8f8f9;
  opacity: 0.9;
}
.is-theme .nk-menu .nk-menu-link:hover {
  opacity: 1;
  color: #fff;
}
.is-theme .nk-menu .nk-menu-heading::before {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.is-theme .nk-menu .overline-title {
  color: #f8f8f9;
  opacity: 0.6;
}
.is-theme .nk-menu .nk-menu-sub .nk-menu-sub {
  border-left-color: rgba(255, 255, 255, 0.1);
}
.is-theme .nk-menu .nk-menu-sub .active > .nk-menu-link {
  color: #9b82fb;
  opacity: 1;
}
.nk-nav {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  max-height: 100%;
  overflow: auto;
}
.nk-nav-item {
  position: relative;
  width: 100%;
}
.nk-nav-link {
  display: flex;
  align-items: center;
  color: #2e314a;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0.625rem 1.25rem;
  background-color: rgba(0, 0, 0, 0);
}
.nk-nav-link:hover {
  color: #5f38f9;
}
.nk-nav-toggle:after {
  position: relative;
  content: "";
  font-family: "Nioicon";
  margin-left: auto;
  right: -0.25rem;
  top: 1px;
  transition: transform 0.3s linear;
}
.nk-nav-heading {
  color: #2e314a;
}
.nk-nav-heading:not(:first-child) {
  padding-top: 1rem;
}
.nk-nav-sub {
  display: none;
  padding: 0.5rem 0;
  z-index: 1;
  box-shadow: 0 -1px 0 0 #e8e7ec, 0 1px 0 0 #e8e7ec;
  margin: 0.5rem 0;
  overflow: hidden;
}
.nk-nav-sub .nk-nav-link {
  padding: 0.5rem 1.5rem;
}
.nk-nav-sub .nk-nav-sub {
  border-left: 1px solid #e8e7ec;
  margin-left: 1.5rem;
  box-shadow: none;
}
.nk-nav-mega {
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  margin: -0.75rem -0.75rem;
}
.nk-nav-col {
  padding: 0.75rem 0.75rem;
}
.is-theme .nk-nav .nk-nav-link {
  color: #f8f8f9;
  opacity: 0.9;
}
.is-theme .nk-nav .nk-nav-link:hover {
  opacity: 1;
  color: #fff;
}
@media (min-width: 576px) {
  [data-navbar-collapse="sm"] .nk-nav {
    flex-direction: row;
    padding: 0;
    margin: 0 -0.75rem;
    overflow: visible;
  }
  [data-navbar-collapse="sm"] .nk-nav-item {
    padding: 0 0.75rem;
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  [data-navbar-collapse="sm"] .nk-nav-item:hover > .nk-nav-sub {
    opacity: 1;
    visibility: visible;
  }
  [data-navbar-collapse="sm"] .nk-nav-link {
    padding: 0 0;
  }
  [data-navbar-collapse="sm"] .nk-nav-text {
    white-space: nowrap;
  }
  [data-navbar-collapse="sm"] .nk-nav-sub {
    display: block !important;
    position: absolute;
    top: calc(100% + 0.5rem);
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    transition: opacity 0.3s ease;
    min-width: 200px;
    box-shadow: 0 4px 13px -2px rgba(67, 71, 107, 0.15);
    border-radius: 0 0 0.375rem 0.375rem;
    max-height: calc(100vh - 3.75rem - 1rem);
    overflow: visible;
  }
  [data-navbar-collapse="sm"] .nk-nav-sub-lg {
    min-width: 280px;
  }
  [data-navbar-collapse="sm"] .nk-nav-sub .nk-nav-sub {
    left: 100%;
    top: 0;
    border-left: 0;
  }
  [data-navbar-collapse="sm"] .nk-nav-mega {
    flex-direction: row;
    max-height: inherit;
    overflow: auto;
    max-width: calc(100vw - 280px - 1rem);
  }
  [data-navbar-collapse="sm"] .nk-nav-mega-lg {
    width: 882px;
  }
  .sidebar-compact [data-navbar-collapse="sm"] .nk-nav-mega {
    max-width: calc(100vw - 4.625rem - 1rem);
  }
  [data-navbar-collapse="sm"] .nk-nav-col {
    flex-grow: 1;
    min-width: 160px;
  }
  [data-navbar-collapse="sm"] .nk-nav-media {
    flex-shrink: 1;
  }
  [data-navbar-collapse="sm"] .nk-nav > .nk-nav-item {
    height: 2.75rem;
  }
  [data-navbar-collapse="sm"] .nk-nav > .nk-nav-item > .nk-nav-link {
    margin: -0.5rem 0;
    height: calc(100% + 1rem);
  }
  [data-navbar-collapse="sm"] .nk-nav > .nk-nav-item .nk-nav-sub .nk-nav-item {
    padding-left: 0;
    padding-right: 0;
  }
  [data-navbar-collapse="sm"] .nk-nav > .nk-nav-item .nk-nav-sub .nk-nav-link {
    padding: 0.5rem 1.5rem;
    flex-grow: 1;
  }
  [data-navbar-collapse="sm"]
    .nk-nav
    > .nk-nav-item
    .nk-nav-sub
    .nk-nav-link::after {
    transform: rotate(-90deg);
  }
}
@media (min-width: 768px) {
  [data-navbar-collapse="md"] .nk-nav {
    flex-direction: row;
    padding: 0;
    margin: 0 -0.75rem;
    overflow: visible;
  }
  [data-navbar-collapse="md"] .nk-nav-item {
    padding: 0 0.75rem;
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  [data-navbar-collapse="md"] .nk-nav-item:hover > .nk-nav-sub {
    opacity: 1;
    visibility: visible;
  }
  [data-navbar-collapse="md"] .nk-nav-link {
    padding: 0 0;
  }
  [data-navbar-collapse="md"] .nk-nav-text {
    white-space: nowrap;
  }
  [data-navbar-collapse="md"] .nk-nav-sub {
    display: block !important;
    position: absolute;
    top: calc(100% + 0.5rem);
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    transition: opacity 0.3s ease;
    min-width: 200px;
    box-shadow: 0 4px 13px -2px rgba(67, 71, 107, 0.15);
    border-radius: 0 0 0.375rem 0.375rem;
    max-height: calc(100vh - 3.75rem - 1rem);
    overflow: visible;
  }
  [data-navbar-collapse="md"] .nk-nav-sub-lg {
    min-width: 280px;
  }
  [data-navbar-collapse="md"] .nk-nav-sub .nk-nav-sub {
    left: 100%;
    top: 0;
    border-left: 0;
  }
  [data-navbar-collapse="md"] .nk-nav-mega {
    flex-direction: row;
    max-height: inherit;
    overflow: auto;
    max-width: calc(100vw - 280px - 1rem);
  }
  [data-navbar-collapse="md"] .nk-nav-mega-lg {
    width: 882px;
  }
  .sidebar-compact [data-navbar-collapse="md"] .nk-nav-mega {
    max-width: calc(100vw - 4.625rem - 1rem);
  }
  [data-navbar-collapse="md"] .nk-nav-col {
    flex-grow: 1;
    min-width: 160px;
  }
  [data-navbar-collapse="md"] .nk-nav-media {
    flex-shrink: 1;
  }
  [data-navbar-collapse="md"] .nk-nav > .nk-nav-item {
    height: 2.75rem;
  }
  [data-navbar-collapse="md"] .nk-nav > .nk-nav-item > .nk-nav-link {
    margin: -0.5rem 0;
    height: calc(100% + 1rem);
  }
  [data-navbar-collapse="md"] .nk-nav > .nk-nav-item .nk-nav-sub .nk-nav-item {
    padding-left: 0;
    padding-right: 0;
  }
  [data-navbar-collapse="md"] .nk-nav > .nk-nav-item .nk-nav-sub .nk-nav-link {
    padding: 0.5rem 1.5rem;
    flex-grow: 1;
  }
  [data-navbar-collapse="md"]
    .nk-nav
    > .nk-nav-item
    .nk-nav-sub
    .nk-nav-link::after {
    transform: rotate(-90deg);
  }
}
@media (min-width: 992px) {
  [data-navbar-collapse="lg"] .nk-nav {
    flex-direction: row;
    padding: 0;
    margin: 0 -0.75rem;
    overflow: visible;
  }
  [data-navbar-collapse="lg"] .nk-nav-item {
    padding: 0 0.75rem;
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  [data-navbar-collapse="lg"] .nk-nav-item:hover > .nk-nav-sub {
    opacity: 1;
    visibility: visible;
  }
  [data-navbar-collapse="lg"] .nk-nav-link {
    padding: 0 0;
  }
  [data-navbar-collapse="lg"] .nk-nav-text {
    white-space: nowrap;
  }
  [data-navbar-collapse="lg"] .nk-nav-sub {
    display: block !important;
    position: absolute;
    top: calc(100% + 0.5rem);
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    transition: opacity 0.3s ease;
    min-width: 200px;
    box-shadow: 0 4px 13px -2px rgba(67, 71, 107, 0.15);
    border-radius: 0 0 0.375rem 0.375rem;
    max-height: calc(100vh - 3.75rem - 1rem);
    overflow: visible;
  }
  [data-navbar-collapse="lg"] .nk-nav-sub-lg {
    min-width: 280px;
  }
  [data-navbar-collapse="lg"] .nk-nav-sub .nk-nav-sub {
    left: 100%;
    top: 0;
    border-left: 0;
  }
  [data-navbar-collapse="lg"] .nk-nav-mega {
    flex-direction: row;
    max-height: inherit;
    overflow: auto;
    max-width: calc(100vw - 280px - 1rem);
  }
  [data-navbar-collapse="lg"] .nk-nav-mega-lg {
    width: 882px;
  }
  .sidebar-compact [data-navbar-collapse="lg"] .nk-nav-mega {
    max-width: calc(100vw - 4.625rem - 1rem);
  }
  [data-navbar-collapse="lg"] .nk-nav-col {
    flex-grow: 1;
    min-width: 160px;
  }
  [data-navbar-collapse="lg"] .nk-nav-media {
    flex-shrink: 1;
  }
  [data-navbar-collapse="lg"] .nk-nav > .nk-nav-item {
    height: 2.75rem;
  }
  [data-navbar-collapse="lg"] .nk-nav > .nk-nav-item > .nk-nav-link {
    margin: -0.5rem 0;
    height: calc(100% + 1rem);
  }
  [data-navbar-collapse="lg"] .nk-nav > .nk-nav-item .nk-nav-sub .nk-nav-item {
    padding-left: 0;
    padding-right: 0;
  }
  [data-navbar-collapse="lg"] .nk-nav > .nk-nav-item .nk-nav-sub .nk-nav-link {
    padding: 0.5rem 1.5rem;
    flex-grow: 1;
  }
  [data-navbar-collapse="lg"]
    .nk-nav
    > .nk-nav-item
    .nk-nav-sub
    .nk-nav-link::after {
    transform: rotate(-90deg);
  }
}
@media (min-width: 1200px) {
  [data-navbar-collapse="xl"] .nk-nav {
    flex-direction: row;
    padding: 0;
    margin: 0 -0.75rem;
    overflow: visible;
  }
  [data-navbar-collapse="xl"] .nk-nav-item {
    padding: 0 0.75rem;
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  [data-navbar-collapse="xl"] .nk-nav-item:hover > .nk-nav-sub {
    opacity: 1;
    visibility: visible;
  }
  [data-navbar-collapse="xl"] .nk-nav-link {
    padding: 0 0;
  }
  [data-navbar-collapse="xl"] .nk-nav-text {
    white-space: nowrap;
  }
  [data-navbar-collapse="xl"] .nk-nav-sub {
    display: block !important;
    position: absolute;
    top: calc(100% + 0.5rem);
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    transition: opacity 0.3s ease;
    min-width: 200px;
    box-shadow: 0 4px 13px -2px rgba(67, 71, 107, 0.15);
    border-radius: 0 0 0.375rem 0.375rem;
    max-height: calc(100vh - 3.75rem - 1rem);
    overflow: visible;
  }
  [data-navbar-collapse="xl"] .nk-nav-sub-lg {
    min-width: 280px;
  }
  [data-navbar-collapse="xl"] .nk-nav-sub .nk-nav-sub {
    left: 100%;
    top: 0;
    border-left: 0;
  }
  [data-navbar-collapse="xl"] .nk-nav-mega {
    flex-direction: row;
    max-height: inherit;
    overflow: auto;
    max-width: calc(100vw - 280px - 1rem);
  }
  [data-navbar-collapse="xl"] .nk-nav-mega-lg {
    width: 882px;
  }
  .sidebar-compact [data-navbar-collapse="xl"] .nk-nav-mega {
    max-width: calc(100vw - 4.625rem - 1rem);
  }
  [data-navbar-collapse="xl"] .nk-nav-col {
    flex-grow: 1;
    min-width: 160px;
  }
  [data-navbar-collapse="xl"] .nk-nav-media {
    flex-shrink: 1;
  }
  [data-navbar-collapse="xl"] .nk-nav > .nk-nav-item {
    height: 2.75rem;
  }
  [data-navbar-collapse="xl"] .nk-nav > .nk-nav-item > .nk-nav-link {
    margin: -0.5rem 0;
    height: calc(100% + 1rem);
  }
  [data-navbar-collapse="xl"] .nk-nav > .nk-nav-item .nk-nav-sub .nk-nav-item {
    padding-left: 0;
    padding-right: 0;
  }
  [data-navbar-collapse="xl"] .nk-nav > .nk-nav-item .nk-nav-sub .nk-nav-link {
    padding: 0.5rem 1.5rem;
    flex-grow: 1;
  }
  [data-navbar-collapse="xl"]
    .nk-nav
    > .nk-nav-item
    .nk-nav-sub
    .nk-nav-link::after {
    transform: rotate(-90deg);
  }
}
.nk-header {
  z-index: 1024;
  border-bottom: 1px solid #e8e7ec;
 
  padding: 0.5rem 0.5rem;
  box-shadow: 0 1px 0 rgba(232, 231, 236, 0.5);
  min-height: 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nk-header-logo {
  display: flex;
  align-items: center;
}
.nk-header-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nk-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.4s;
}
.nk-header-fixed + .nk-content {
  margin-top: 3.75rem;
}
.nk-header.is-theme {
  background-color: #1c047b;
}
@media (min-width: 576px) {
  .nk-header {
    padding: 0.5rem 1rem;
  }
}
.navbar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(31, 41, 55, 0.4);
  z-index: 1025;
  animation: overlay-fade-out 400ms ease 1;
}
.navbar-shown {
  overflow: hidden;
}
.navbar-shown .navbar-overlay {
  animation: overlay-fade-in 400ms ease 1;
}
@media (min-width: 576px) {
  [data-sidebar-collapse="sm"] .nk-header-logo {
    display: none;
  }
}
@media (min-width: 768px) {
  [data-sidebar-collapse="md"] .nk-header-logo {
    display: none;
  }
}
@media (min-width: 992px) {
  [data-sidebar-collapse="lg"] .nk-header-logo {
    display: none;
  }
}
@media (min-width: 1200px) {
  [data-sidebar-collapse="xl"] .nk-header-logo {
    display: none;
  }
}
.nk-navbar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1025;
  background: #fff;
  width: 280px;
  transform: translateX(100%);
  height: 100vh;
}
.nk-navbar.navbar-active {
  transform: none;
}
.nk-navbar.navbar-mobile {
  transition: 0.4s ease;
}
@media (min-width: 576px) {
  [data-navbar-collapse="sm"] .nk-navbar {
    position: static;
    width: auto;
    transform: none;
    height: auto;
    background-color: rgba(0, 0, 0, 0);
  }
  [data-navbar-collapse="sm"] .nk-navbar-toggle {
    display: none;
  }
}
@media (min-width: 768px) {
  [data-navbar-collapse="md"] .nk-navbar {
    position: static;
    width: auto;
    transform: none;
    height: auto;
    background-color: rgba(0, 0, 0, 0);
  }
  [data-navbar-collapse="md"] .nk-navbar-toggle {
    display: none;
  }
}
@media (min-width: 992px) {
  [data-navbar-collapse="lg"] .nk-navbar {
    position: static;
    width: auto;
    transform: none;
    height: auto;
    background-color: rgba(0, 0, 0, 0);
  }
  [data-navbar-collapse="lg"] .nk-navbar-toggle {
    display: none;
  }
}
@media (min-width: 1200px) {
  [data-navbar-collapse="xl"] .nk-navbar {
    position: static;
    width: auto;
    transform: none;
    height: auto;
    background-color: rgba(0, 0, 0, 0);
  }
  [data-navbar-collapse="xl"] .nk-navbar-toggle {
    display: none;
  }
}
.nk-sidebar {
  position: absolute;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  width: 280px;
  /* transform: translateX(-100%); */
  transition: 0.4s;
  background-color: #fff;
  z-index: 1026;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.nk-sidebar.sidebar-active {
  transform: none;
}
.nk-sidebar + .nk-wrap {
  transition: 0.4s;
}
.nk-sidebar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.nk-sidebar .nk-menu-text,
.nk-sidebar .nk-sidebar-brand {
  transition: 0.4s;
}
.nk-sidebar.active {
  transform: translateX(0);
}
.nk-sidebar-fixed {
  position: fixed;
  max-height: 100vh;
}
.nk-sidebar-head {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.375rem;
  overflow: hidden;
  width: 280px;
}
.nk-sidebar-body {
  position: relative;
  height: 100%;
}
.nk-sidebar-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}
.nk-sidebar-content .nk-sidebar-menu[data-simplebar] {
  height: 100%;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(31, 41, 55, 0.4);
  z-index: 1025;
  animation: overlay-fade-out 400ms ease 1;
}
.sidebar-shown {
  overflow: hidden;
}
.sidebar-shown .sidebar-overlay {
  animation: overlay-fade-in 400ms ease 1;
}
@keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes overlay-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (min-width: 576px) {
  [data-sidebar-collapse="sm"] .nk-sidebar {
    transform: translateX(0);
  }
  [data-sidebar-collapse="sm"] .nk-sidebar-toggle {
    display: none;
  }
  [data-sidebar-collapse="sm"] .nk-sidebar + .nk-wrap {
    padding-left: 280px;
  }
  [data-sidebar-collapse="sm"] .nk-sidebar + .nk-wrap > .nk-header-fixed {
    left: 280px;
  }
}
@media (min-width: 768px) {
  [data-sidebar-collapse="md"] .nk-sidebar {
    transform: translateX(0);
  }
  [data-sidebar-collapse="md"] .nk-sidebar-toggle {
    display: none;
  }
  [data-sidebar-collapse="md"] .nk-sidebar + .nk-wrap {
    padding-left: 280px;
  }
  [data-sidebar-collapse="md"] .nk-sidebar + .nk-wrap > .nk-header-fixed {
    left: 280px;
  }
}
@media (min-width: 992px) {
  [data-sidebar-collapse="lg"] .nk-sidebar {
    transform: translateX(0);
  }
  [data-sidebar-collapse="lg"] .nk-sidebar-toggle {
    display: none;
  }
  [data-sidebar-collapse="lg"] .nk-sidebar + .nk-wrap {
    padding-left: 280px;
  }
  [data-sidebar-collapse="lg"] .nk-sidebar + .nk-wrap > .nk-header-fixed {
    left: 280px;
  }
}
@media (min-width: 1200px) {
  [data-sidebar-collapse="xl"] .nk-sidebar {
    transform: translateX(0);
  }
  [data-sidebar-collapse="xl"] .nk-sidebar-toggle {
    display: none;
  }
  [data-sidebar-collapse="xl"] .nk-sidebar + .nk-wrap {
    padding-left: 280px;
  }
  [data-sidebar-collapse="xl"] .nk-sidebar + .nk-wrap > .nk-header-fixed {
    left: 280px;
  }
}
.nk-compact-toggle {
  display: none;
}
@media (min-width: 576px) {
  [data-sidebar-collapse="sm"] .nk-compact-toggle {
    display: block;
  }
  [data-sidebar-collapse="sm"] .is-compact:not(:hover) .nk-compact-toggle {
    display: none;
  }
  [data-sidebar-collapse="sm"] .nk-sidebar.is-compact:not(:hover) {
    width: 4.625rem;
  }
  [data-sidebar-collapse="sm"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-sidebar-head {
    width: 4.625rem;
  }
  [data-sidebar-collapse="sm"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-text {
    opacity: 0;
  }
  [data-sidebar-collapse="sm"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-toggle::before {
    opacity: 0;
  }
  [data-sidebar-collapse="sm"] .nk-sidebar.is-compact:not(:hover) .nk-menu-sub {
    display: none !important;
  }
  [data-sidebar-collapse="sm"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading {
    position: relative;
  }
  [data-sidebar-collapse="sm"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading
    .overline-title {
    opacity: 0;
  }
  [data-sidebar-collapse="sm"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading:not(:first-child)::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    top: 50%;
    background-color: #d2d4e4;
  }
  [data-sidebar-collapse="sm"]
    .nk-sidebar.is-compact:not(:hover)
    .compact-toggle {
    opacity: 0;
  }
  [data-sidebar-collapse="sm"] .nk-sidebar.is-compact + .nk-wrap {
    padding-left: 4.625rem;
  }
  [data-sidebar-collapse="sm"]
    .nk-sidebar.is-compact
    + .nk-wrap
    > .nk-header-fixed {
    left: 4.625rem;
  }
}
@media (min-width: 768px) {
  [data-sidebar-collapse="md"] .nk-compact-toggle {
    display: block;
  }
  [data-sidebar-collapse="md"] .is-compact:not(:hover) .nk-compact-toggle {
    display: none;
  }
  [data-sidebar-collapse="md"] .nk-sidebar.is-compact:not(:hover) {
    width: 4.625rem;
  }
  [data-sidebar-collapse="md"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-sidebar-head {
    width: 4.625rem;
  }
  [data-sidebar-collapse="md"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-text {
    opacity: 0;
  }
  [data-sidebar-collapse="md"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-toggle::before {
    opacity: 0;
  }
  [data-sidebar-collapse="md"] .nk-sidebar.is-compact:not(:hover) .nk-menu-sub {
    display: none !important;
  }
  [data-sidebar-collapse="md"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading {
    position: relative;
  }
  [data-sidebar-collapse="md"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading
    .overline-title {
    opacity: 0;
  }
  [data-sidebar-collapse="md"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading:not(:first-child)::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    top: 50%;
    background-color: #d2d4e4;
  }
  [data-sidebar-collapse="md"]
    .nk-sidebar.is-compact:not(:hover)
    .compact-toggle {
    opacity: 0;
  }
  [data-sidebar-collapse="md"] .nk-sidebar.is-compact + .nk-wrap {
    padding-left: 4.625rem;
  }
  [data-sidebar-collapse="md"]
    .nk-sidebar.is-compact
    + .nk-wrap
    > .nk-header-fixed {
    left: 4.625rem;
  }
}
@media (min-width: 992px) {
  [data-sidebar-collapse="lg"] .nk-compact-toggle {
    display: block;
  }
  [data-sidebar-collapse="lg"] .is-compact:not(:hover) .nk-compact-toggle {
    display: none;
  }
  [data-sidebar-collapse="lg"] .nk-sidebar.is-compact:not(:hover) {
    width: 4.625rem;
  }
  [data-sidebar-collapse="lg"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-sidebar-head {
    width: 4.625rem;
  }
  [data-sidebar-collapse="lg"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-text {
    opacity: 0;
  }
  [data-sidebar-collapse="lg"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-toggle::before {
    opacity: 0;
  }
  [data-sidebar-collapse="lg"] .nk-sidebar.is-compact:not(:hover) .nk-menu-sub {
    display: none !important;
  }
  [data-sidebar-collapse="lg"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading {
    position: relative;
  }
  [data-sidebar-collapse="lg"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading
    .overline-title {
    opacity: 0;
  }
  [data-sidebar-collapse="lg"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading:not(:first-child)::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    top: 50%;
    background-color: #d2d4e4;
  }
  [data-sidebar-collapse="lg"]
    .nk-sidebar.is-compact:not(:hover)
    .compact-toggle {
    opacity: 0;
  }
  [data-sidebar-collapse="lg"] .nk-sidebar.is-compact + .nk-wrap {
    padding-left: 4.625rem;
  }
  [data-sidebar-collapse="lg"]
    .nk-sidebar.is-compact
    + .nk-wrap
    > .nk-header-fixed {
    left: 4.625rem;
  }
}
@media (min-width: 1200px) {
  [data-sidebar-collapse="xl"] .nk-compact-toggle {
    display: block;
  }
  [data-sidebar-collapse="xl"] .is-compact:not(:hover) .nk-compact-toggle {
    display: none;
  }
  [data-sidebar-collapse="xl"] .nk-sidebar.is-compact:not(:hover) {
    width: 4.625rem;
  }
  [data-sidebar-collapse="xl"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-sidebar-head {
    width: 4.625rem;
  }
  [data-sidebar-collapse="xl"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-text {
    opacity: 0;
  }
  [data-sidebar-collapse="xl"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-toggle::before {
    opacity: 0;
  }
  [data-sidebar-collapse="xl"] .nk-sidebar.is-compact:not(:hover) .nk-menu-sub {
    display: none !important;
  }
  [data-sidebar-collapse="xl"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading {
    position: relative;
  }
  [data-sidebar-collapse="xl"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading
    .overline-title {
    opacity: 0;
  }
  [data-sidebar-collapse="xl"]
    .nk-sidebar.is-compact:not(:hover)
    .nk-menu-heading:not(:first-child)::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    top: 50%;
    background-color: #d2d4e4;
  }
  [data-sidebar-collapse="xl"]
    .nk-sidebar.is-compact:not(:hover)
    .compact-toggle {
    opacity: 0;
  }
  [data-sidebar-collapse="xl"] .nk-sidebar.is-compact + .nk-wrap {
    padding-left: 4.625rem;
  }
  [data-sidebar-collapse="xl"]
    .nk-sidebar.is-compact
    + .nk-wrap
    > .nk-header-fixed {
    left: 4.625rem;
  }
}
.nk-footer {
  background-color: #fff;
  margin-top: auto;
  border-top: 1px solid #e8e7ec;
  padding: 0.5rem 0.5rem;
}
.nk-footer-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.nk-footer-copyright {
  font-size: 0.875rem;
  padding-top: 0.5rem;
}
@media (min-width: 576px) {
  .nk-footer-wrap {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .nk-footer-copyright {
    padding-top: 0;
  }
}
.nk-aside-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem 0.75rem;
}
.nk-aside-heading > * {
  display: flex;
}
.nk-aside-content {
  padding: 0 1.5rem;
}
canvas {
  width: 100%;
  max-width: 100%;
}
a {
  transition: color 0.2s, background-color 0.2s, border 0.2s, box-shadow 0.2s;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}
h1:where(:last-child),
h2:where(:last-child),
h3:where(:last-child),
h4:where(:last-child),
h5:where(:last-child),
h6:where(:last-child),
.h1:where(:last-child),
.h2:where(:last-child),
.h3:where(:last-child),
.h4:where(:last-child),
.h5:where(:last-child),
.h6:where(:last-child),
p:where(:last-child) {
  margin-bottom: 0;
}
.is-theme h1,
.is-dark h1,
.is-theme h2,
.is-dark h2,
.is-theme h3,
.is-dark h3,
.is-theme h4,
.is-dark h4,
.is-theme h5,
.is-dark h5,
.is-theme h6,
.is-dark h6,
.is-theme .h1,
.is-dark .h1,
.is-theme .h2,
.is-dark .h2,
.is-theme .h3,
.is-dark .h3,
.is-theme .h4,
.is-dark .h4,
.is-theme .h5,
.is-dark .h5,
.is-theme .h6,
.is-dark .h6,
.is-theme p,
.is-dark p {
  color: #fff;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}
.fit-cover {
  object-fit: cover;
}
.gap {
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.gap > * {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.bg-primary-dark {
  background-color: #18036c;
}
@media (min-width: 575px) {
  .wide-xs {
    max-width: 480px !important;
  }
}
@media (min-width: 768px) {
  .wide-sm {
    max-width: 580px !important;
  }
}
@media (min-width: 992px) {
  .wide-md {
    max-width: 940px !important;
  }
}
.overline-title {
  font-size: 11px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #787c9e;
  text-transform: uppercase;
  font-weight: 700;
}
.overline-title-sep {
  padding-left: 0.35rem;
  padding-right: 0.25rem;
  position: relative;
  display: inline-block;
  font-weight: 700;
}
.overline-title-sep::before,
.overline-title-sep::after {
  background: #787c9e;
  content: "";
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  opacity: 0.4;
}
.overline-title-sep::before {
  right: 100%;
}
.overline-title-sep::after {
  left: 100%;
}
.smaller {
  font-size: 0.75rem;
}
.lead-text {
  font-size: 0.813rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #2e314a;
}
.sub-text {
  font-size: 0.75rem;
  color: #787c9e;
  line-height: 1.5;
}
.text-base {
  color: #2e314a !important;
}
.text-light {
  color: #787c9e !important;
}
.entry img {
  border-radius: 0.375rem;
}
.entry img + p,
.entry img + h2,
.entry img + .h2,
.entry img + h3,
.entry img + .h3,
.entry img + h4,
.entry img + .h4,
.entry img + h5,
.entry img + .h5,
.entry img + h6,
.entry img + .h6,
.entry img + ul,
.entry img + ol {
  margin-top: 2rem;
}
.entry p {
  color: #787c9e;
}
.entry p + img {
  margin-top: 1rem;
}
.entry p + h2,
.entry p + .h2,
.entry p + h3,
.entry p + .h3,
.entry p + h4,
.entry p + .h4,
.entry p + h5,
.entry p + .h5,
.entry p + h6,
.entry p + .h6 {
  padding-top: 0.75rem;
}
.dropdown-user .dropdown-menu {
  width: 250px;
}
.nk-quick-nav {
  display: flex;
  align-items: center;
  margin: 0 -0.25rem;
}
.nk-quick-nav > * {
  padding: 0 0.25rem;
}
.nk-quick-nav-icon {
  font-size: 1.5rem;
  color: #2e314a;
}
.nk-block-head {
  margin-bottom: 2rem;
}
.nk-block-head-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nk-block-head-sm {
  margin-bottom: 1rem;
}
.nk-block-title {
  margin-bottom: 0.25rem;
}
.nk-block + .nk-block {
  margin-top: 3rem;
}
.nk-page-head {
  margin-bottom: 2.5rem;
}
.nk-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nk-split {
  position: relative;
  display: flex;
}
.nk-split-page {
  min-height: 100vh;
  width: 100%;
}
.nk-split-auth-container {
  max-width: 100%;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 576px) {
  .nk-split-auth-container {
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .nk-split-auth-container {
    max-width: 570px;
    width: 46%;
  }
}
@media (min-width: 1400px) {
  .nk-split-auth-container {
    padding: 20px 100px;
  }
}
.js-copy {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.js-copy-wrap {
  position: relative;
}
.js-copy-tooltip {
  background-color: #24214b;
  color: #fff;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.125rem 0.625rem;
  position: absolute;
  z-index: 1;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  margin: 0 !important;
}
.js-copy-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 50%;
  margin-right: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #24214b rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}
.js-copy:hover .js-copy-tooltip {
  visibility: visible;
  opacity: 1;
}
.media {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 0.25rem;
  font-size: 0.9166666667rem;
  flex-shrink: 0;
}
.media .icon {
  font-size: 1.375rem;
}
.media img {
  border-radius: inherit;
}
.media-rounded {
  border-radius: 0.5rem;
}
.media-circle {
  border-radius: 50rem;
}
.media-sharp {
  border-radius: 0;
}
.media-middle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.media-border {
  border-width: 1px;
  border-style: solid;
}
.media-text {
  display: flex;
  flex-direction: column;
}
.media-text .title {
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  color: #2e314a;
}
.media-text .title + .date {
  margin-top: 0.25rem;
}
.media-text a.title:hover {
  color: #5f38f9;
}
.media-text .text,
.media-text .date {
  display: block;
  color: #787c9e;
}
.media-action {
  flex-shrink: 0;
  flex-grow: 0;
}
.media-action-end {
  margin-left: auto;
}
.media-group {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.media-group .media + .media-text {
  margin-left: 0.625rem;
}
.media-group-column {
  flex-direction: column;
}
.media-group-column .media + .media-text {
  margin-left: 0;
  margin-top: 0.625rem;
}
.media-group-overlap .media + .media {
  margin-left: -0.5rem;
}
.media-group-overlap.media-group-column .media + .media {
  margin-left: 0;
  margin-top: -0.5rem;
}
.media-tiny {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.4166666667rem;
}
.media-tiny .icon {
  font-size: 0.625rem;
}
.media-xs {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.5rem;
}
.media-xs .icon {
  font-size: 0.75rem;
}
.media-sm {
  width: 1.875rem;
  height: 1.875rem;
  font-size: 0.625rem;
}
.media-sm .icon {
  font-size: 0.9375rem;
}
.media-md {
  width: 2.375rem;
  height: 2.375rem;
  font-size: 0.7916666667rem;
}
.media-md .icon {
  font-size: 1.1875rem;
}
.media-rg {
  width: 2.75rem;
  height: 2.75rem;
  font-size: 0.9166666667rem;
}
.media-rg .icon {
  font-size: 1.375rem;
}
.media-lg {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.1666666667rem;
}
.media-lg .icon {
  font-size: 1.75rem;
}
.media-xl {
  width: 3.375rem;
  height: 3.375rem;
  font-size: 1.125rem;
}
.media-xl .icon {
  font-size: 1.6875rem;
}
.media-xxl {
  width: 3.75rem;
  height: 3.75rem;
  font-size: 1.25rem;
}
.media-xxl .icon {
  font-size: 1.875rem;
}
.media-big {
  width: 5rem;
  height: 5rem;
  font-size: 1.6666666667rem;
}
.media-big .icon {
  font-size: 2.5rem;
}
.media-huge {
  width: 6rem;
  height: 6rem;
  font-size: 2rem;
}
.media-huge .icon {
  font-size: 3rem;
}
.table-border {
  border: 1px solid #e8e7ec;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 0.375rem;
}
.table-head-sm th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.table tbody tr:last-child td,
.table tbody tr:last-child th {
  border-bottom-width: 0;
}
.table tbody th {
  color: #43476b;
}
.tb-col-hide,
.tb-col-xxl,
.tb-col-xl,
.tb-col-lg,
.tb-col-md,
.tb-col-sm,
.tb-col-xs,
.tb-col-mb {
  display: none !important;
}
@media (min-width: 359px) {
  .tb-col-xs {
    display: table-cell !important;
  }
}
@media (min-width: 414px) {
  .tb-col-mb {
    display: table-cell !important;
  }
}
@media (min-width: 576px) {
  .tb-col-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) {
  .tb-col-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) {
  .tb-col-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .tb-col-xl {
    display: table-cell !important;
  }
}
@media (min-width: 1400px) {
  .tb-col-xxl {
    display: table-cell !important;
  }
}
.table-middle td,
.tb-row-middle td {
  vertical-align: middle;
}
.tb-col-end,
.tb-col-action {
  text-align: right;
}
.tb-col-middle {
  vertical-align: middle;
}
.tb-col-check {
  width: 48px !important;
  padding-right: 0 !important;
}
.tb-col-check .form-check {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}
.icon-hint {
  color: #787c9e;
}
.icon-xs {
  font-size: 0.875rem;
}
.icon-sm {
  font-size: 1rem;
}
.icon-md {
  font-size: 1.25rem;
}
.icon-rg {
  font-size: 1.5rem;
}
.icon-lg {
  font-size: 1.75rem;
}
.icon-xl {
  font-size: 2rem;
}
.off {
  display: block;
}
.active > .off {
  display: none;
}
.on {
  display: none;
}
.active > .on {
  display: block;
}
.change.up {
  color: #2dc58c;
}
.change.down {
  color: #df3c4e;
}
.change + * {
  margin-left: 5px;
}
.amount {
  color: #43476b;
}
.chart-label-group {
  display: flex;
}
.chart-label-small .title {
  font-size: 0.75rem;
  color: #787c9e;
}
.chart-legend {
  padding-left: 0.875rem;
  position: relative;
}
.chart-legend-group {
  display: flex;
}
.chart-legend .title {
  font-size: 0.813rem;
  color: #2e314a;
}
.chart-legend .dot {
  position: absolute;
  left: 0;
  top: 7px;
}
.chart-legend-small .title {
  font-size: 0.813rem;
}
.chart-legend-small .dot {
  top: 6px;
}
[data-nk-chart="line"],
[data-nk-chart="bar"] {
  max-width: 100%;
  max-height: 100%;
}
[data-nk-chart="doughnut"],
[data-nk-chart="pie"] {
  max-width: 100%;
  max-height: 100%;
}
.nk-chart-analytics-session {
  height: 150px;
}
.nk-chart-analytics-avg-session {
  height: 130px;
}
.nk-chart-analytics-bounce-rate {
  height: 162px;
}
.nk-chart-analytics-goal-completions {
  height: 100px;
}
.nk-chart-analytics-audience-overview {
  height: 280px;
}
.nk-chart-analytics-session-device {
  height: 270px;
}
.nk-chart-ecommerce-visitor {
  height: 140px;
}
.nk-chart-ecommerce-activity {
  height: 140px;
}
.nk-chart-ecommerce-total-revenue {
  height: 86px;
}
.nk-chart-ecommerce-sales-analytics {
  height: 280px;
}
.nk-chart-ecommerce-total-profit {
  height: 322px;
}
.nk-chart-ecommerce-knob {
  height: 70px;
}
.nk-chart-project-active {
  height: 110px;
}
.nk-chart-project-done {
  position: relative;
  width: 136px;
  height: 120px;
}
.nk-chart-project-done .knob-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.nk-chart-project-earnings {
  height: 110px;
  width: 130px;
}
.nk-chart-project-total-clients {
  height: 110px;
  width: 130px;
}
.nk-chart-project-overview {
  height: 180px;
}
.nk-chart-project-avg-earnings {
  height: 190px;
}
.nk-chart-marketing-campaign {
  height: 190px;
}
.nk-chart-marketing-campaign-overview {
  height: 254px;
}
.nk-chart-marketing-campaign-visitor {
  height: 152px;
}
.nk-chart-marketing-effectiveness {
  height: 320px;
}
.nk-chart-nft-total-revenue {
  height: 104px;
}
.nk-chart-nft-estimated {
  height: 104px;
}
.nk-chart-nft-marketplace {
  height: 258px;
}
.nk-chart-nft-popularity {
  height: 280px;
}
@media (min-width: 576px) {
  .nk-chart-analytics-session-region-map {
    width: 330px;
  }
}
.dot {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.dot + * {
  margin-left: 0.125rem;
}
.dot.sq {
  border-radius: 3px;
}
.dot-xs {
  height: 4px;
  width: 4px;
}
.dot-sm {
  height: 6px;
  width: 6px;
}
.dot-md {
  height: 8px;
  width: 8px;
}
.dot-rg {
  height: 10px;
  width: 10px;
}
.dot-lg {
  height: 12px;
  width: 12px;
}
.dot-xl {
  height: 16px;
  width: 16px;
}
.nk-timeline-heading {
  padding-bottom: 0.75rem;
}
.nk-timeline-group + .nk-timeline-group {
  padding-top: 32px;
}
.nk-timeline-item {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  overflow: hidden;
}
.nk-timeline-item:not(:last-child) .nk-timeline-item-inner {
  padding-bottom: 0.75rem;
}
.nk-timeline-item-inner {
  display: flex;
}
.nk-timeline-item-inner p {
  margin-bottom: 0;
}
.nk-timeline-item-inner strong {
  font-weight: 500;
  color: #2e314a;
}
.nk-timeline-item-inner .time {
  display: block;
}
.nk-timeline-item-inner .time + p {
  margin-top: 0.25rem;
}
.nk-timeline-item:last-child .nk-timeline-symbol::before {
  display: none;
}
.nk-timeline-symbol {
  position: relative;
  align-self: flex-start;
  display: inline-flex;
}
.nk-timeline-symbol::before {
  position: absolute;
  content: "";
  top: calc(100% + 0.25rem);
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100rem;
  background-color: #d1d5db;
  z-index: 1;
}
.nk-timeline-symbol .media {
  position: relative;
  z-index: 4;
}
.nk-timeline-symbol + .nk-timeline-content {
  padding-left: 0.75rem;
}
.nk-timeline-center .nk-timeline-item-inner {
  align-items: center;
}
.nk-data-list-item {
  display: flex;
}
.nk-data-list-item .amount {
  line-height: 1;
}
.nk-data-list-item .media + .amount-wrap {
  margin-left: 0.75rem;
}
.list-group-wrap {
  margin: -2px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 4px);
}
.list-group-wrap > * {
  flex-grow: 1;
  border: 1px dashed #d2d4e4;
  margin: -0.5px;
}
.list-group-dotted {
  border: 1px dashed #d2d4e4;
  border-radius: 0.375rem;
  overflow: hidden;
}
.list-group-sm {
  font-size: 0.813rem;
}
.list-group-noborder {
  --bs-list-group-border-width: 0;
  --bs-list-group-item-padding-x: 0;
}
.list-group-noborder li {
  display: flex;
  align-items: center;
}
.list-group-noborder li .icon {
  font-size: 1.25rem;
  width: 2rem;
}
.list-group-accent {
  --bs-list-group-color: #5f38f9;
}
.list-group-accent-dark {
  --bs-list-group-color: #1c047b;
}
.list-group-accent-darken {
  --bs-list-group-color: #090127;
}
.list-group-base-dark {
  --bs-list-group-color: #24214b;
}
.list-group-base {
  --bs-list-group-color: #43476b;
}
.list-group-base-alt {
  --bs-list-group-color: #2e314a;
}
.list-group-base-light {
  --bs-list-group-color: #787c9e;
}
.list-group-base-lighter {
  --bs-list-group-color: #d1d5db;
}
.nk-list-option {
  display: flex;
  align-items: center;
  margin: -0.5rem;
}
.nk-list-option > * {
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
}
.nk-list-option .icon {
  width: 1.25rem;
  display: inline-block;
}
.nk-list-option-list li {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.nk-list-option-list li a {
  color: #43476b;
}
.nk-list-option-list li a:hover {
  color: #5f38f9;
}
.nk-list-collapse li a {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}
.nk-list-collapse li a .off {
  color: #5f38f9;
}
.nk-list-collapse li a.collapsed .off {
  display: none;
}
.nk-list-collapse li a.collapsed .on {
  display: block;
}
.nk-list-collapse li li a {
  margin-bottom: 0;
}
.nk-list-meta {
  display: flex;
  align-items: center;
}
.nk-list-meta li {
  font-size: 0.875rem;
  color: #787c9e;
  padding-right: 1.5rem;
  position: relative;
}
.nk-list-meta li::after {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 4px;
  height: 4px;
  background-color: #9ca3af;
  border-radius: 50%;
  transform: translateY(-50%);
  content: "";
}
.nk-list-meta li:last-child {
  padding-right: 0;
}
.nk-list-meta li:last-child::after {
  display: none;
}
.link-list {
  --nk-link-list-color: #43476b;
  --nk-link-list-bg: transparent;
  --nk-link-list-hover-color: #5f38f9;
  --nk-link-list-hover-bg: transparent;
  --nk-link-list-font-size: 0.813rem;
  --nk-link-list-line-height: 1.25rem;
  --nk-link-list-padding-y: 0.375rem;
  --nk-link-list-padding-x: 0;
  --nk-link-list-icon-size: 1.25rem;
  --nk-link-list-icon-width: 2rem;
  --nk-link-list-btn-padding-y: 0.375rem;
  --nk-link-list-btn-padding-x: 0;
  --nk-link-list-btn-border-radius: 0.375rem;
}
.link-list li {
  padding: var(--nk-link-list-padding-y) var(--nk-link-list-padding-x);
  margin-left: calc(var(--nk-link-list-btn-padding-x) * -1);
  margin-right: calc(var(--nk-link-list-btn-padding-x) * -1);
}
.link-list li a {
  display: flex;
  align-items: center;
  font-size: var(--nk-link-list-font-size);
  line-height: var(--nk-link-list-line-height);
  color: var(--nk-link-list-color);
  background-color: var(--nk-link-list-bg);
  padding: var(--nk-link-list-btn-padding-y) var(--nk-link-list-btn-padding-x);
  border-radius: var(--nk-link-list-btn-border-radius);
}
.link-list li a .icon {
  font-size: var(--nk-link-list-icon-size);
  line-height: var(--nk-link-list-line-height);
  width: var(--nk-link-list-icon-width);
}
.link-list li a:hover {
  color: var(--nk-link-list-hover-color);
  background-color: var(--nk-link-list-hover-bg);
}
.link-list.is-compact {
  --nk-link-list-padding-y: 0.25rem;
}
[class*="link-list-hover-bg"] {
  --nk-link-list-hover-color: #5f38f9;
  --nk-link-list-hover-bg: #dfd7fe;
  --nk-link-list-padding-y: 0;
  --nk-link-list-padding-x: 0;
  --nk-link-list-btn-padding-y: 0.75rem;
  --nk-link-list-btn-padding-x: 0.75rem;
}
[class*="link-list-hover-bg"].link-list-sm {
  --nk-link-list-btn-padding-y: 0.3125rem;
  --nk-link-list-font-size: 0.75rem;
  --nk-link-list-icon-size: 1rem;
  --nk-link-list-icon-width: 1.5rem;
  --nk-link-list-btn-border-radius: 0.25rem;
}
[class*="link-list-hover-bg"].link-list-md {
  --nk-link-list-btn-padding-y: 0.5625rem;
  --nk-link-list-font-size: 0.813rem;
  --nk-link-list-icon-size: 1.125rem;
  --nk-link-list-icon-width: 1.75rem;
  --nk-link-list-btn-border-radius: 0.313rem;
}
.link-list-primary {
  --nk-link-list-color: #5f38f9;
}
.link-list-purple {
  --nk-link-list-color: #8927f9;
}
.link-list-pink {
  --nk-link-list-color: #f24a8b;
}
.link-list-secondary {
  --nk-link-list-color: #374151;
}
.link-list-success {
  --nk-link-list-color: #2dc58c;
}
.link-list-info {
  --nk-link-list-color: #478ffc;
}
.link-list-warning {
  --nk-link-list-color: #f2bc16;
}
.link-list-danger {
  --nk-link-list-color: #df3c4e;
}
.link-list-lighter {
  --nk-link-list-color: #f1f2f7;
}
.link-list-light {
  --nk-link-list-color: #e5e7eb;
}
.link-list-dark {
  --nk-link-list-color: #1f2937;
}
.link-list-darker {
  --nk-link-list-color: #111827;
}
.link-list-accent {
  --nk-link-list-color: #5f38f9;
}
.link-list-accent-dark {
  --nk-link-list-color: #1c047b;
}
.link-list-accent-darken {
  --nk-link-list-color: #090127;
}
.link-list-base-dark {
  --nk-link-list-color: #24214b;
}
.link-list-base {
  --nk-link-list-color: #43476b;
}
.link-list-base-alt {
  --nk-link-list-color: #2e314a;
}
.link-list-base-light {
  --nk-link-list-color: #787c9e;
}
.link-list-base-lighter {
  --nk-link-list-color: #d1d5db;
}
.link-list-hover-bg-primary {
  --nk-link-list-hover-color: #5f38f9;
  --nk-link-list-hover-bg: #e7e1fe;
}
.link-list-hover-bg-purple {
  --nk-link-list-hover-color: #8927f9;
  --nk-link-list-hover-bg: #eddffe;
}
.link-list-hover-bg-pink {
  --nk-link-list-hover-color: #f24a8b;
  --nk-link-list-hover-bg: #fde4ee;
}
.link-list-hover-bg-secondary {
  --nk-link-list-hover-color: #374151;
  --nk-link-list-hover-bg: #e1e3e5;
}
.link-list-hover-bg-success {
  --nk-link-list-hover-color: #2dc58c;
  --nk-link-list-hover-bg: #e0f6ee;
}
.link-list-hover-bg-info {
  --nk-link-list-hover-color: #478ffc;
  --nk-link-list-hover-bg: #e3eeff;
}
.link-list-hover-bg-warning {
  --nk-link-list-hover-color: #f2bc16;
  --nk-link-list-hover-bg: #fdf5dc;
}
.link-list-hover-bg-danger {
  --nk-link-list-hover-color: #df3c4e;
  --nk-link-list-hover-bg: #fae2e4;
}
.link-list-hover-bg-lighter {
  --nk-link-list-hover-color: #f1f2f7;
  --nk-link-list-hover-bg: #fdfdfe;
}
.link-list-hover-bg-light {
  --nk-link-list-hover-color: #e5e7eb;
  --nk-link-list-hover-bg: #fbfbfc;
}
.link-list-hover-bg-dark {
  --nk-link-list-hover-color: #1f2937;
  --nk-link-list-hover-bg: #dddfe1;
}
.link-list-hover-bg-darker {
  --nk-link-list-hover-color: #111827;
  --nk-link-list-hover-bg: #dbdcdf;
}
[class*="link-list-bg"] {
  --nk-link-list-btn-padding-y: 0.75rem;
  --nk-link-list-btn-padding-x: 0.75rem;
}
.link-list-bg-primary {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #5f38f9;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #5130d4;
}
.link-list-bg-purple {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #8927f9;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #7421d4;
}
.link-list-bg-pink {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #f24a8b;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #ce3f76;
}
.link-list-bg-secondary {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #374151;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #2f3745;
}
.link-list-bg-success {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #2dc58c;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #26a777;
}
.link-list-bg-info {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #478ffc;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #3c7ad6;
}
.link-list-bg-warning {
  --nk-link-list-color: #000;
  --nk-link-list-bg: #f2bc16;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #cea013;
}
.link-list-bg-danger {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #df3c4e;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #be3342;
}
.link-list-bg-lighter {
  --nk-link-list-color: #000;
  --nk-link-list-bg: #f1f2f7;
  --nk-link-list-hover-color: #000;
  --nk-link-list-hover-bg: #cdced2;
}
.link-list-bg-light {
  --nk-link-list-color: #000;
  --nk-link-list-bg: #e5e7eb;
  --nk-link-list-hover-color: #000;
  --nk-link-list-hover-bg: #c3c4c8;
}
.link-list-bg-dark {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #1f2937;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #1a232f;
}
.link-list-bg-darker {
  --nk-link-list-color: #fff;
  --nk-link-list-bg: #111827;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #0e1421;
}
.link-list-bg-primary-soft {
  --nk-link-list-color: #5f38f9;
  --nk-link-list-bg: #e7e1fe;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #5f38f9;
}
.link-list-bg-purple-soft {
  --nk-link-list-color: #8927f9;
  --nk-link-list-bg: #eddffe;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #8927f9;
}
.link-list-bg-pink-soft {
  --nk-link-list-color: #f24a8b;
  --nk-link-list-bg: #fde4ee;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #f24a8b;
}
.link-list-bg-secondary-soft {
  --nk-link-list-color: #374151;
  --nk-link-list-bg: #e1e3e5;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #374151;
}
.link-list-bg-success-soft {
  --nk-link-list-color: #2dc58c;
  --nk-link-list-bg: #e0f6ee;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #2dc58c;
}
.link-list-bg-info-soft {
  --nk-link-list-color: #478ffc;
  --nk-link-list-bg: #e3eeff;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #478ffc;
}
.link-list-bg-warning-soft {
  --nk-link-list-color: #f2bc16;
  --nk-link-list-bg: #fdf5dc;
  --nk-link-list-hover-color: #000;
  --nk-link-list-hover-bg: #f2bc16;
}
.link-list-bg-danger-soft {
  --nk-link-list-color: #df3c4e;
  --nk-link-list-bg: #fae2e4;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #df3c4e;
}
.link-list-bg-lighter-soft {
  --nk-link-list-color: #f1f2f7;
  --nk-link-list-bg: #fdfdfe;
  --nk-link-list-hover-color: #000;
  --nk-link-list-hover-bg: #f1f2f7;
}
.link-list-bg-light-soft {
  --nk-link-list-color: #e5e7eb;
  --nk-link-list-bg: #fbfbfc;
  --nk-link-list-hover-color: #000;
  --nk-link-list-hover-bg: #e5e7eb;
}
.link-list-bg-dark-soft {
  --nk-link-list-color: #1f2937;
  --nk-link-list-bg: #dddfe1;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #1f2937;
}
.link-list-bg-darker-soft {
  --nk-link-list-color: #111827;
  --nk-link-list-bg: #dbdcdf;
  --nk-link-list-hover-color: #fff;
  --nk-link-list-hover-bg: #111827;
}
.list2col > li {
  width: 50%;
}
.list3col > li {
  width: 33.3333333333%;
}
.list4col > li {
  width: 25%;
}
.list5col > li {
  width: 20%;
}
.list6col > li {
  width: 16.6666666667%;
}
.nk-schedule-item {
  overflow: hidden;
}
.nk-schedule-item:not(:last-child) {
  padding-bottom: 1.625rem;
}
.nk-schedule-item-inner {
  display: flex;
}
.nk-schedule-item:first-child .nk-schedule-symbol::before {
  top: 50%;
}
.nk-schedule-item:last-child .nk-schedule-symbol::before {
  top: auto;
  bottom: 50%;
}
.nk-schedule-symbol {
  position: relative;
  width: 2.25rem;
  flex-shrink: 0;
}
.nk-schedule-symbol.active::after {
  background-color: #478ffc;
  box-shadow: 0 0 0 0.25rem #edf4ff;
}
.nk-schedule-symbol::after {
  width: 0.5rem;
  height: 0.5rem;
  top: calc(50% - 0.25rem);
  left: 0.25rem;
  position: absolute;
  content: "";
  background-color: #e5e7eb;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 0 0 0.25rem #fcfdfd;
}
.nk-schedule-symbol::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0.5rem;
  transform: translateX(-50%);
  width: 0.125rem;
  height: 100rem;
  background-color: #f1f2f7;
  z-index: 1;
}
.nk-schedule-content {
  border: 1px dashed #d2d4e4;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem 0.75rem 1rem;
}
.nk-schedule-content-no-border {
  border: 0;
  padding: 0;
  border-radius: 0;
}
.box-dotted {
  border: 1px dashed #d2d4e4;
  border-radius: 0.375rem;
  padding: 1rem;
}
.bio-block p {
  font-size: 0.875rem;
}
.bio-block p:not(:last-child) {
  margin-bottom: 0.625rem;
}
.bio-block strong {
  font-weight: 500;
  color: #2e314a;
}
.bio-block + .bio-block {
  padding-top: 1.625rem;
}
.mask {
  position: absolute;
}
.mask-1 {
  top: 0;
  left: 0;
  width: 504px;
  height: 200px;
  /* background-image: url("../images/mask/a.png"); */
  background-size: cover;
}
.mask-2 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../images/mask/b.png"); */
  background-size: cover;
  z-index: -1;
}
.mask-3 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../images/mask/c.png"); */
  background-size: cover;
  background-position: center;
  z-index: -1;
}
.mask-4 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../images/mask/d.png"); */
  background-size: cover;
  background-position: bottom;
  z-index: -1;
}
.has-mask {
  position: relative;
  z-index: 2;
}
.has-mask-1 {
  padding-top: 200px;
}
.has-bg {
  background-size: cover;
  background-position: center;
}
.search-inline {
  display: flex;
  align-items: center;
}
.rating {
  display: flex;
  align-items: center;
  margin: -2px;
}
.rating > * {
  padding: 2px;
}
.rating-label {
  font-size: 1rem;
  color: #d1d5db;
}
.rating-label.checked {
  color: #f2bc16;
}
.image-upload-wrap {
  background: #fff;
  border: 1px solid #d2d4e4;
  border-radius: 0.375rem;
  padding: 2rem 1rem;
}
[data-overlay] {
  z-index: 500;
}
.nk-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(31, 41, 55, 0.5);
  z-index: 499;
  animation: overlay-fade-in 0.3s 1;
}
.nk-entry img,
.nk-entry blockquote {
  border-radius: 0.375rem;
}
.nk-entry h1 + p,
.nk-entry .h1 + p,
.nk-entry h2 + p,
.nk-entry .h2 + p,
.nk-entry h3 + p,
.nk-entry .h3 + p {
  margin-top: 1rem;
}
.nk-entry h1 + img,
.nk-entry .h1 + img,
.nk-entry h2 + img,
.nk-entry .h2 + img,
.nk-entry h3 + img,
.nk-entry .h3 + img {
  margin-top: 0.75rem;
}
.nk-entry h4 + p,
.nk-entry .h4 + p,
.nk-entry h5 + p,
.nk-entry .h5 + p,
.nk-entry h6 + p,
.nk-entry .h6 + p {
  margin-top: 0.75rem;
}
.nk-entry img:not(:last-child) {
  margin-bottom: 2rem;
}
.nk-entry p {
  font-size: 0.938rem;
}
.nk-entry p + h1,
.nk-entry p + .h1,
.nk-entry p + h2,
.nk-entry p + .h2,
.nk-entry p + h3,
.nk-entry p + .h3 {
  margin-top: 2rem;
}
.nk-entry ul + h1,
.nk-entry ul + .h1,
.nk-entry ul + h2,
.nk-entry ul + .h2,
.nk-entry ul + h3,
.nk-entry ul + .h3,
.nk-entry ul + p,
.nk-entry ol + h1,
.nk-entry ol + .h1,
.nk-entry ol + h2,
.nk-entry ol + .h2,
.nk-entry ol + h3,
.nk-entry ol + .h3,
.nk-entry ol + p {
  margin-top: 1rem;
}
.nk-entry pre + h1,
.nk-entry pre + .h1,
.nk-entry pre + h2,
.nk-entry pre + .h2,
.nk-entry pre + h3,
.nk-entry pre + .h3,
.nk-entry pre + p {
  margin-top: 1.75rem;
}
.nk-entry blockquote {
  background-color: #f1f2f7;
  padding: 1rem;
}
.nk-entry ul,
.nk-entry ol {
  list-style: inherit;
  padding-left: 1rem;
}
.nk-entry ol {
  list-style-type: decimal;
}
.asterisk a {
  position: relative;
  color: #787c9e;
  display: inline-flex;
}
.asterisk a:hover,
.asterisk a.active {
  color: #f85f32;
}
.asterisk a:hover .asterisk-on,
.asterisk a.active .asterisk-on {
  opacity: 1;
}
.asterisk a.active:hover .asterisk-on {
  opacity: 0;
}
.asterisk-on {
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #f85f32;
  z-index: 1;
  transition: opacity 0.2s;
}
.nk-ibx {
  display: flex;
  position: relative;
  background: #fff;
  height: 100%;
}
.nk-ibx-aside {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 254px;
  height: 100%;
  border-right: 1px solid #d2d4e4;
  transform: translateX(-100%);
  background: #fff;
  z-index: 500;
}
.nk-ibx-aside.toggle-collapsed {
  transition: transform 0.3s ease;
}
.nk-ibx-aside.show-aside {
  transform: none;
}
.nk-ibx-aside .simplebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.nk-ibx-aside .simplebar-content > *:last-child {
  padding-bottom: 1.25rem;
}
.nk-ibx-aside-header {
  padding: 1.5rem 1.5rem 1rem;
}
.nk-ibx-menu li {
  padding: 0.375rem 0;
}
.nk-ibx-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  color: #787c9e;
  padding: 0.375rem 1.5rem;
  font-size: 0.875rem;
}
.nk-ibx-menu-item::after {
  position: absolute;
  height: 100%;
  width: 2px;
  content: "";
  left: 0;
  top: 0;
  background: #5f38f9;
  opacity: 0;
}
.nk-ibx-menu-item.active,
.active > .nk-ibx-menu-item {
  color: #5f38f9;
}
.nk-ibx-menu-item.active:after,
.active > .nk-ibx-menu-item:after {
  opacity: 1;
}
.nk-ibx-menu-item .icon {
  font-size: 1.375rem;
  width: 2.375rem;
}
.nk-ibx-menu-badge {
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 0.125rem;
  margin-left: auto;
  display: inline-flex;
}
.nk-ibx-contacts li {
  padding: 0 1.5rem;
}
.nk-ibx-contacts li:not(:last-child) {
  padding-bottom: 1.5rem;
}
.nk-ibx-body {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}
.nk-ibx-head {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e7ec;
  padding: 0.5rem 0.875rem;
}
.nk-ibx-head-tools {
  display: flex;
  align-items: center;
  margin: 0 -0.125rem;
}
.nk-ibx-head-tools > li {
  display: inline-flex;
  align-items: center;
  padding: 1px 0.125rem;
  flex-shrink: 0;
}
.nk-ibx-head-search {
  width: 20rem;
  padding: 0 1.25rem;
}
.nk-ibx-head-pagination {
  display: flex;
  align-items: center;
}
.nk-ibx-head-pagination > *:not(:last-child) {
  padding-right: 1rem;
}
.nk-ibx-head-info {
  color: #787c9e;
  font-size: 0.875rem;
}
.nk-ibx-head-nav {
  display: flex;
  align-items: center;
  margin: 0 -0.125rem;
}
.nk-ibx-head-nav li {
  padding: 0 0.125rem;
}
.nk-ibx-list {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow: auto;
}
.nk-ibx-item {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 1rem;
}
.nk-ibx-item-elem {
  flex-shrink: 0;
  padding: 1rem 0.5rem;
}
.nk-ibx-item-check {
  width: 2.25rem;
  z-index: 2;
}
.nk-ibx-item-star {
  width: 2rem;
  z-index: 2;
}
.nk-ibx-item-user {
  width: 240px;
}
.nk-ibx-item-title {
  display: flex;
}
.nk-ibx-item-content {
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  max-width: 100%;
}
.nk-ibx-item-subject {
  color: #2e314a;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
}
.nk-ibx-item-tags {
  padding: 0 0.25rem;
}
.nk-ibx-item-tags li {
  padding: 0 0.125rem;
}
.nk-ibx-item-message {
  color: #787c9e;
  font-size: 0.813rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nk-ibx-item-meta {
  width: 5rem;
}
.nk-ibx-item-meta .time {
  font-size: 0.813rem;
  white-space: nowrap;
  text-align: right;
}
.nk-ibx-item:not(:last-child) {
  border-bottom: 1px solid #e8e7ec;
}
.nk-ibx-link {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.nk-ibx-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.nk-ibx-view.show-ibx {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.nk-ibx-reply-head {
  padding: 0.75rem 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nk-ibx-reply-header {
  margin-right: -0.5rem;
}
.nk-ibx-reply-item {
  position: relative;
}
.nk-ibx-reply-item > div:not(.nk-reply-header) {
  margin-top: 1.25rem;
}
.nk-ibx-reply-item > div:not(.nk-reply-header):not(:last-child) {
  margin-bottom: 0.75rem;
}
.nk-ibx-reply-item:not(:last-child):after {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  height: 1px;
  bottom: 0;
  content: "";
  background: #e8e7ec;
}
@media (max-width: 767.98px) {
  .nk-ibx-item {
    flex-wrap: wrap;
  }
  .nk-ibx-item-check {
    position: absolute;
    top: 0.375rem;
    left: 1rem;
  }
  .nk-ibx-item-star {
    position: absolute;
    left: 1rem;
    top: 2.875rem;
  }
  .nk-ibx-item-user {
    width: 100%;
    padding-left: 3rem;
    padding-bottom: 0;
  }
  .nk-ibx-item-content {
    padding-left: 3rem;
  }
  .nk-ibx-item-meta {
    position: absolute;
    top: 0;
    right: 1rem;
  }
}
@media (min-width: 768px) {
  [data-ibx-collapse="md"] .nk-ibx-aside {
    position: static;
    transform: translateX(0);
  }
  [data-ibx-collapse="md"] .nk-ibx-body {
    max-width: calc(100% - 254px);
  }
  [data-ibx-collapse="md"] .nk-ibx-toggle {
    display: none;
  }
}
@media (min-width: 992px) {
  [data-ibx-collapse="lg"] .nk-ibx-aside {
    position: static;
    transform: translateX(0);
  }
  [data-ibx-collapse="lg"] .nk-ibx-body {
    max-width: calc(100% - 254px);
  }
  [data-ibx-collapse="lg"] .nk-ibx-toggle {
    display: none;
  }
}
@media (min-width: 1200px) {
  [data-ibx-collapse="xl"] .nk-ibx-aside {
    position: static;
    transform: translateX(0);
  }
  [data-ibx-collapse="xl"] .nk-ibx-body {
    max-width: calc(100% - 254px);
  }
  [data-ibx-collapse="xl"] .nk-ibx-toggle {
    display: none;
  }
}
.nk-reply {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}
.nk-reply-item {
  padding: 1rem 1.5rem;
}
.nk-reply-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.nk-reply-header .user-name {
  margin-left: 1rem;
  font-size: 0.875rem;
}
.nk-reply-header .user-name span {
  color: #787c9e;
  font-weight: 400;
}
.nk-reply-header .date-time {
  color: #787c9e;
  font-size: 0.75rem;
}
.nk-reply-entry.note {
  background: #f1f2f7;
  padding: 1.25rem;
  border-radius: 0.375rem;
}
.nk-reply-entry p {
  font-size: 0.938rem;
}
.nk-reply-from {
  margin-top: 1.25rem;
  padding: 0.375rem 0.75rem;
  background: #f1f2f7;
  border-radius: 0.375rem;
  display: inline-block;
  font-size: 0.813rem;
}
.nk-reply-meta {
  overflow: hidden;
  text-align: center;
  margin: 0 1.5rem;
  padding: 0 2rem;
}
.nk-reply-meta-info {
  position: relative;
  display: inline-block;
  padding: 0 0.75rem;
  color: #787c9e;
  font-size: 0.813rem;
  line-height: 1.4;
}
.nk-reply-meta-info span,
.nk-reply-meta-info strong,
.nk-reply-meta-info .who {
  color: #43476b;
}
.nk-reply-meta-info .whom {
  color: #5f38f9;
}
.nk-reply-meta-info:before,
.nk-reply-meta-info:after {
  position: absolute;
  height: 1px;
  background: #e8e7ec;
  content: "";
  width: 100vw;
  top: 50%;
}
.nk-reply-meta-info:before {
  right: 100%;
}
.nk-reply-meta-info:after {
  left: 100%;
}
.nk-reply-form {
  border-top: 1px solid #e8e7ec;
}
.nk-reply-form-dropdown {
  margin-left: -0.5rem;
  margin-right: 0.75rem;
}
.nk-reply-form-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid #e8e7ec;
}
.nk-reply-form-title {
  display: flex;
  align-items: center;
}
.nk-reply-form-title .title {
  color: #787c9e;
  margin-right: 0.75rem;
}
.nk-reply-form-field {
  padding: 0.5rem 1.25rem;
}
.nk-reply-form-field:not(:last-child) {
  border-bottom: 1px solid #e8e7ec;
}
.nk-reply-form-field .form-control {
  font-size: 0.875rem;
}
.nk-reply-form-field textarea.form-control {
  min-height: 78px;
}
.nk-reply-form-field textarea.form-control.ex-large {
  min-height: 160px;
}
.nk-reply-form-field + .nk-reply-form-tools {
  border-top: 0;
}
.nk-reply-form-tools {
  padding: 1.25rem 1.5rem;
  border-top: 1px solid #e8e7ec;
  display: flex;
  justify-content: space-between;
}
.nk-reply-form-actions {
  display: inline-flex;
  align-items: center;
}
.nk-reply-form-options {
  display: inline-flex;
}
.nk-reply-form-input {
  display: flex;
}
.nk-reply-form-input .label {
  margin: 0.3rem 0.5rem 0.3rem 0;
  width: 1.25rem;
  color: #787c9e;
}
.nk-reply-form-input-group {
  width: 100%;
}
.nk-reply-form-input-cc,
.nk-reply-form-input-bcc {
  display: none;
}
.nk-reply-form-input-cc.expanded,
.nk-reply-form-input-bcc.expanded {
  display: flex;
}
.nk-reply-form-input .toggle-opt {
  display: inline-flex;
  align-items: center;
  color: #2e314a;
}
.nk-reply-form-input .toggle-opt:hover {
  color: #5f38f9;
}
.nk-reply-form-group {
  position: relative;
  padding: 1rem 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.nk-reply-form-input-to {
  padding-right: 3.5rem;
}
.nk-reply-form-nav {
  position: absolute;
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: 1.0625rem 0 0;
  right: 0;
  top: 0;
  margin: 0 -0.375rem;
}
.nk-reply-form-nav li a {
  font-size: 0.75rem;
  color: #787c9e;
  display: block;
  line-height: 1.5rem;
  padding: 0.25rem 0.375rem;
}
.nk-reply-form-nav li a:hover {
  color: #5f38f9;
}
.nk-reply-form-editor .ql-toolbar {
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 1rem;
  padding-right: 1rem;
}
.nk-reply-form-editor .ql-editor {
  padding: 1rem 1.5rem;
}
.nk-reply-form-editor .ql-editor.ql-blank::before {
  left: 1.5rem;
  right: 1.5rem;
}
.nk-reply-form-editor .ql-container {
  border: 0;
}
.is-collapsed .nk-reply-msg-info {
  display: none;
}
.nk-reply-msg-excerpt {
  padding-top: 0.219rem;
  font-size: 0.866rem;
  color: #787c9e;
  display: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.is-collapsed .nk-reply-msg-excerpt {
  display: block;
}
.nk-reply-msg-meta {
  padding: 0.75rem;
}
.nk-reply-msg-meta li {
  display: flex;
  line-height: 1.125rem;
  padding: 0.1875rem 0;
}
.nk-reply-msg-meta li .label {
  width: 75px;
  text-align: right;
  color: #787c9e;
  font-size: 0.75rem;
  margin-right: 0.75rem;
}
.nk-reply-msg-meta li .info {
  font-size: 0.813rem;
}
.nk-reply-msg-meta li .info a {
  color: #43476b;
}
.nk-reply-tools {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: default;
}
.nk-reply-tools .date {
  font-size: 0.875rem;
  margin-right: 1rem;
  line-height: 2.25rem;
  color: #787c9e;
}
.nk-reply-tools .attach-msg {
  font-size: 1rem;
  margin-right: 0.375rem;
  color: #787c9e;
}
.nk-reply-desc {
  display: flex;
  flex-grow: 1;
}
.is-collapsed .nk-reply-desc {
  overflow: hidden;
}
.nk-reply-header .nk-reply-desc {
  align-items: center;
}
.nk-reply-info {
  margin-left: 1rem;
  max-width: calc(100% - 4.5rem);
}
.nk-reply-header.is-collapsed {
  margin-bottom: 0;
}
.nk-reply-header:not(.is-opened) {
  cursor: pointer;
}
.is-collapsed + .nk-reply-body {
  display: none;
}
.nk-reply-quick-btns ul {
  display: flex;
  align-items: center;
}
.nk-reply-author .date {
  display: inline;
  white-space: nowrap;
  font-size: 0.75rem;
  margin-left: 0.25rem;
}
.is-collapsed .prev-msg,
.is-collapsed .next-msg,
.is-collapsed .more-actions,
.is-collapsed .replyto-msg,
.is-collapsed .forwardto-msg {
  display: none;
}
@media (min-width: 576px) {
  .nk-reply-item > div:not(.nk-reply-header) {
    margin-left: 3.175rem;
  }
  .nk-reply-form {
    border-radius: 0.375rem;
    margin: 1.25rem 1.5rem 0 4.675rem;
    border: 1px solid #e8e7ec;
  }
}
.nk-message {
  display: flex;
  width: 100%;
  padding: 0.5rem 1.5rem;
}
.nk-message-wrap {
  display: flex;
  flex-direction: column-reverse;
}
.nk-message.is-me {
  flex-direction: row-reverse;
}
.nk-message-avatar {
  margin-right: 0.75rem;
}
.is-me .nk-message-avatar {
  margin-left: 0.75rem;
  margin-right: 0;
}
.nk-message-content {
  display: flex;
  flex-direction: inherit;
  width: 100%;
}
.is-you .nk-message-content {
  max-width: calc(100% - 3.5rem);
}
.nk-message-actions {
  display: flex;
  align-items: center;
  opacity: 0;
}
.is-you .nk-message-actions {
  padding-left: 0.5rem;
}
.is-me .nk-message-actions {
  padding-right: 0.5rem;
  flex-direction: row-reverse;
}
.nk-message-bubble {
  position: relative;
  background: #f8f8f9;
  border-radius: 1rem;
  padding: 0.75rem 1rem 0.376rem;
}
.nk-message-bubble.has-media {
  background: rgba(0, 0, 0, 0);
  padding: 0;
}
.nk-message-bubbles {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
}
.nk-message-bubbles > li {
  display: flex;
  align-items: center;
}
.is-me .nk-message-bubbles > li {
  flex-direction: row-reverse;
}
.nk-message-bubbles > li:not(:first-child) {
  margin-top: 0.25rem;
}
.nk-message-bubbles > li:hover .nk-message-actions {
  opacity: 1;
}
.is-you .nk-message-bubbles > li .nk-message-bubble {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.is-me .nk-message-bubbles > li .nk-message-bubble {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.is-you .nk-message-bubbles > li:last-child .nk-message-bubble {
  border-bottom-left-radius: 1rem;
}
.is-me .nk-message-bubbles > li:last-child .nk-message-bubble {
  border-bottom-right-radius: 1rem;
}
.is-me .nk-message-bubble {
  background: #5f38f9;
}
.nk-message-text {
  font-size: 0.875rem;
  color: #43476b;
  padding-bottom: 0.375rem;
}
.is-me .nk-message-text {
  color: #fff;
}
.nk-message-media {
  display: flex;
  margin: -0.25rem;
  padding-bottom: 0.5rem;
}
.nk-message-media li {
  padding: 0.25rem;
}
.nk-message-media li img {
  border-radius: 0.25rem;
}
.nk-message-media li:first-child img {
  border-top-left-radius: 0;
}
.nk-message-meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.75rem;
  color: #787c9e;
  margin: 0 -0.5rem;
}
.is-me .nk-message-meta {
  color: #fff;
  flex-direction: row-reverse;
}
.nk-message-meta li {
  position: relative;
  padding: 0 0.5rem;
}
.nk-message-meta li:not(:last-child):after {
  position: absolute;
  content: "";
  background: currentColor;
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 50%;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  opacity: 0.7;
}
.is-me .nk-message-meta li:not(:last-child):after {
  left: 0;
  transform: translate(-50%, -50%);
}
.nk-chat {
  display: flex;
  position: relative;
  background: #fff;
  height: 100%;
}
.nk-chat-aside {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.nk-chat-aside-scroll {
  overflow: auto;
  max-height: 100%;
}
.nk-chat-list li {
  cursor: pointer;
  padding: 0.875rem 1.5rem;
}
.nk-chat-list .active {
  cursor: auto;
  background: #f8f8f9;
}
.nk-chat-list .media-group {
  width: 100%;
}
.nk-chat-list .media-group .media + .media-text {
  margin-left: 0.875rem;
}
.nk-chat-list .media-text {
  flex-grow: 1;
}
.nk-chat-list .date {
  font-size: 0.75rem;
  color: #43476b;
}
.nk-chat-list .badge {
  font-size: 0.625rem;
}
.nk-chat-list .title,
.nk-chat-list .text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nk-chat-body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 1;
  background: #fff;
  display: none;
}
.nk-chat-body.show-chat {
  display: flex;
  z-index: 9999;
}
.nk-chat-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #e8e7ec;
}
.nk-chat-head-tools {
  display: flex;
  align-items: center;
}
.nk-chat-panel {
  flex-grow: 1;
  overflow: auto;
  max-height: 100%;
  padding: 1rem 0;
}
.nk-chat-form {
  padding: 1.5rem 1.5rem;
}
.nk-chat-input {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.25rem 0.675rem;
  border: 1px solid #e8e7ec;
}
.nk-chat-input-field {
  padding: 0 0.25rem;
  flex-grow: 1;
}
.nk-chat-input-btns {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 0.75rem;
  margin-right: 0.75rem;
}
.nk-chat-input-btns:after {
  position: absolute;
  content: "";
  right: 0;
  width: 1px;
  height: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: #e8e7ec;
}
.nk-chat-profile {
  position: absolute;
  top: 0;
  right: 0;
  width: 320px;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-shrink: 0;
  border-left: 1px solid #e8e7ec;
  transform: translateX(100%);
}
.nk-chat-profile.show-profile {
  transform: none;
}
.nk-chat-profile.toggle-collapsed {
  transition: transform 0.3s ease;
}
.nk-chat-media-list {
  display: flex;
  flex-wrap: wrap;
}
.nk-chat-media-item {
  position: relative;
  border-radius: 0.375rem;
}
.nk-chat-media-item img {
  border-radius: 0.375rem;
}
.nk-chat-media-item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
}
@media (min-width: 768px) {
  .nk-chat-aside {
    width: 300px;
    border-right: 1px solid #e8e7ec;
  }
  .nk-chat-body {
    position: static;
    display: flex !important;
    z-index: auto !important;
  }
}
@media (min-width: 1400px) {
  .nk-chat-aside {
    width: 340px;
  }
}
@media (min-width: 1400px) {
  .nk-chat-body {
    transition: padding 0.3s ease;
  }
  .profile-shown .nk-chat-body {
    padding-right: 320px;
  }
  .nk-chat-profile {
    transition: transform 0.3s ease;
  }
}
.nk-support-header {
  padding: 70px 40px;
}
.nk-support-header .nk-support-desc {
  margin-bottom: 2rem;
}
.nk-support-body {
  padding: 40px;
}
@media (min-width: 992px) {
  .nk-support-body {
    padding-top: 60px;
  }
  .nk-support-side-content {
    padding-left: 40px;
  }
  .nk-support-header-content {
    max-width: 490px;
    margin-left: auto;
    margin-right: auto;
  }
}
.nk-pricing-wrap {
  padding: 60px 40px;
}
.nk-pricing-header {
  padding-bottom: 40px;
}
.nk-pricing-title .text {
  font-size: 0.938rem;
}
.nk-pricing-card ul li {
  color: #787c9e;
}
.nk-pricing-card ul li .icon {
  color: #5f38f9;
}
.nk-pricing-card ul li .icon + span {
  margin-left: 0.25rem;
}
@media (min-width: 992px) {
  .nk-pricing-header-content {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
  .nk-pricing-card-title {
    padding-right: 120px;
  }
}
.nk-todo {
  display: flex;
  position: relative;
}
.nk-todo-aside {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 254px;
  height: 100%;
  border-right: 1px solid #e8e7ec;
  transform: translateX(-100%);
  background-color: #fff;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
}
.nk-todo-aside.toggle-collapsed {
  transition: transform 0.3s ease;
}
.nk-todo-aside.show-aside {
  transform: none;
  opacity: 1;
  visibility: visible;
}
.nk-todo-aside-header {
  padding: 1.5rem;
}
.nk-todo-menu li {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.nk-todo-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  color: #787c9e;
  padding: 0.375rem 1.5rem;
  font-size: 0.875rem;
}
.nk-todo-menu-item::after {
  position: absolute;
  height: 100%;
  width: 2px;
  content: "";
  left: 0;
  top: 0;
  background-color: #5f38f9;
  opacity: 0;
}
.nk-todo-menu-item.active,
.active > .nk-todo-menu-item {
  color: #5f38f9;
}
.nk-todo-menu-item.active:after,
.active > .nk-todo-menu-item:after {
  opacity: 1;
}
.nk-todo-menu-item .icon {
  font-size: 1.375rem;
  width: 2.375rem;
}
.nk-todo-menu-badge {
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 0.125rem;
  margin-left: auto;
  display: inline-flex;
}
.nk-todo-body {
  flex-grow: 1;
  width: 100%;
}
.nk-todo-head {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e7ec;
  padding: 0.5rem 1.5rem;
}
.nk-todo-head-nav {
  display: flex;
  align-items: center;
  margin: 0 -0.125rem;
}
.nk-todo-head-nav li {
  padding: 0 0.125rem;
}
.nk-todo-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.nk-todo-item-elem {
  flex-shrink: 0;
  padding: 0.625rem 0.5rem;
}
.nk-todo-item-check {
  width: 2.25rem;
  z-index: 2;
}
.nk-todo-item-star {
  width: 2rem;
  z-index: 2;
}
.nk-todo-item-content {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  max-width: 100%;
}
.nk-todo-item-subject {
  font-weight: 500;
  font-size: 0.875rem;
}
.nk-todo-item-action {
  padding-right: 0;
}
.nk-todo-item:not(:last-child) {
  border-bottom: 1px solid #e8e7ec;
}
.nk-todo-item.task-done .nk-todo-item-subject {
  text-decoration: line-through;
}
@media (min-width: 768px) {
  [data-todo-collapse="md"] .nk-todo-aside {
    position: static;
    transform: translateX(0);
    background-color: rgba(0, 0, 0, 0);
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  [data-todo-collapse="md"] .nk-todo-body {
    max-width: calc(100% - 254px);
  }
  [data-todo-collapse="md"] .nk-todo-toggle {
    display: none;
  }
}
@media (min-width: 992px) {
  [data-todo-collapse="lg"] .nk-todo-aside {
    position: static;
    transform: translateX(0);
    background-color: rgba(0, 0, 0, 0);
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  [data-todo-collapse="lg"] .nk-todo-body {
    max-width: calc(100% - 254px);
  }
  [data-todo-collapse="lg"] .nk-todo-toggle {
    display: none;
  }
}
@media (min-width: 1200px) {
  [data-todo-collapse="xl"] .nk-todo-aside {
    position: static;
    transform: translateX(0);
    background-color: rgba(0, 0, 0, 0);
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  [data-todo-collapse="xl"] .nk-todo-body {
    max-width: calc(100% - 254px);
  }
  [data-todo-collapse="xl"] .nk-todo-toggle {
    display: none;
  }
}
.nk-invoice {
  padding: 32px;
}
.nk-invoice-head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.nk-invoice-head + .nk-invoice-head {
  border-top: 1px solid #e8e7ec;
  padding-top: 30px;
}
.nk-invoice-head ul li {
  font-size: 0.875rem;
  color: #787c9e;
}
.nk-invoice-table th,
.nk-invoice-table td {
  font-size: 0.875rem;
}
.nk-invoice-table tfoot {
  border-top: 1px solid #e8e7ec;
}
.nk-invoice-table tfoot td {
  border-bottom: 0;
}
.nk-invoice-table tfoot tr:last-child td:not(:first-child),
.nk-invoice-table tfoot tr:first-child td:not(:first-child) {
  font-weight: 500;
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.nk-invoice-table tfoot tr:last-child td:not(:first-child) {
  border-top: 1px solid #e8e7ec;
  padding-top: 0.25rem;
}
@media (min-width: 992px) {
  .nk-invoice {
    padding: 70px;
  }
}
code[class*="language-"],
pre[class*="language-"] {
  color: #657b83;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 0.875rem;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.2;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  background: #090127;
}
pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  background: #090127;
}
pre[class*="language-"] {
  padding: 1em;
  overflow: auto;
  margin-bottom: 0;
}
pre[class*="language-"]:not(:last-child) {
  margin-bottom: 1rem;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background-color: #f1f2f7;
}
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #93a1a1;
}
.token.punctuation {
  color: #586e75;
}
.token.namespace {
  opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #268bd2;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.url,
.token.inserted {
  color: #2aa198;
}
.token.entity {
  color: #657b83;
  background: #eee8d5;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #859900;
}
.token.function,
.token.class-name {
  color: #b58900;
}
.token.regex,
.token.important,
.token.variable {
  color: #cb4b16;
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
.code-toolbar {
  border-top: 1px solid #e8e7ec;
}
.code-preview::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.code-preview::-webkit-scrollbar-track {
  border-radius: 6px;
}
.code-preview::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 6px;
}
.code-preview::-webkit-scrollbar-thumb:hover {
  background: #bdbcbc;
}
.border-utils [class*="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: #fbfbfb;
}
.rounded-utils [class*="rounded"] {
  display: inline-block;
  width: 75px;
  height: 75px;
  margin: 0.25rem;
}
.rounded-utils .rounded-pill {
  width: 120px;
}
.border-utils-0 [class*="border"] {
  border: 1px solid #888;
}
.card .code-preview {
  border-radius: 0.375rem;
  margin-bottom: 0 !important;
  max-height: 100px;
  padding-top: 0;
  overflow-x: hidden;
}
.preview-icon-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.75rem;
}
.preview-icon-item {
  flex: 1 0 150px;
  padding: 0.75rem;
  max-width: 50%;
  height: 100%;
}
.preview-icon-box {
  position: relative;
  padding: 25px 20px;
  text-align: center;
  border: 1px solid #e8e7ec;
  box-shadow: 0 1px 0px rgba(232, 231, 236, 0.5);
}
.preview-icon-box .btn-clipboard {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
}
.preview-icon-box .btn-clipboard:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.preview-icon-box:hover .btn-clipboard {
  opacity: 1;
}
.preview-icon-wrap {
  padding-bottom: 5px;
}
.preview-icon-wrap .ni {
  font-size: 30px;
}
.preview-icon-svg .preview-icon-wrap {
  margin-bottom: 0.5rem;
}
.preview-icon-svg .preview-icon-wrap svg {
  width: 90px;
}
.preview-icon-name {
  color: #787c9e;
  font-size: 13px;
}
.nk-sidebar+.nk-wrap>.nk-header-fixed {
    left: 280px;
}
.nk-sidebar+.nk-wrap {
  padding-left: 280px;
}
@media (min-width: 992px){
[data-sidebar-collapse=lg] .nk-sidebar {
    transform: translateX(0);
}
}
.nk-chart-analytics-session {
  display: none;
}
.chart-label-group.justify-content-between.mt-1 {
  display: none;
}
button.btn.btn-icon.btn-md.btn-zoom.d-none.d-sm-inline-flex:before {
  content: unset;
}
.ni-signout:before {
  content: unset;
}
.ni-signout {
  margin-right: 2px;
}
button.btn.btn-icon.btn-md.btn-zoom.d-none.d-sm-inline-flex {
  margin-right: 9px;
}

















/* ================================mobile============================================ */
@media only screen and (max-width: 768px) {
  .nk-sidebar {
    transform: translateX(-100%);
  }
  
  .nk-sidebar+.nk-wrap {
    padding-left: 0;
}
.nk-sidebar+.nk-wrap>.nk-header-fixed {
  left: 0;
}
button#menutoggle {
  right: -17%;
  position: fixed;
  background: transparent;
  z-index: 9999;
  top: 9px;
  transition: .5s;
}
.nk-sidebar.nk-sidebar-fixed.is-theme.active button#menutoggle {
  right: 9px;
  position: fixed;
  background: transparent;
  z-index: 9999;
  top: 9px;
  rotate: 180deg;
}
}
@media only screen and (max-width: 600px) and (orientation: portrait) {
  .services-com.text-center {
    width: 47%;
    margin-bottom: 16px;
}
.appoint-Device img {
  width: 60%;
    height: 79px;
    object-fit: contain;
}
.mobile-menu-btn {
  display: block !important;
  /* background: red; */
  margin-right: 17px;
  position: relative;
}
.mobile-menu-btn {
  display: block !important;
  margin-right: 17px;
  position: relative;
  font-size: 33px;
  width: 36px !important;
  margin-top: -10px !important;
  color: white;
}
.footer-link {
  margin: 52px 12px;
}
.mobile-menu {
  display: block !important;
  position: fixed;
  left: 100%;
  background: white;
  height: 100%;
  width: 100%;
  z-index: 99;
  top: 0;
transition: .5s;
}
.mobile-menu.menu-open {
  left: 0;
}
.close-menu {
  background: #02acdd;
  color: white;
  padding: 9px;
  font-size: 26px;
}
.appointment {
  margin-top: 35px;
}
.mobile-menu-outer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.appointment {
  margin-top: 24px;
}
.mobile-menu-outer li {
  padding: 12px;
  border-bottom: 1px solid #0000001a;
}
.mobile-menu-outer a {
  color: black;
  font-size: 22px;
  padding-left: 28px;
  text-decoration: none;
}

}


@media only screen and (max-width: 768px) {
.flex-md-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-md-column {
  flex-direction: column !important;
}

}