.product_brand-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  padding: 10px;
  text-align: center;
  background-color: #fff;
}

.product_brand-card:hover {
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
}

.product_brand-card.clicked {
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2) !important;
}

.product_brand-image {
  border-radius: 5px;
  margin-bottom: 10px;
  max-height: 200px;
  object-fit: contain;
}

.product_brand-name {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.container_brand {
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
